<template>
  <div id="app">
    <div v-show="showtooltip" class="popover" :style="positionUnlineStyle" v-html="txttooltip"></div>

    <b-navbar type="dark" variant="bruvis" toggleable="lg" fixed="top" class="py-0 px-0">
      <b-container>
        <!--b-navbar-brand v-b-modal.debug v-if="logo"-->
        <b-overlay
          :show="navbusy"
          rounded
          opacity="1"
          spinner-small
          spinner-variant="white"
          variant="bruvis"
        >
          <b-navbar-brand v-if="logo">
            <b-iconstack font-scale="1.8" class="mx-2 d-inline d-md-none shadow-none" variant="white" v-b-toggle.sidebarmenu @click.prevent>
              <b-icon stacked icon="dash-lg" scale="0.6" shift-h="0" shift-v="4"></b-icon>
              <b-icon stacked icon="dash-lg" scale="0.6" shift-h="0" shift-v="0"></b-icon><b-icon stacked icon="dash-lg" scale="0.6" shift-h="0" shift-v="-4"></b-icon>
            </b-iconstack>  
            <b-img :src="logo.img" height="40" class="mx-1" @click="debugmode=!debugmode"></b-img><span class="d-none d-md-inline">{{logo.text}}</span>
          </b-navbar-brand>
        </b-overlay>  

        
        <b-navbar-nav class="mr-auto" v-show="!navbusy">
          <b-button v-if="currentSalepoint" v-b-modal.salepoints-modal class="ml-2 border-bruvis bg-bruvis shadow-none">
            <b-icon icon="geo-alt-fill" class="mr-0" scale="0.7"/>{{currentSalepoint.point_name}}<b-icon icon="caret-down-fill" scale="0.6" class="ml-0"/>
          </b-button>

          <!--b-nav-form>
            <b-form-select
              size="sm" class="mr-sm-2 shadow-none"
              v-if="settings.enable_salepoints && places && places.length > 1"
              v-model="currentPlace"
              :options="places"
              value-field="rn"
              text-field="name"
              @change = "changePlace()"
            />
          </b-nav-form>
          <b-nav-form>
            <b-form-select
              size="sm" class="mr-sm-2 shadow-none"
              v-if="currentSalepointsList && currentSalepointsList.length>1"
              v-model="currentSalepoint"
              :options="currentSalepointsList"
              value-field="hall_rn"
              text-field="hall_name"
              @change = "changeSalepoint()"
            />
          </b-nav-form-->
        </b-navbar-nav>

        <!--b-navbar-nav>
          <b-nav-item class="text-white" small>
            <span class="d-block d-sm-none text-white">xs</span>
            <span class="d-none d-sm-block d-md-none text-white">sm</span>
            <span class="d-none d-md-block d-lg-none text-white">md</span>
            <span class="d-none d-lg-block d-xl-none text-white">lg</span>
            <span class="d-none d-xl-block text-white">xl</span>
          </b-nav-item> 
        </b-navbar-nav-->
        <!--div-->
         <!--b-iconstack 
                         font-scale="2"
                      >
                         <b-icon 
                          
                          stacked
                          icon="square-fill" 
                          variant="light" 
                          >
                        </b-icon>
                        <b-icon 
                          stacked
                          icon="square" 
                          variant="dark" 
                          >
                        </b-icon>
                        <b-icon 
                          
                          stacked
                          scale="0.6"
                          icon="search" 
                          variant="dark" 
                          
                          >
                        </b-icon>
                      </b-iconstack>

<b-iconstack 
                         font-scale="2"
                      >
                         <b-icon 
                          
                          stacked
                          icon="square-fill" 
                          variant="light" 
                          >
                        </b-icon>
                        <b-icon 
                          stacked
                          icon="square" 
                          variant="dark" 
                          >
                        </b-icon>
                        <b-icon 
                          
                          stacked
                          scale="0.75"
                          icon="cart4" 
                          variant="dark" 
                          
                          >
                        </b-icon>
                      </b-iconstack-->

        
        <!--/div-->  
        


        <!--validDateAbon(auth.abons,ondate)-->

        <!--disabled-field="notEnabled"-->

        <!------------------------------------>

        <!--             v-b-popover.hover.bottomleft.v-victor="{ 
              customClass: 'bg-victor text-black font-weight-bold popover', 
              content :'Неоплачених бронюваннь '+unpayedtickets.length
            }"
 -->


       

        <!------------------------------------>

        <b-navbar-nav class="ml-auto" style="flex-direction: row;">

          <b-nav-item 
            v-if="(currentDateTabMark && isStateFree(currentDateTabMark)) || (checkedSeats.length>0 && isStateFree(checkedSeats[0]))"
            v-show="!shownRightSidebar"
            v-b-popover.hover.bottomleft.v-victor="{ 
              customClass: 'bg-victor text-black font-weight-bold popover d-none d-sm-block', 
              content :'Обраних позицій '+checkedSeats.length
            }"
            id="pre-cart-button" 
            class="py-1 m-1 button-cart text-white" 
            variant="white"
            @click="switchSidebar('rightsidebar','on')" 
          >
            <b-icon icon="cart-check-fill" class="mr-2"></b-icon>
            <div class="quant2" id="header-cart-quantity2" v-show="checkedSeats.length > 0">{{ checkedSeats.length }}</div> 
          </b-nav-item>

          <b-nav-item 
            v-if="printlist && printlist.length"
            v-b-popover.hover.bottomleft.v-victor="{ 
              customClass: 'bg-victor text-black font-weight-bold popover', 
              content :'Квитків для роздрукування '+printlist.length
            }"
            id="printlist-button" 
            class="py-1 m-1 button-cart text-white" 
            variant="white"
            @click="$bvModal.show('printlist-modal')" 
          >
            <b-icon icon="printer" class="mr-2"></b-icon>
            <div class="quant3" id="header-printlist-quantity" v-show="printlist.length > 0">{{ printlist.length }}</div> 
          </b-nav-item>

          <b-nav-item 
            v-if="unpayedtickets && unpayedtickets.length"
            v-b-popover.hover.bottomleft.v-victor="{ 
              customClass: 'bg-victor text-black font-weight-bold popover', 
              content :'Неоплачених бронюваннь '+unpayedtickets.length
            }"
            id="cart-button" 
            class="py-1 m-1 button-cart text-white" 
            variant="white"
            @click="$bvModal.show('unpayedtickets-modal')" 
          >
            <b-icon icon="cart4" class="mr-2"></b-icon>
            <div class="quant" id="header-cart-quantity" v-show="unpayedtickets.length > 0">{{ unpayedtickets.length }}</div> 
            <span class="head-txt" v-show="unpayedticketssum > 0">{{ unpayedticketssum }} <span>&#8372;</span>&nbsp;</span> 
            <span class="mx-2 head-txt" v-if="mincounter">({{ mincounter }})</span> 
          </b-nav-item>

          <!--b-nav-form  
            v-show="0"
            v-if="!razdel && auth && auth.visitor && auth.abons && (Object.keys(auth.abons)).length>0"
          >

            <b-input-group>
              <b-input-group-prepend 
                is-text
                v-if="!this.settings || this.settings.disable_liqpay!=1"               
              >
                <b-form-checkbox 
                  v-model="useAbon"
                  class="mr-n2"
                  size="md"
                  switch
                  v-b-popover.hover.bottomleft.v-victor="{ 
                    customClass: 'bg-victor text-black font-weight-bold popover', 
                    content :'Задіяти абонемент. Одночасно можна замовити лише одне місце'
                  }"
                ></b-form-checkbox>
              </b-input-group-prepend>

              <b-form-select
                v-model="currentAbon"
                @change="changeAbon()"
                :disabled = "!useAbon"
                :style="(currentAbon && !validDateAbon(auth.abons[currentAbon],ondate))?'background-color:#ff7272;color:black':''"
              >
                <b-form-select-option 
                  v-for="(a,rn) in auth.abons"
                  :value="rn"
                  :key = rn
                  :style = "(!validDateAbon(a,ondate))?'color:red;background-color:white':'color:black;background-color:white'"
                >
                  <b>{{a.xnomenu}}</b>
                </b-form-select-option>
              </b-form-select>

              
            </b-input-group>
          </b-nav-form-->

          <b-nav-item v-if="!auth || !auth.visitor">
            <b-button @click="needlogin=true" class="border-bruvis bg-bruvis">Вхід</b-button>
          </b-nav-item>  

          <b-dropdown v-else v-show="!needlogin" right variant="bruvis" class="p-0 pr-2 m-0 d-none d-md-inline" >
            <template #button-content>
              <b-icon icon="person-fill"></b-icon><span class="d-none d-sm-inline p-0 m-0"> {{auth.visitor.mnemo}}</span>
            </template>
            <b-dropdown-item v-b-modal.userprofile-modal>
              <b-icon icon="person-badge" scale="1" class="mr-1"></b-icon>
              Профіль користувача
            </b-dropdown-item>
            <b-dropdown-item v-b-modal.archive-modal>
              <b-icon icon="box-seam" scale="1" class="mr-1"></b-icon>
              Бронювання
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.abonements-modal>
              <b-icon icon="credit-card2-front" scale="1" class="mr-1"></b-icon>
              Купити абонемент 
            </b-dropdown-item>

            <!--b-dropdown-item v-b-modal.specialists-modal>
              <b-icon icon="emoji-angry" scale="1" class="mr-1"></b-icon>
              Спеціалісти 
            </b-dropdown-item-->

            <b-dropdown-item v-b-modal.history-modal>
              <b-icon icon="bag-check" scale="1" class="mr-1"></b-icon>
              Історія покупок
            </b-dropdown-item>
            <!--b-dropdown-item v-b-modal.zakazy-payed-modal>
              <b-icon icon="bag-check" scale="1" class="mr-1"></b-icon>
              Оплачені бронювання
            </b-dropdown-item-->

            <b-dropdown-divider></b-dropdown-divider>

            <b-dropdown-item @click="razdel='specialisty'">
              <b-icon icon="person-check" scale="1" class="mr-1"></b-icon>
              Спеціалісти
            </b-dropdown-item>

            <b-dropdown-item @click="razdel='grouplessons'">
              <b-icon icon="people" scale="1" class="mr-1"></b-icon>
              Групові заняття
            </b-dropdown-item>

            <b-dropdown-item @click="razdel='news'">
              <b-icon icon="people" scale="1" class="mr-1"></b-icon>
              Новини
            </b-dropdown-item>



            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item href="#" @click="logout">
              <b-iconstack font-scale="1" class="mr-1">
                <b-icon stacked icon="door-open" variant="dark"></b-icon>
                <b-icon stacked icon="arrow-left-short" scale="1" shift-v="0" shift-h="-8"></b-icon>
              </b-iconstack>
              Вихід
            </b-dropdown-item>
          </b-dropdown>
        </b-navbar-nav>

      </b-container>
    </b-navbar> 
    
    <!-- Sidebar menu -->
    <b-sidebar id="sidebarmenu" :title="(auth && auth.visitor.mnemo)? auth.visitor.mnemo:''" bg-variant="bruvis" text-variant="white" backdrop-variant="light" backdrop shadow>
      <div class="px-3 py-2">
        <b-button-group vertical size="lg">
          <template v-if="auth && auth.visitor.mnemo">
            <b-button class="text-left" variant="bruvis"  v-b-modal.userprofile-modal v-b-toggle.sidebarmenu><b-icon icon="person-badge" scale="1" class="mr-1"></b-icon> Профіль користувача</b-button>  
            <b-button class="text-left" variant="bruvis"  v-b-modal.archive-modal v-b-toggle.sidebarmenu><b-icon icon="box-seam" scale="1" class="mr-1"></b-icon> Бронювання</b-button>  
            <b-button class="text-left" variant="bruvis"  v-b-modal.abonements-modal v-b-toggle.sidebarmenu><b-icon icon="credit-card2-front" scale="1" class="mr-1"></b-icon> Купити абонемент</b-button>  
            <b-button class="text-left" variant="bruvis"  v-b-modal.history-modal v-b-toggle.sidebarmenu><b-icon icon="bag-check" scale="1" class="mr-1"></b-icon> Історія покупок</b-button>  
            <hr>
          </template>  
          <b-button class="text-left" variant="bruvis"  @click="razdel='specialisty'" v-b-toggle.sidebarmenu><b-icon icon="person-check-fill" scale="1" class="mr-1"></b-icon> Індивідуальні заняття</b-button>
          <b-button class="text-left" variant="bruvis"  @click="razdel='grouplessons'" v-b-toggle.sidebarmenu><b-icon icon="people-fill" scale="1" class="mr-1"></b-icon> Групові заняття</b-button>
          <b-button class="text-left" variant="bruvis"  @click="razdel='news'" v-b-toggle.sidebarmenu><b-icon icon="box-seam" scale="1" class="mr-1"></b-icon> Новини</b-button>
          <hr>
          <template v-if="auth && auth.visitor.mnemo">
            <b-button class="text-left" variant="bruvis" @click="logout" v-b-toggle.sidebarmenu><b-iconstack font-scale="1" class="mr-1"><b-icon stacked icon="door-open" variant="light"></b-icon>
                <b-icon stacked icon="arrow-left-short" scale="1" shift-v="0" shift-h="-8"></b-icon></b-iconstack> Вихід</b-button>      
          </template>
          <template v-else>
            <b-button class="text-left" variant="bruvis" @click="needlogin=true" v-b-toggle.sidebarmenu><b-iconstack font-scale="1" class="mr-1"><b-icon stacked icon="door-open" variant="light"></b-icon>
                <b-icon stacked icon="arrow-right-short" scale="1" shift-v="0" shift-h="-8"></b-icon></b-iconstack> Вхід / Реєстрація</b-button>      
          </template>  
        </b-button-group>
      </div>
    </b-sidebar>
    
    <!----------ROOT TABS------------------------------------------------------------------------------------------------------------------------------------------>

    <div class="container-fluid bg-bruvis d-none d-md-block root-menu" style="margin-top: -23px;margin-bottom:25px;">
      <div class="container">
        <b-tabs end class="mt-3 mb-0" active-nav-item-class="text-black">
                
                <b-tab :active="razdel==='news'"  @click.prevent = "razdel='news'"  title-item-class="text-light-cow">
                    <template #title>
                      <b-icon icon="house-door-fill" scale="1" class="mr-2 d-md-none d-lg-inline"></b-icon>Новини
                    </template>
                </b-tab>

                <b-tab :active="!razdel"  @click.prevent = "razdel=null"  title-item-class="text-light-cow">
                    <template #title>
                      <b-icon icon="check2-square" scale="1" class="mr-2 d-md-none d-lg-inline"></b-icon>Бронювання місць
                    </template>
                </b-tab>

                <b-tab :active="razdel==='specialisty'"  @click.prevent = "razdel='specialisty'" title-item-class="text-light-cow"> <!-- :disabled="!auth || !auth.visitor"-->
                    <template #title>
                      <b-icon icon="person-check-fill" scale="1" class="mr-2 d-md-none d-lg-inline"></b-icon>Індивідуальні заняття
                    </template>
                </b-tab>

                <b-tab :active="razdel==='grouplessons'"  @click.prevent = "razdel='grouplessons'" title-item-class="text-light-cow"> <!--  :disabled="!auth || !auth.visitor" -->
                    <template #title>
                      <b-icon icon="people-fill" scale="1" class="mr-2 d-md-none d-lg-inline"></b-icon>Групові заняття <!--:icon="(!auth || !auth.visitor)?'lock-fill':'people'"-->
                    </template>
                </b-tab>
           
        </b-tabs>
      </div>
    </div>  


    <div class="container d-block d-md-none">
      <b-tabs pills fill class="mt-2 mb-2"  active-nav-item-class="text-black bg-bruvis">
                
                <b-tab :active="razdel==='news'"  @click.prevent = "razdel='news'" title-item-class="text-light-cow m-1">
                    <template #title>
                      <b-icon icon="house-door-fill" scale="1" class="mr-2"></b-icon>Новини
                    </template>
                </b-tab>

                <b-tab :active="!razdel"  @click.prevent = "razdel=null" title-item-class="text-light-cow m-1">
                    <template #title>
                      <b-icon icon="check2-square" scale="1" class="mr-2"></b-icon>Бронювання місць
                    </template>
                </b-tab>

                <b-tab :active="razdel==='specialisty'"  @click.prevent = "razdel='specialisty'" title-item-class="text-light-cow m-1"> <!-- :disabled="!auth || !auth.visitor"-->
                    <template #title>
                      <b-icon icon="person-check-fill" scale="1" class="mr-2"></b-icon>Індивідуальні заняття
                    </template>
                </b-tab>

                <b-tab :active="razdel==='grouplessons'"  @click.prevent = "razdel='grouplessons'" title-item-class="text-light-cow m-1"> <!--  :disabled="!auth || !auth.visitor" -->
                    <template #title>
                      <b-icon icon="people-fill" scale="1" class="mr-2"></b-icon>Групові заняття <!--:icon="(!auth || !auth.visitor)?'lock-fill':'people'"-->
                    </template>
                </b-tab>
           
      </b-tabs>
    </div>
    
    <!-------RAZDEL 1-BEGIN---------------------------------------------------------------------------------------------------------------------------------------->
    <template v-if="!razdel">
      
    <b-container class="main__container" :aria-hidden="globalbusy">

      <b-overlay
        :show="globalbusy && !needlogin"
        rounded
        variant="white"
        blur="2px" 
        opacity="0.9"
        spinner-variant="bruvis"
        class="d-inline-block w-100"
        no-center
        style="inset:0px;"
        id = "globaloverlay"
      >

        <b-row>
          <b-col lg="12">
            <!------------------------------------------------------------------>
            <div class="w-100 mb-4 d-none d-sm-block" @wheel.prevent="wheel">
              <b-tabs 
                ref="ondates-tabs"
                id="ondates-tabs" 
                pills 
                nav-class="scrollable-tabs-nav"
                active-nav-item-class = "btn btn-bruvis"
                v-model="ondateTabIndexBuffer"
              >
                <template #tabs-start>
                  <button 
                    ref="scrollLeft" 
                    class="vps-tabs-nav-btn-left btn btn-victor text-bruvis border-bruvis" 
                    @click="clickLeft"
                  >
                    <b-icon icon="caret-left-fill"></b-icon>
                  </button>
                </template>
                <template v-for="(dt,tbinxd) in ondates">
                  <b-tab
                    :active="ondateTabIndexBuffer === tbinxd"
                    :key = "dt"
                    @click.prevent = "changeOndate(dt)"
                    :title-item-class="(tbinxd === 0 )?'text-nowrap first shadow-none':((tbinxd === ondates.length-1)?'text-nowrap last shadow-none':'text-nowrap shadow-none')"
                  >
                    <template #title>
                      <div class="datetab">
                      {{new Date(dt) | dateFormat('dddd D MMMM')}}
                      <!--div v-if="zakazy_payed_actual[dt]" class="zakazy-day-quant">{{(zakazy_payed_actual[dt]).length}}</div-->
                      <div :class="(!currentDateTabMark)?'datetabmark':'datetabmark2'" v-show="datestates && datestates[dt] != null && datestates[dt] != -1">
                        <b-iconstack datetabmark
                          
                          font-scale="2"
                        > 
                          <b-icon 
                            stacked
                            :icon="(!currentDateTabMark)?'caret-up-fill':'caret-right-fill'" 
                            rotate="45" 
                            :variant="(datestates && datestates[dt]==0)?'success':((datestates && datestates[dt]==2)?'danger':'warning')" 
                            
                            >
                          </b-icon>
                          <b-icon 
                            stacked
                            :icon="(!currentDateTabMark)?'caret-up':'caret-right'" 
                            rotate="45"
                            variant="light" 
                            >
                          </b-icon>
                        </b-iconstack>
                      </div>
                      <!--div :class="(!currentDateTabMark)?'datetabmark':'datetabmark2'" v-show="datestates && datestates[dt] != null && datestates[dt] == -1">
                        <b-icon 
                            font-scale="2"
                            :icon="(!currentDateTabMark)?'caret-up':'caret-right'" 
                            rotate="45"
                            variant="light" 
                            >
                          </b-icon>

                      </div-->  


                      <!--b-icon 
                        v-show="datestates && datestates[dt] != null"
                        icon="circle-fill" 
                        :variant="(datestates && datestates[dt]==0)?'success':'danger'" 
                        font-scale="0.4" class="mb-3">
                      </b-icon-->
                      </div>
                    </template>
                    
                  </b-tab>
                </template>  
              <template #tabs-end>
                <button 
                  ref="scrollRight" 
                  class="vps-tabs-nav-btn-right btn btn-victor text-bruvis border-bruvis" 
                  @click="clickRight"
                >
                  <b-icon icon="caret-right-fill"></b-icon>
                </button>
              </template>
              </b-tabs>
            </div>
            <!------------------------------------------------------------------>

                    <div class="date-block d-sm-none" v-if="ondates">
                      <div
                        role="button" 
                        class="date-prev-btn btn btn-victor border-bruvis" 
                        @click="setPrevDay()"  
                        title="Попередній день"
                        :disabled="ondateTabIndex === 0"
                      >
                        <b-icon :icon="(ondateTabIndex === 0)?'stop':'caret-left-fill'"></b-icon>
                      </div>
                      <div class="datepicker">  <!-- month: 'long', day: 'numeric', weekday: 'long', year: 'numeric'-->
                        <b-form-datepicker
                          @input = "changeOndate(ondate)" 
                          v-model="ondate" 
                          :date-disabled-fn="dateDisabled"
                          :date-format-options="{ month: 'long', day: 'numeric', weekday: 'long', year: '2-digit' }" 
                          locale="uk" 
                          placeholder="Оберіть дату"
                          start-weekday="1"
                          :min= "minOndate"
                          :max= "maxOndate"
                          class="shadow-none border-bruvis mb-2 d-sm-none"
                          style="font-size:0.8em;height:3em;"
                          hide-header
                          label-prev-decade="label1"
                          label-next-decade="label2"
                        />
                      </div>
                      <div 
                        role="button" 
                        class="date-next-btn btn btn-victor border-bruvis" 
                        @click="setNextDay()" 
                        title="Наступний день"
                        :disabled="ondateTabIndex === ondates.length-1"
                      >
                        <b-icon :icon="(ondateTabIndex === ondates.length-1)?'stop':'caret-right-fill'"></b-icon>
                      </div>
                    </div>

            <!------------------------------------------------------------------>
          </b-col>
        </b-row>


        <!--b-row v-if="rooms"> 
          <b-col lg="12">
            <div 
              v-if="(hallobj[0]).schemeRN && schemes[(hallobj[0]).schemeRN] && shifts[((schemes[(hallobj[0]).schemeRN]).specs[onweekdays[ondate]]).shift]"
            >
              <div class="alert alert-info">
                <pre>
                  {{Object.keys((this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]).specs)}}
                  <hr>
                {{(shifts[((schemes[currentScheme]).specs[onweekdays[ondate]]).shift]).specs}}
                </pre>
              </div>  
            </div>
          </b-col>
        </b-row-->
        <!-------EXPERIMENT BEGIN-------------->
        <b-row  v-if="rooms && this.settings && this.settings.multi_shift!=1" >
          <b-col lg="12">
            <!--template v-if="(hallobj[0]).schemeRN && schemes[(hallobj[0]).schemeRN] && shifts[((schemes[(hallobj[0]).schemeRN]).specs[onweekdays[ondate]]).shift]"-->
            <template v-if="currentScheme && schemes[currentScheme] && shifts[((schemes[currentScheme]).specs[onweekdays[ondate]]).shift]">
            <!------------------------------------------------------------------>
            <div class="w-100 mb-4"  @wheel.prevent="wheel2">
              <b-tabs 
                ref="shifts-tabs"
                id="shifts-tabs" 
                pills 
                nav-class="scrollable-tabs-nav"
                active-nav-item-class = "btn btn-bruvis"
              
              ><!-- v-model="currentShift"-->
                <template #tabs-start>
                  <button 
                    ref="scrollLeft2" 
                    class="vps-tabs-nav-btn-left btn btn-victor text-bruvis border-bruvis" 
                    @click="clickLeft2"
                  >
                    <b-icon icon="caret-left-fill"></b-icon>
                  </button>
                </template>
                <!--template   
                  v-for="(shif,shiftrn,idx) of (shifts[((schemes[(hallobj[0]).schemeRN]).specs[onweekdays[ondate]]).shift]).specs"
                --><!--v-for="(shif,shiftrn,idx) of (shifts[((schemes[currentScheme]).specs[onweekdays[ondate]]).shift]).specs"-->
                <template   
                  v-for="(shif,idx) of currentShiftsList"
                >
                  <b-tab
                    :active="currentShift === shif.rn"
                    :key = "idx"
                    @click.prevent = "setShift(shif.rn)"
                    :disabled="disabledShifts.includes(shif.rn)" 
                    :title-item-class="(idx === 0 )?'text-nowrap first shadow-none':((idx === currentShiftsList.length-1)?'text-nowrap last shadow-none':'text-nowrap shadow-none')"
                  >
                    <template #title>
                      <div class="datetab">
                        <b-iconstack font-scale="1">
                          <b-icon 
                            stacked
                            v-if="currentRoom" icon="circle-fill" 
                            :variant="(getStateTab(shif.rn)===0)?'success':((getStateTab(shif.rn)===1)?'warning':'danger')" 
                            font-scale="1">
                          </b-icon>
                          <b-icon icon="clock-history" class="mx-1" stacked/>
                        </b-iconstack>
                        <template v-if="shif">
                          {{floatToTime(shif.timeFr)}}-{{floatToTime(shif.timeTo)}}<!-- {{disabledShifts.includes(shif.rn)}}-->
                        </template>
                        <template v-else>
                          test- 
                        </template>

                        <!--template v-if="currentShifts.includes(shif.rn)">[+]</template>      
                        <template v-else>[-]</template-->
                      </div>
                    </template>
                  </b-tab>
                </template>  
              <template #tabs-end>
                <button 
                  ref="scrollRight2" 
                  class="vps-tabs-nav-btn-right btn btn-victor text-bruvis border-bruvis" 
                  @click="clickRight2"
                >
                  <b-icon icon="caret-right-fill"></b-icon>
                </button>
              </template>
              </b-tabs>
            </div>
            <!------------------------>
            </template>
            <div v-else class="alert alert-warning">
              Немає можливості бронювання
            </div> 
          </b-col>
        </b-row>  

        <!-------EXPEWRIMENT END--------------->

        <!--template  v-if="debugmode"-->
        <b-row v-if="rooms && this.settings && this.settings.multi_shift && currentScheme && schemes[currentScheme] && shifts[((schemes[currentScheme]).specs[onweekdays[ondate]]).shift]"> 
          <b-col lg="12">

            <div id="multishift_select_field">
              <div id="multishift_select_leftbt">
                <button 
                  ref="scrollLeft22" 
                  class="multishift-btn-left btn btn-victor text-bruvis border-bruvis" 
                  @click="clickLeft22"
                  :disabled="scrollMultiShift===0"
                >
                  <b-icon icon="caret-left-fill"></b-icon>
                </button>
              </div>
              <div id="multishift_select_middle"  @wheel.prevent="wheel22">
                <!--style="display: flex;justify-content: center;"-->
                <div id="multishift_select_scrolled"
                  v-if="currentScheme && schemes[currentScheme] && shifts[((schemes[currentScheme]).specs[onweekdays[ondate]]).shift]"
                >
                  <template   
                    v-for="(shif,idx) of currentShiftsList"
                  >
                    <b-button
                      
                      @click="clickShift(shif.rn)"
                      :disabled="disabledShifts.includes(shif.rn)"  
                      :key="idx"
                      :variant="(currentShifts.includes(shif.rn))?'bruvis':(disabledShifts.includes(shif.rn))?'secondary':'white'"
                      :class="(currentShifts.includes(shif.rn))?'bg-bruvis text-white text-nowrap p-2 px-3 m-1 my-0 border-bruvis rounded shadow-none':'text-nowrap p-2 px-3 m-1 my-0 border-bruvis rounded shadow-none'"
                      :title="shif.rn"
                      style="margin:0 !important;margin-right:1px !important;margin-left:1px !important;"
                    >
                      <b-iconstack class="d-none d-md-inline-block">
                        <b-icon 
                          stacked
                          v-if="currentRoom" icon="circle-fill" 
                          :variant="(getStateTab(shif.rn)===0)?'success':((getStateTab(shif.rn)===1)?'warning':'danger')" 
                          font-scale="1">
                        </b-icon>
                        <b-icon icon="clock-history" class="mx-1" stacked/>
                      </b-iconstack>  

                      {{floatToTime(shif.timeFr)}}-{{floatToTime(shif.timeTo)}}
                      

                    </b-button>
                  </template>
                </div>



              </div>      
              <div id="multishift_select_rightbt">
                <button 
                  ref="scrollRight2" 
                  class="multishift-btn-right btn btn-victor text-bruvis border-bruvis" 
                  @click="clickRight22"
                >
                  <b-icon icon="caret-right-fill"></b-icon>
                </button>
              </div>
            </div>    


  <!--
            <div 
              style="display: flex;justify-content: center;"
              v-if="currentScheme && schemes[currentScheme] && shifts[((schemes[currentScheme]).specs[onweekdays[ondate]]).shift]"
            >
                <template   
                  v-for="(shif,idx) of currentShiftsList"
                >
                  <b-button
                    
                    @click="clickShift(shif.rn)"
                    :disabled="disabledShifts.includes(shif.rn)"  
                    :key="idx"
                    :variant="(currentShifts.includes(shif.rn))?'bruvis':(disabledShifts.includes(shif.rn))?'secondary':'white'"
                    :class="(currentShifts.includes(shif.rn))?'bg-bruvis text-white p-2 px-3 m-1 border-bruvis rounded shadow-none':'p-2 px-3 m-1 border-bruvis rounded shadow-none'"
                    :title="shif.rn"
                    style="margin-right:1px !important;margin-left:1px !important;"
                  >
                    <b-iconstack>
                      <b-icon 
                        stacked
                        v-if="currentRoom" icon="circle-fill" 
                        :variant="(getStateTab(shif.rn)===0)?'success':((getStateTab(shif.rn)===1)?'warning':'danger')" 
                        font-scale="1">
                      </b-icon>
                      <b-icon icon="clock-history" class="mx-1" stacked/>
                    </b-iconstack>  

                    {{floatToTime(shif.timeFr)}}-{{floatToTime(shif.timeTo)}}
                    

                  </b-button>
                </template>
              </div>
  -->




              <!--div v-else class="alert alert-warning">
                Немає можливості бронювання
              </div-->  
          </b-col>  
        </b-row>  
        <!--/template-->

        <!--b-row v-if="rooms">
          <b-col lg="12">
            <div class="shifts-root">
                <div v-if="currentRoom" class="shifts-end">&nbsp;</div>  
                <template v-if="(hallobj[0]).schemeRN && schemes[(hallobj[0]).schemeRN] && shifts[((schemes[(hallobj[0]).schemeRN]).specs[onweekdays[ondate]]).shift]">
                <template   
                  v-for="shif of (shifts[((schemes[(hallobj[0]).schemeRN]).specs[onweekdays[ondate]]).shift]).specs"
                >
                  <div class="shifts-gop" :key="shif.rn">&nbsp;</div>  
                  <div
                    role="button" 
                    @click="setShift(shif.rn)" 
                    :key="shif.rn"
                    :class="(currentShift == shif.rn)?'shifts-item-active':'shifts-item'"
                    :title="shif.rn"
                  >
                    {{floatToTime(shif.timeFr)}}-{{floatToTime(shif.timeTo)}}
                    <b-icon 
                      v-if="currentRoom" icon="circle-fill" 
                      :variant="(getStateTab(shif.rn)===0)?'success':((getStateTab(shif.rn)===1)?'warning':'danger')" 
                      font-scale="1">
                    </b-icon>

                  </div>
                </template>
                </template>
                <div v-if="!currentRoom" class="shifts-end">&nbsp;</div>
                <div v-else class="shifts-gop">&nbsp;</div>
              </div>
          </b-col>  
        </b-row-->  

        <b-row v-if="rooms && currentRoom" class="my-2"><!-- class="d-block d-md-none"-->
          <b-col  lg="12" style="display:flex;align-items: flex-start;">

            <b-iconstack font-scale="2.4" 
              class="m-0 mr-1" 
              @click="setRoom(null),$nextTick().then(roomsRecolor())" 
              v-if="currentRoom" 
              role="button"
            >
              <b-icon stacked icon="square-fill" variant="white" ></b-icon>
              <b-icon stacked icon="square" class="roomselect"></b-icon>
              <b-icon stacked scale="0.6" icon="arrow90deg-up" class="roomselect"></b-icon>
            </b-iconstack>

            <b-form-select
              v-model="currentRoom"
              class="mb-2 shadow-none roomselect"
              
            ><!--@change = "setRoomSelect()" -->
                  <template v-for="room of rooms">
                    <b-form-select-option 
                        :key="room.hallObjRN"
                        :value="room.hallObjRN"
                    >
                      {{room.objName}}
                    </b-form-select-option>
                  </template>  
            </b-form-select>
                <!--@click.stop = "currentDateTabMark=(roomInfo[currentRoom]).rn;checkedSeats=[(roomInfo[currentRoom]).rn]" -->
                <!--                  v-if="roomNulseats[currentRoom] && reservedDateRoomShift[ondate] && reservedDateRoomShift[ondate][currentRoom][currentShift]===0" -->
                <b-iconstack font-scale="2.4" 
                  class="m-0 ml-1" 
                  @click.stop = "currentDateTabMark=roomNulseats[currentRoom];checkedSeats=[roomNulseats[currentRoom]]" 
                  v-if="datestates[ondate]===0 && !currentDateTabMark"
                  v-b-popover.hover.bottomleft.v-victor="{ 
                      customClass: 'bg-victor text-black font-weight-bold popover', 
                      content :'Забронювати все приміщення'
                    }"
                >
                  <b-icon stacked icon="square-fill" variant="white" ></b-icon>
                  <b-icon stacked icon="square" variant="info"></b-icon>
                  <b-icon stacked scale="0.6" icon="cart4" variant="info"></b-icon>
                </b-iconstack>
                <!--v-else-if="reservedDateRoomShift[ondate] && reservedDateRoomShift[ondate][currentRoom][currentShift]===2" -->
                <b-iconstack font-scale="2.4" 
                  class="m-0 ml-1" 
                  v-else-if="datestates[ondate]===2 && !currentDateTabMark" 
                >
                  <b-icon stacked icon="square-fill" variant="white" ></b-icon>
                  <b-icon stacked icon="square" variant="danger"></b-icon>
                  <b-icon stacked scale="0.6" icon="cone-striped" variant="danger"></b-icon>
                </b-iconstack>



          </b-col>


        </b-row>

      </b-overlay>

    </b-container>  


    <div class="container" style="flex:1 0 auto;"><!-------------------------GLOBAL B-CARD------------------------------------------------>

      <template v-if="rooms">
        <b-row  v-show="!currentRoom">
          <b-col lg="12" v-if="hallobj['hallObjRP'] && svg[hallobj['hallObjRP']]">
            <div
              @click="clickMapRoot($event)"
              @mouseover="overMapRoot($event)"
              v-html="svgInsertShtrihovka(svg[hallobj['hallObjRP']])"
            ></div>
          </b-col>

          <!--b-form-select
            v-else
            v-model="currentRoom"
            class="mb-2 shadow-none roomselect"
            
          >
                <template v-for="room of rooms">
                  <b-form-select-option 
                      :key="room.hallObjRN"
                      :value="room.hallObjRN"
                  >
                    {{room.objName}}({{room.hallObjRN}})
                  </b-form-select-option>
                </template>  
          </b-form-select-->

          <template v-else>
              <b-col md="4" lg="3" sm="6" xs="12" role="button" 
                v-for="room of rooms" :key="room.hallObjRN"
                @click = "currentRoom = room.hallObjRN"
                class="room_cell"
              >
                <b-img fluid src="/img/palatka.png"></b-img>
                <div class="text-center">{{room.objName}} <small>({{room.hallObjRN}})</small></div>

              </b-col>   
          </template>  

          
          <!--template v-for="ic of rooms">
            <b-icon :id="'ico_bt_'+ic.hallObjRN" icon="cart4" variant="light" font-scale="1.5" style="position:absolute;display:none" :key="ic.hallObjRN"></b-icon>
          </template-->  
        </b-row>
      </template>


      <div v-if="rooms && rooms[currentRoom]" class="h-100">    <!--lg="9"-->
          
          <div class="alert alert-info mt-1 text-left" v-if="debugmode">
            <div class="row">
              <div class="col-md-6">
                <b>roomname:</b> {{(rooms[currentRoom]).objName}} <br>
                <b>ondate:</b> {{ondate}}<br>
                <b>currentRoom:</b> {{currentRoom}}<br>
              </div>
              <div  class="col-md-6">
                <b>currentScheme:</b> {{currentScheme}}<br>
                <b>currentShift:</b> {{currentShift}}<br>
                <b>service:</b> {{(rooms[currentRoom]).serviceRNs}}<br>
              </div>  
            <!--div v-if="roomsdata[currentRoom] && roomsdata[currentRoom][ondate] && roomsdata[currentRoom][ondate][currentShift]">State {{roomsdata[currentRoom][ondate][currentShift]}}</div-->
            <!--div>{{roomInfo[currentRoom]}}</div-->
            </div>
          </div>

          <!--div v-if="svg[currentRoom]"
            id="svgcontainer"
            @click="clickMap($event)"
            @mousemove="overMap($event),zoomControls=true"
            @mouseout="outMap($event),stopDrag($event),zoomControls=false"
            @mousedown="startDrag($event)"
            @mouseup="stopDrag($event)"
            @wheel.prevent="zoomSVG($event)"
            v-html="svg[currentRoom]"
          ></div-->

          <div v-if="svg[currentRoom]"
            id="svgcontainer"
            @click="clickMap($event)"
            @mousemove="overMap($event),zoomControls=true"
            @mouseout="outMap($event),zoomControls=false"
            @mousedown="startDrag($event)"
            @mouseup="stopDrag($event)"

            @touchstart="startTouch($event)"
            @touchmove="moveTouch($event)"
            @touchend="endTouch($event)"
            class="h-100"
            
          >
            <!--
              @wheel.prevent="zoomSVG($event)"
              @touchmove.stop="moveTouch($event)"
              -->
            <div id="zoom-controls-1">
               <transition name="slide-fade">
              <b-iconstack v-show="zoomControls" font-scale="1.5" rotate="45" role="button">

                <b-icon icon="square-fill" variant="warning" class="maskicon2" ></b-icon>
                <b-icon icon="square-fill" variant="warning" class="maskicon2" shift-h="18" shift-v="-18"></b-icon>
                <b-icon icon="square-fill" variant="warning" class="maskicon2" shift-h="18"></b-icon>
                <b-icon icon="square-fill" variant="warning" class="maskicon2" shift-v="-18"></b-icon>    

                <b-icon :class="(hovered=='zoom-move-up')?'hovered':''" icon="arrow-down-left-square" rotate="90"></b-icon>
                <b-icon :class="(hovered=='zoom-move-right')?'hovered':''" icon="arrow-down-left-square" shift-h="18" rotate="180"></b-icon>
                <b-icon :class="(hovered=='zoom-move-down')?'hovered':''" icon="arrow-down-left-square" shift-h="18" shift-v="-18"  rotate="-90"></b-icon>
                <b-icon :class="(hovered=='zoom-move-left')?'hovered':''" icon="arrow-down-left-square" shift-v="-18"></b-icon>

                <b-icon icon="square-fill" variant="white" class="maskicon" @mousedown.stop="svgMove('up')" @mouseover.prevent="hovered='zoom-move-up'" @mouseleave="hovered=null"></b-icon>
                <b-icon icon="square-fill" variant="white" class="maskicon" shift-h="18"  @mousedown.stop="svgMove('right')" @mouseover.prevent="hovered='zoom-move-right'" @mouseleave="hovered=null"></b-icon>
                <b-icon icon="square-fill" variant="white" class="maskicon" shift-h="18" shift-v="-18"  @mousedown.stop="svgMove('down')" @mouseover.prevent="hovered='zoom-move-down'" @mouseleave="hovered=null"></b-icon>
                <b-icon icon="square-fill" variant="white" class="maskicon" shift-v="-18" @mousedown.stop="svgMove('left')" @mouseover.prevent="hovered='zoom-move-left'" @mouseleave="hovered=null"></b-icon>  
              </b-iconstack>
               </transition>
            </div> 


            <div id="zoom-controls-2">
              <transition name="slide-fade">
              <b-iconstack v-show="zoomControls" font-scale="1.5" role="button">
                <b-icon icon="square-fill" variant="warning" class="maskicon2"></b-icon>
                <b-icon icon="square-fill" variant="warning" class="maskicon2" shift-v="-22"></b-icon>
                <b-icon icon="square-fill" variant="warning" class="maskicon2" shift-v="-44"></b-icon>  

                <b-icon icon="plus-square"></b-icon>
                <b-icon icon="dash-square" shift-v="-22"></b-icon>
                <b-icon icon="square" shift-v="-44"></b-icon>
                <b-icon icon="arrows-angle-contract" shift-v="-44" scale="0.7"></b-icon>
                
                <b-icon icon="square-fill" variant="white" class="maskicon" @mousedown.stop="zoomIN()"></b-icon>
                <b-icon icon="square-fill" variant="white" class="maskicon" shift-v="-22" @mousedown.stop="zoomOUT()"></b-icon>
                <b-icon icon="square-fill" variant="white" class="maskicon" shift-v="-44" @mousedown.stop="resetSVG()"></b-icon>
              </b-iconstack>
              </transition>
            </div>  


            <div 
              id="svgimage"
              v-html="svg[currentRoom]"
            ></div>
          
          </div>
          <!--------SEATS LIST BEGIN----------->
          <div 
            v-else
            id="seats" 
            :class="(datestates[ondate]===2)?'bg-danger p-2':''"
          >
          <!--:class="(roomsdata[currentRoom] && roomsdata[currentRoom][ondate] && roomsdata[currentRoom][ondate][currentShift]==2)?'bg-danger p-2':''"-->
          
            <!--(((statesdata[sts.hallSeatRN])[ondate])[currentShift])-->
            <template v-for="sts of (rooms[currentRoom]).seats">
              <div
                v-if="statesdata[sts.hallSeatRN] && (statesdata[sts.hallSeatRN])[ondate] && sts.rownum!=0"
                :class="getStateClass(sts.hallSeatRN)" 
                :key="sts.hallSeatRN"
                :title="(prices[sts.hallSeatRN] && prices[sts.hallSeatRN][ondate] && prices[sts.hallSeatRN][ondate][currentShift])?prices[sts.hallSeatRN][ondate][currentShift] + ' UAH':''"
              > <!--"(seatInfo[sts.hallSeatRN]).price + ' UAH'"-->

                <div v-if="debugmode">{{sts.hallSeatRN}}</div>
                <div v-if="debugmode"><h5><b-badge size="lg" variant="warning">{{(seatInfo[sts.hallSeatRN]).rownum}}-{{(seatInfo[sts.hallSeatRN]).seat}}</b-badge></h5></div>
                <div>{{getSeatLabel((seatInfo[sts.hallSeatRN]).namerow,(seatInfo[sts.hallSeatRN]).rownum)}}</div> 
                <div>{{getSeatLabel((seatInfo[sts.hallSeatRN]).namecol,(seatInfo[sts.hallSeatRN]).seat)}}</div>
                <b-iconstack font-scale="2"
                  v-if="currentShift"
                  @click="checkSeat(sts.hallSeatRN)" role="button"
                  class="m-1"
                >
                  <b-icon stacked icon="square-fill" :variant="(checkedSeats.includes(sts.hallSeatRN))?'info':'white'" ></b-icon>
                  <b-icon stacked icon="square" variant="info"></b-icon>
                  <b-icon stacked scale="0.6" icon="check-lg" :variant="(checkedSeats.includes(sts.hallSeatRN))?'white':'info'"></b-icon>     
                </b-iconstack>
              </div>
            </template>
          </div>
          <!---------SEATS LIST END---------->
      </div>

      <!--------DEBUG INFO---------------------->
        <template v-if="rooms && rooms[currentRoom]">

          <!---SEATS List Begin----------------------------->
          <!--div v-for="shif of  Object.keys((shifts[((schemes[(rooms[currentRoom]).schemeRN]).specs[onweekdays[ondate]]).shift]).specs)" :key="shif"-->
          <template v-if="schemes[(rooms[currentRoom]).schemeRN] && shifts[((schemes[(rooms[currentRoom]).schemeRN]).specs[onweekdays[ondate]]).shift] && 1==2">
            <div class="shifts-root">
              <!--template v-for="shif of (shifts[((schemes[(rooms[currentRoom]).schemeRN]).specs[onweekdays[ondate]]).shift]).specs">
                <div class="shifts-gop" :key="shif.rn">&nbsp;</div>  
                <div
                  role="button" 
                  @click="setShift(shif.rn)" 
                  :key="shif.rn"
                  :class="(currentShift == shif.rn)?'shifts-item-active':'shifts-item'"
                >
                  {{shif.rn}} ({{shif.timeFr}}-{{shif.timeTo}}) 
                  <b-icon icon="circle-fill" :variant="getStateTabColor(shif.rn)" font-scale="1"></b-icon>
                </div>
              </template-->
              <div class="shifts-end">&nbsp;</div>  
            </div>
            <div class="shifts-content">
              <div id="seats" :class="(roomsdata[currentRoom] && roomsdata[currentRoom][ondate] && roomsdata[currentRoom][ondate][currentShift]==2)?'bg-danger p-2':''">
                <template v-for="sts of (rooms[currentRoom]).seats">
                  <div
                    v-if="statesdata[sts.hallSeatRN] && (statesdata[sts.hallSeatRN])[ondate] && sts.rownum!=0"
                    :class="getStateColor(((statesdata[sts.hallSeatRN])[ondate])[currentShift])" 
                    :key="sts.hallSeatRN"
                  >
                    <div><b>{{sts.hallSeatRN}}</b></div>
                    <div>Ряд {{sts.rownum}}</div>
                    <div>Место {{sts.seat}}</div>
                    <!--div size="small">State {{((statesdata[sts.hallSeatRN])[ondate])[currentShift]}}</div-->
                    <b-iconstack font-scale="2"
                      v-if="currentShift"
                      @click="checkSeat(sts.hallSeatRN)" role="button"
                      class="m-1"
                    ><!--@click="(currentDateTabMark==sts.hallSeatRN)?uncheckSeat():checkSeat(sts.hallSeatRN)" role="button"-->
                      <b-icon stacked icon="square-fill" :variant="(checkedSeats.includes(sts.hallSeatRN))?'info':'white'" ></b-icon>
                      <b-icon stacked icon="square" variant="info"></b-icon>
                      <b-icon stacked scale="0.6" icon="check-lg" :variant="(checkedSeats.includes(sts.hallSeatRN))?'white':'info'"></b-icon>     
                    </b-iconstack><!--b-icon stacked scale="0.6" icon="check-lg" :variant="(currentDateTabMark==sts.hallSeatRN)?'white':'info'"></b-icon-->     
                    <!--b-iconstack font-scale="2" class="m-1" v-if="currentShift && statesDateRoomShiftSeats[ondate][currentRoom][currentShift] && statesDateRoomShiftSeats[ondate][currentRoom][currentShift][sts.hallSeatRN]==0">
                      <b-icon stacked icon="square-fill" variant="white" ></b-icon>
                      <b-icon stacked icon="square" variant="info"></b-icon>
                      <b-icon stacked scale="0.6" icon="cart4" variant="info"></b-icon>
                    </b-iconstack-->
                  </div>
                </template>
              </div> 
            </div>
          </template>  
        </template>  
          <!---SEATS List End----------------------------->

     

    </div> <!------------GLOBAR BCARD  END------------>


    

      <div class="text-left" v-show="debugmode">
        
      <pre>

        <b>ondate:</b> {{ondate}}
        <b>currentRoom:</b> {{currentRoom}}
        <b>currentScheme:</b> {{currentScheme}}
        <b>currentShift:</b> {{currentShift}}
        <b>currentShifts:</b> {{currentShifts}}
        <b v-if="currentShiftsAssoc && currentShifts[0] && currentShiftsAssoc[currentShifts[0]] && currentShiftsAssoc[currentShifts[currentShifts.length-1]]">
          {{floatToTime((currentShiftsAssoc[currentShifts[0]]).timeFr)}}-{{floatToTime((currentShiftsAssoc[currentShifts[currentShifts.length-1]]).timeTo)}}</b>  

        <b>disabledShifts:</b> {{disabledShifts}}
        
        PrintList:

        {{printlist}}

        DateStates
        {{datestates}}
        currentDateTabMark
        {{currentDateTabMark}}
        roomstates
        {{roomstates}}
        checkedSeats
        {{checkedSeats}}
        auth
        {{auth}}
        <b>currentAbon:</b> {{currentAbon}}
        <b>ondateTabIndex</b> {{ondateTabIndex}}
        <b>ondateTabIndexBuffer</b> {{ondateTabIndexBuffer}}
        <b-form-select v-model="ondateTabIndexBuffer">
          <option value='0'>0</option>
          <option value='1'>1</option>
          <option value='2'>2</option>
          <option value='3'>3</option>
          <option value='4'>4</option>
        </b-form-select>  

        <b>settings</b>
        {{settings}}
        
        <b>unpayedtickets</b>
        {{unpayedtickets}}

        <b>freeDateRoomShift:</b>
        {{freeDateRoomShift}}

        <b>counters:</b>
        {{counters}}

        <b>Payed:</b>
        {{payed}}

        <b>Shiftspec:</b>
        {{shiftspec}}
                
      </pre>
      </div>

    </template>
    <!-------RAZDEL 1-END---------------------------------------------------------------------------------------------------------------------------------------->

    <!-------RAZDEL 2-begin-------------------------------------------------------------------------------------------------------------------------------------->

    <div class="container" v-else-if="razdel==='specialisty' && settings"> <!--&& auth -->
      <div class="text-left">
        <!--b-link @click="razdel=null" class="mb-2"><b-icon icon="arrow-left-short" class="mr-2"></b-icon>quit</b-link-->
        <SpeciaListy
          :abons = "(auth)?auth.abons:null"
          :visitor = "(auth)?auth.visitor:null"
          :settings = "settings"
          :need2update = "needUpdateStatesSpecialist"
          :worker2open = "worker2open"
          @clearJump = "worker2open = null"
          @onUpdateStates = "needUpdateStatesSpecialist = false"
          @needlogin = "needlogin = true"
          @onupdate = "init()"
          @onupdatebasket = "updateBasket()"
          @readytopay = "openBasketToPay()"
        /> 
      </div>
    </div>


    <!-------RAZDEL 2-END---------------------------------------------------------------------------------------------------------------------------------------->

    <!-------RAZDEL 3-begin-------------------------------------------------------------------------------------------------------------------------------------->
    <div class="container" v-else-if="razdel==='grouplessons'">
      <div class="text-left">
        <!--b-link @click="razdel=null" class="mb-2"><b-icon icon="arrow-left-short" class="mr-2"></b-icon>quit</b-link-->
        <GroupLessons
          :abons = "(auth)?auth.abons:null"
          :visitor = "(auth)?auth.visitor:null"
          :settings = "settings"
          :need2update = "needUpdateStatesGroup"
          :gruppa2open = "gruppa2open"
          @clearJump = "gruppa2open = null"
          @onUpdateStates = "needUpdateStatesGroup = false"
          @needlogin = "needlogin = true"
          @onupdate = "init()"
          @onupdatebasket = "updateBasket()"
          @readytopay = "openBasketToPay()"
        /> 
      </div>
    </div>
    <!-------RAZDEL 3-END---------------------------------------------------------------------------------------------------------------------------------------->

    <!-------RAZDEL 4-begin-------------------------------------------------------------------------------------------------------------------------------------->
    <div class="container" v-else-if="razdel==='news'">
      <div class="text-left">
        <!--b-link @click="razdel=null" class="mb-2"><b-icon icon="arrow-left-short" class="mr-2"></b-icon>quit</b-link-->

        <!--b-row>
            <b-col><b-button @click="razdel=null"><b-icon icon="person-check" scale="1" class="mr-1"></b-icon>Бронювання місць</b-button></b-col>
            <b-col><b-button @click="razdel='specialisty'"><b-icon icon="person-check" scale="1" class="mr-1"></b-icon>Індивідуальні заняття</b-button></b-col>
            <b-col><b-button @click="razdel='grouplessons'"><b-icon icon="people" scale="1" class="mr-1"></b-icon>Группові заняття</b-button></b-col>
        </b-row-->

        <NewsData
          @needlogin = "needlogin = true"
          @jumpTo = "jumpTo"
        /> 
      </div>
    </div>
    <!-------RAZDEL 4-END---------------------------------------------------------------------------------------------------------------------------------------->




    
    <!-----------SIDEBAR BEGIN----------------------------------------------->

    <b-sidebar 
            id="rightsidebar" ref="rightsidebar" title="Бронювання" right shadow 
            sidebar-class="border-left border-bruvis" header-class="bg-victor"
            v-show="checkedSeats.length>0"
            v-if="razdel===null"
          >
            <template #footer="{ hide }">
              <div class="d-flex d-sm-none bg-transparent align-items-center px-3 py-2">
                <!--strong class="mr-auto">Footer</strong-->
                <b-button @click="hide" class="bg-bruvis w-100 text-light p-2">Закрити та продовжити бронювання</b-button>
              </div>
            </template>

            <div class="px-3 py-2">

              <!--1-BEGIN------------------------------------->              
              <b-overlay
                :show="book_busy"
                rounded 
                variant="white"
                blur="2px" 
                opacity="0.6"
                spinner-variant="bruvis"
                class="d-inline-block w-100"
              >
                <div id="booking">
                  <template v-if="!auth || !auth.visitor || !auth.visitor.visitorRN">
                    <div class="text-left">
                      <!--b-form-group
                        id="input-group-1"
                        label="Ваш Email:"
                        label-for="booking_email"
                        description="На цю адресу буде відправлено квитки"
                      -->
                        <b-form-input
                          id="booking_email"
                          v-model="booking_email"
                          type="email"
                          placeholder="Email"
                          required
                          class="mb-3"
                        ></b-form-input>
                      <!--/b-form-group>  
                      <b-form-group
                        id="input-group-1"
                        label="Ваше ПІБ:"
                        label-for="booking_name"
                      -->
                        <b-form-input
                          id="booking_name"
                          v-model="booking_name"
                          placeholder="Введіть ваше ПІБ"
                          required
                          class="mb-3"
                        ></b-form-input>
                      <!--/b-form-group-->
                    </div>    
                  </template>
                  <template v-else-if="auth && auth.abons && Object.keys(auth.abons).length>0">
                    <div v-if="checkedSeats.length===1 || currentDateTabMark" class="text-left">
                      <b-form-checkbox 
                        v-model="useAbon"  
                        class="mr-2 my-2"
                        :disabled="this.settings && this.settings.disable_liqpay===1"
                      >
                        Задіяти абонемент
                      </b-form-checkbox>
                      <b-collapse v-model="useAbon" class="p-0 m-0">
                        <b-form-select
                          v-if="auth && auth.abons && Object.keys(auth.abons).length>0"
                          v-model="currentAbon"
                          @change="changeAbon()"
                          :disabled="!useAbon"
                          :class="(useAbon && !currentAbon)?'border-danger mb-3':'mb-3'"
                          :style="(checkedSeats[0] && seatInfo[checkedSeats[0]] && !validDateServiceAbon(auth.abons[currentAbon],ondate,(seatInfo[checkedSeats[0]]).nomenu))?'border-danger;background-color:rgb(255, 114, 114);color: black;':'background-color: #fff;color: black;'"
                        >
                          <!--:style="(useAbon && checkedSeats[0] && seatInfo[checkedSeats[0]] && !validDateServiceAbon(auth.abons[currentAbon],ondate,(seatInfo[checkedSeats[0]]).nomenu))?'border-danger;background-color:rgb(255, 114, 114);color: black;':'background-color: #fff;color: black;'"-->
                          <!--validDateAbon(auth.abons[currentAbon],ondate)-->
                          

                          <template v-for="(a,rn) in auth.abons">
                            <b-form-select-option 
                              :value="a.rn"
                              :key = rn
                              :style = "(!validDateServiceAbon(a,ondate,(seatInfo[checkedSeats[0]]).nomenu))?'color:red;background-color:white':'color:black;background-color:white'"
                            >{{a.xnomenu}}</b-form-select-option>
                          </template>  
                        </b-form-select>
                        <div class="alert alert-danger" v-if="useAbon && checkedSeats[0] && seatInfo[checkedSeats[0]] && !validDateServiceAbon(auth.abons[currentAbon],ondate,(seatInfo[checkedSeats[0]]).nomenu)">
                          Невідповідний абонемент !
                        </div>  
                      </b-collapse>  
                    </div>
                    <div v-else-if="checkedSeats.length>1" class="alert alert-warning">
                      <b-icon icon="exclamation-triangle" class="mr-2"></b-icon>Абонемент задіяти не можна
                    </div>
                  </template>  

                  <template v-if="!nulSeats.includes(checkedSeats[0])"><!--"(((statesdata[sts])[ondate])[currentShift]===0 && (!roomsdata[currentRoom] || roomsdata[currentRoom][ondate][currentShift]!=2))?'info':'danger'"-->
                    <b-alert 
                      :variant = "(isStateFree(sts))?'info':'danger'"
                      v-for="sts of checkedSeats" :key="sts"
                      show
                      dismissible
                      fade
                      @dismissed="checkSeat(sts)"
                      class="text-left"
                    >
                      {{(seatInfo[sts]).fullname}}
                      <h5>
                        <b-badge variant="light" class="mr-1">
                          {{getSeatLabel((seatInfo[sts]).namerow,(seatInfo[sts]).rownum)}}&nbsp;
                          {{getSeatLabel((seatInfo[sts]).namecol,(seatInfo[sts]).seat)}}
                        </b-badge>
                        <b-badge variant="success" class="" v-if="!useAbon || !currentAbon">
                          <template v-if="settings && settings.multi_shift && currentShifts.length>1">
                            <template v-for="(sft,idx) of currentShifts">
                              <template v-if="prices[sts] && prices[sts][ondate]">
                                <templates v-if="idx>0" :key="sft"> + </templates>{{prices[sts][ondate][sft] | toCurrency}}
                              </template>
                            </template>  
                          </template>
                          <template v-else>
                            <template v-if="prices[sts] && prices[sts][ondate]">
                              {{prices[sts][ondate][currentShifts[0]] | toCurrency}}
                            </template>
                            <template v-else>---</template>   
                          </template>  

                          <!--
                          <template  
                            v-if="settings && settings.multi_shift && currentShifts.length>1"
                          >{{currentShifts.length}} x </template>{{(seatInfo[sts]).price | toCurrency}}-->

                        </b-badge>

                        <!-- priceResult=this.prices[seatnum][ondt][this.currentShift ]-->

                        <b-badge variant="info" class="ml-1"> <!-- v-if="settings && settings.multi_shift && currentShifts.length>1"-->
                          {{getTimeFromShiftList()}}
                        </b-badge>

                      </h5>
                    </b-alert>
                   
                  </template> 
                  <template v-else><!--:variant = "(((statesdata[sts])[ondate])[currentShift]===0 && (!roomsdata[currentRoom] || roomsdata[currentRoom][ondate][currentShift]!=2))?'info':'danger'"-->
                    <b-alert 
                      :variant = "(isStateFree(sts))?'info':'danger'"
                      v-for="sts of checkedSeats" :key="sts"
                      show
                      dismissible
                      fade
                      @dismissed="checkSeat(sts)"
                      class="text-left"
                    >
                      {{(seatInfo[sts]).fullname}}
                      <h5>
                        <!--b-badge variant="success" class="" v-if="!useAbon || !currentAbon">
                          <template  v-if="settings && settings.multi_shift && currentShifts.length>1">{{currentShifts.length}} x </template>{{(seatInfo[sts]).price | toCurrency}}
                        </b-badge-->

                        <b-badge variant="success" class="" v-if="!useAbon || !currentAbon">
                          <template v-if="settings && settings.multi_shift && currentShifts.length>1">
                            <template v-for="(sft,idx) of currentShifts">
                              <template v-if="prices[sts]">
                                <templates v-if="idx>0" :key="sft"> + </templates>{{prices[sts][ondate][sft] | toCurrency}}
                              </template>  
                            </template>  
                          </template>
                          <template v-else>
                            <template v-if="prices[sts]">
                              {{prices[sts][ondate][currentShifts[0]] | toCurrency}}
                            </template>  
                          </template>  
                        </b-badge>

                        <b-badge variant="info" class="ml-1"> <!-- v-if="settings && settings.multi_shift && currentShifts.length>1"-->
                          {{getTimeFromShiftList()}}
                        </b-badge>
                      </h5>
                    </b-alert>
                  </template> 
                  <!------CALENDAR BEGIN------------------------------------------>
                  <template v-if="settings && settings.multi_days">
                    <template v-if="checkedSeats.length==1"><!--вопрос: можно ли использовать календарь для заказа зала, или только для одного места?-->
                      <div class="m-0 p-0 text-left">
                        <b-form-checkbox v-model="multiDateEnable"  class="mr-2 my-2">Декілька дат</b-form-checkbox>
                      </div>  
                      <b-collapse v-model="multiDateEnable" class="my-2">
                        <b-calendar
                          :date-disabled-fn="dateDisabledExt"
                          :date-info-fn="dateIsSelected"
                          :selected-variant="activeVariant"
                          @selected="clickMultiDate"
                          v-model="vmodelDate"
                          @input="vmodelDate=null"
                          locale="uk" 
                          start-weekday="1"
                          :min= "minOndate"
                          :max= "maxOndate"
                          style=""
                          hide-header
                          
                          no-key-nav
                          block
                        ><!--no-highlight-today-->

                          <!--template v-slot:nav-next-month>
                            <b-icon icon="arrow-right-square-fill" variant="dark" class="h3"></b-icon>
                          </template>
                          <template v-slot:nav-prev-month>
                            <b-icon icon="arrow-left-square-fill" variant="dark" class="h3"></b-icon>
                          </template>
                          <template v-slot:nav-prev-year></template>
                          <template v-slot:nav-next-year></template>
                          <template v-slot:nav-this-month></template-->

                        
                        </b-calendar>
                      </b-collapse>  
                    </template>
                  </template>  
                      

                    <!------CALENDAR END------------------------------------------>

                </div>


              <!--1-END--------------------------------------->


                <!--@click="book_busy=false;isBooking=true"-->
                <!--=v-if="!currentDateTabMark || (currentDateTabMark && ((statesdata[currentDateTabMark])[ondate])[currentShift]===0 && (!roomsdata[currentRoom] || roomsdata[currentRoom][ondate][currentShift]!=2))"-->
                <template v-if="!currentDateTabMark || (currentDateTabMark && isStateFree(currentDateTabMark))">
                  <div v-if="!useAbon || !currentAbon" class="m-0 p-0 text-left">
                    <b-form-checkbox v-model="readyToPay"  class="mr-2 my-2">Перейти до оплати</b-form-checkbox>  
                  </div>  

                  <b-button 
                    block
                    @click="(!nulSeats.includes(checkedSeats[0]))?goBook():goBook(checkedSeats[0])"
                    variant="success"
                    class="p-2 shadow-none"
                    :disabled="useAbon && (!currentAbon || (checkedSeats[0] && seatInfo[checkedSeats[0]] && !validDateServiceAbon(auth.abons[currentAbon],ondate,(seatInfo[checkedSeats[0]]).nomenu)))"
                  >
                    Забронювати
                    <b-icon aria-hidden="true" icon="cart4" variant="white" class="mx-1"></b-icon>
                    <template v-if="!useAbon || !currentAbon">
                      <!--template v-if="!multiDateEnable">{{sum | toCurrency}}</template>
                      <template v-else>{{selectedDates.length * sum | toCurrency}}</template-->
                      {{resultSum| toCurrency}}
                    </template>
                  </b-button>

                </template>  

              </b-overlay>
            </div>
          </b-sidebar>



  


    <!------------------------------------------------------------------------------------------>
    <!--вОВЗМОЖНО ЭТО ВООБЩЕ МОЖНО УБРАТЬ!!!-->
          <b-modal 
              id="login-modal" ref="login-modal"
              :hide-header-close="settings && settings.disable_registration"
              hide-footer
              hide-header-
              header-bg-variant="bruvis"
              header-text-variant="light" 
              centered 
              title="Авторизація"
              v-model="needlogin" 
              @hidden="busy = false" 
              :no-close-on-backdrop="true"
            >
              <LoginWindow :settings="settings" @onlogin="afterLogin()" />
          </b-modal>
    <!--------hide-header --              hide-header-close -------------------------------------------------------------------------------->
          <b-modal 
              id="booking-modal" ref="booking-modal"
              hide-header-close
              hide-footer-
              centered 
              scrollable 
              size="md" 
              v-model="isBooking" 
              @hidden="busy = false" 
              class="p-0"
              :no-close-on-backdrop="true"
            >
              <template #modal-title>
                <b-icon icon="cart4" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Бронювання ({{new Date(ondate)  | dateFormat('dddd D MMMM')}})</span>
              </template>
              <b-overlay
                :show="book_busy && !widget_is_visible"
                rounded 
                variant="white"
                blur="2px" 
                opacity="0.6"
                spinner-variant="bruvis"
                class="d-inline-block w-100"
              >
                <div id="booking" v-show="!widget_is_visible">
                  <div style="" class="booking-modal-body">
                      <div>
                          <div class="form-group booking-form">
                                <template v-if="!auth || !auth.visitor || !auth.visitor.visitorRN">
                                  <div>
                                  <table class="w-100">
                                    <tr>
                                      <td>
                                        <label class="mr-2" for="booking_email">E-mail</label>
                                      </td>
                                      <td>  
                                        <input v-model="booking_email" id="booking_email" type="text" class="form-control my-2" placeholder_="E-mail" required>
                                      </td>
                                    </tr>    
                                    <tr>
                                      <td>
                                        <label class="mr-2" for="booking_name">ПІБ</label>
                                      </td>
                                      <td>
                                        <input v-model="booking_name" id="booking_name" type="text" class="form-control my-2" placeholder="ПІБ" required>
                                      </td>
                                    </tr>
                                  </table>      
                                  </div>
                                </template>
                                
                                <template v-else>
                                  <table class="w-100" v-if="checkedSeats.length===1 || currentDateTabMark">
                                    <tr><td class="px-2">
                                    <b-form-checkbox 
                                      v-model="useAbon" 
                                      class="mx-2"
                                      :disabled="this.settings && this.settings.disable_anonym"
                                    >
                                      Задіяти абонемент
                                    </b-form-checkbox>
                                    </td><td>
                                    <b-form-select
                                      v-if="auth && auth.abons"
                                      v-model="currentAbon"
                                      @change="changeAbon()"
                                      :disabled="!useAbon"
                                      :class="(useAbon && !currentAbon)?'border-danger;':''"
                                      :style="(useAbon && !validDateAbon(auth.abons[currentAbon],ondate))?'border-danger;background-color:rgb(255, 114, 114);color: black;':'background-color: #fff;color: black;'"
                                    >
                                      <!--
                                        value-field="rn"
                                        text-field="xnomenu"
                                        :options="auth.abons.filter(item => validDateAbon(item,ondate))"
                                      -->
                                      <template v-for="(a,rn) in auth.abons">
                                        <b-form-select-option 
                                          :value="a.rn"
                                          :key = rn
                                          :style = "(!validDateAbon(a,ondate))?'color:red;background-color:white':'color:black;background-color:white'"
                                        >{{a.xnomenu}}</b-form-select-option>
                                      </template>  
                                    </b-form-select>
                                    </td>
                                    </tr>
                                  </table>  
                                  <div v-else-if="checkedSeats.length>1" class="alert alert-warning">
                                    Абонемент задіяти не можна
                                  </div>
                                </template>  

                                <div v-if="error_login_msg" class="text-danger my-2">{{error_msg}}</div>
                     
                                <div>
                                  <table class="w-100" >
                                    <tr> 
                                      <td class="py-2 px-3" v-if="rooms && currentRoom && rooms[currentRoom]">
                                        {{(rooms[currentRoom]).objName}}
                                      </td> 
                                      <td class="py-2 px-2" v-if="shifts && shiftspec && currentShift && shiftspec[currentShift] && shifts[((schemes[currentScheme]).specs[onweekdays[ondate]]).shift]">
                                        <b-icon icon="clock-history" class="mx-1" />
                                        <!--
                                          ВРЕМЕННО
                                        
                                        {{floatToTime((((shifts[((schemes[currentScheme]).specs[onweekdays[ondate]]).shift]).specs)[currentShift]).timeFr)}}-{{floatToTime((((shifts[((schemes[currentScheme]).specs[onweekdays[ondate]]).shift]).specs)[currentShift]).timeTo)}}
                                        -->
                                        {{floatToTime((shiftspec[currentShift]).timeFr)}}-{{floatToTime((shiftspec[currentShift]).timeTo)}}
                                                                                  
                                      </td> 
                                    </tr>
                                  </table>
                                </div>


                                <div>
                                  <table class="table w-100" >
                                    <template v-if="!nulSeats.includes(checkedSeats[0])">
                                      <template v-if="checkedSeats.length==1">
                                        <tr>
                                          <td class="py-0">{{(seatInfo[checkedSeats[0]]).fullname}}</td> 
                                          <td class="py-0">{{getSeatLabel((seatInfo[checkedSeats[0]]).namerow,(seatInfo[checkedSeats[0]]).rownum)}} {{getSeatLabel((seatInfo[checkedSeats[0]]).namecol,(seatInfo[checkedSeats[0]]).seat)}}</td>
                                          <td class="text-right py-0">
                                            <template v-if="!useAbon || !currentAbon">
                                              {{(seatInfo[checkedSeats[0]]).price | toCurrency}}
                                            </template>
                                          </td>
                                        </tr>  
                                      </template>
                                      <template v-else>
                                        <tr v-for="sts of checkedSeats" :key="sts"> 
                                          <td class="py-0">{{(seatInfo[sts]).fullname}}</td> 
                                          <td class="py-0">{{getSeatLabel((seatInfo[sts]).namerow,(seatInfo[sts]).rownum)}}&nbsp;{{getSeatLabel((seatInfo[sts]).namecol,(seatInfo[sts]).seat)}}</td>
                                          <td class="text-right py-0">
                                            <template v-if="!useAbon || !currentAbon">
                                              {{(seatInfo[sts]).price | toCurrency}}
                                            </template>  
                                          </td>
                                        </tr>
                                      </template>        
                                      <tr v-if="!useAbon || !currentAbon"><td colspan="3" class="text-right py-2">
                                        Сума до сплати: {{sum | toCurrency}}
                                      </td></tr>
                                    </template>  
                                    <template v-else>
                                      <tr> 
                                        <td class="py-0">{{(checkedSeats[0]).fullname}}</td> 
                                      </tr>    
                                      <tr v-if="!useAbon || !currentAbon"><td colspan="3" class="text-right py-2">
                                        Сума до сплати: {{(seatInfo[checkedSeats[0]]).price | toCurrency}}
                                      </td></tr>
                                    </template>  
                                  </table>  
                                </div>

                          </div>
                      </div>
                  </div>
                </div>
              </b-overlay>

              <div id="liqpay_checkout"></div>


              <template #modal-footer>
                <b-button 
                  variant="success" class="shadow-none" 
                  @click="(!nulSeats.includes(checkedSeats[0]))?goBook():goBook(checkedSeats[0])"
                  v-show="!widget_is_visible"
                  :disabled="useAbon && (!currentAbon || !validDateAbon(auth.abons[currentAbon],ondate))"
                >
                  <b-icon icon="bag-check" class="shadow-none"></b-icon> Забронювати
                  <template v-if="!useAbon || !currentAbon"> та сплатити</template>
                </b-button>

                <!--b-button v-if="ticketToDownload" variant="info" @click="ticketDownload(ticketToDownload)">
                  <b-icon icon="download" class="shadow-none"></b-icon> Загрузити квіток
                </b-button-->
                
                <b-button v-if="!widget_is_visible" variant="outline-secondary" @click="isBooking=false">
                  Відміна
                </b-button>
                <b-button v-else variant="info" @click="widget_is_visible=false;isBooking=false;ticketToDownload=null">  
                  Закрити
                </b-button>
          </template>  
          </b-modal>
    <!------------------------------------------------------------------------------------------>

    <!--BASKET BEGIN--------------------------------------------------------------------->
          <!--            @shown  ="(readyToPay)?(payUnpayedTickets(),readyToPay=false):null"-->
          <b-modal 
            id="unpayedtickets-modal" 
            hide-header-close- centered scrollable size="lg" 
            :no-close-on-backdrop="true"
          >
            <template #modal-title>
              <b-icon icon="cart4" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Неоплачені бронювання</span>
              
            </template>

            <!--b-overlay
              :show="!widget_is_visible && readyToPay"
              rounded
              variant="white"
              blur="2px" 
              opacity="0.9"
              spinner-variant="bruvis"
              class="d-inline-block w-100"
              no-center
              style="inset:0px;"
            -->
            <div v-show="!widget_is_visible" ><!-- && !readyToPay-->

              <!--table 
                class="table orders-table" 
                sticky-header="true" 
                v-if="unpayedtickets && unpayedtickets.length > 0"
              -->
              <div class="basket-rows" v-if="unpayedtickets && unpayedtickets.length > 0">
                <!--thead>
                    <th class="align-bottom border-top-0 border-bottom-0" width="1"></th>
                    <th class="align-bottom border-top-0 border-bottom-0" width="500"></th>
                    <th class="align-bottom border-top-0 border-bottom-0 pl-3" colspan="2"></th>
                </thead-->
                <!--tbody-->
                  <template v-for="ci of unpayedtickets">
                    <div class="basket-row" :id="'ticket_'+ci.ticketRN" :key="ci.rn" :class="( ci.payUpTo && new Date(ci.payUpTo) < new Date() )?'text-muted order-failed orders-specs-row':'orders-specs-row'">
                      <div
                        class="basket-cell basket-cell1" 
                        :title="ci.rn"
                      >
                        <span class="text-nowrap"> 
                          {{new Date(ci.ondate) | dateFormat('dddd D MMMM') }}
                        </span>  
                        <span class="text-nowrap"> 
                          <template v-if="ci.timeFr && ci.timeTo"><b-icon icon="clock-history" class="mx-1" />{{ci.timeFr}}-{{ci.timeTo}}</template>
                          <template v-else>
                              <template v-if="ci.smena">{{ci.smena}}</template>
                              <template v-else-if="ci.shift && shiftspec && shiftspec[ci.shift]"><b-icon icon="clock-history" class="mx-1" />{{floatToTime((shiftspec[ci.shift]).timeFr)}}-{{floatToTime((shiftspec[ci.shift]).timeTo)}}</template>
                            </template>
                        </span>  

                      </div>
                      <div class="basket-cell basket-cell2" >

                        <template v-if="ci.type==='grouplesson' || ci.groupname">
                          {{ci.objname}} : {{ci.xservice}}
                          <!--Група : {{ci.groupname}}--><br>
                          <!--span class="text-nowrap"> 
                            <b-icon icon="clock-history" class="mx-1" />
                            <template v-if="ci.timeFr && ci.timeTo">{{ci.timeFr}}-{{ci.timeTo}}</template>
                          </span-->  
                          <h5>
                            <b-badge variant="primary" title="Назва групи">
                              <b-icon icon="people-fill" class="mr-2"></b-icon>{{ci.groupname}}
                            </b-badge>
                          </h5>  
                        </template>

                        <template v-else-if="ci.type==='specialist' || ci.xworker">
                          {{ci.objname}} : {{ci.xservice}}
                          <!--span class="text-nowrap"> 
                            <b-icon icon="clock-history" class="mx-1" />
                            <template v-if="ci.timeFr && ci.timeTo">{{ci.timeFr}}-{{ci.timeTo}}</template>
                          </span-->  
                          <h5>
                            <b-badge variant="primary" >
                              <b-icon icon="person-fill" class="mr-2"></b-icon>{{ci.xworker}}
                            </b-badge>
                          </h5>  
                        </template>

                        <template v-else>
                          {{ci.objname}} {{ci.fullname}}
                          <span class="text-nowrap"> 
                            <!--b-icon icon="clock-history" class="mx-1" /-->
                            <!--template v-if="ci.timeFr && ci.timeTo">{{ci.timeFr}}-{{ci.timeTo}}</template-->
                            <!--template v-else>
                              <template v-if="ci.smena">{{ci.smena}}</template>
                              <template v-else-if="ci.shift && shiftspec && shiftspec[ci.shift]">{{floatToTime((shiftspec[ci.shift]).timeFr)}}-{{floatToTime((shiftspec[ci.shift]).timeTo)}}</template>
                            </template-->
                          </span>  
                          <h5 v-if="getSeatLabel(ci.namerow,ci.rownum) || getSeatLabel(ci.namecol,ci.seat)">
                            <b-badge variant="primary" :title="ci.namerow+'|'+ci.rownum+'|'+ci.namecol+'|'+ci.seat">
                              {{getSeatLabel(ci.namerow,ci.rownum)}} {{getSeatLabel(ci.namecol,ci.seat)}}
                            </b-badge>
                          </h5>  
                        </template>
                        <!--div>Граничний термін оплати : {{new Date(ci.payUpTo) | dateFormat('D MMMM H:mm', dateFormatConfig)}}</div-->
                      </div>  
                      <div class="basket-cell basket-cell3" >
                        {{ci.paySum | toCurrency}}<!--ci.shiftscount*ci.paySum-->
                      </div>
                      <div class="basket-cell basket-cell4" >
                        <h5 class="text-nowrap text-right" v-if="counters[ci.rn]">
                          <b-badge variant="warning" class="mr-1">
                            <b-iconstack font-scale="1" class="mr-1">
                              <b-icon stacked icon="hourglass-top" animation="fade" ></b-icon>
                              <b-icon stacked icon="hourglass-split" animation="fade" ></b-icon>
                              <b-icon stacked icon="hourglass"></b-icon>
                              <b-icon stacked icon="hourglass-bottom" animation="fade" ></b-icon>
                              <b-icon stacked icon="hourglass"></b-icon>
                            </b-iconstack> {{counters[ci.rn]}}
                          </b-badge>  
                          <b-badge variant="danger" @click="deleteTickets(ci.ticketRN)" title="Анулювати бронювання" role="button">
                            <b-icon icon="x-lg" variant="white"></b-icon>
                          </b-badge>  
                        </h5> 
                        <h5 class="text-nowrap text-right" v-else>
                          <b-badge variant="danger"  @click="deleteVisit(ci.visitRN)" title="Анулювати бронювання" role="button">
                            <b-icon icon="x-lg" variant="white"></b-icon>
                          </b-badge>  
                        </h5>   
                      </div>  
                    </div>
                  </template>
                <!--/tbody-->
              </div>
              <div v-else class="text-center">
                Актуальних бронювань немає
              </div>  
            </div>
            <!--/b-overlay-->  

            <div id="liqpay_checkout2"></div>

            <template #modal-footer>  
              <b-button 
                variant="success" 
                @click="payUnpayedTickets()" 
                :disabled="unpayedtickets.length == 0"
                v-if="unpayedtickets.length > 0"
                v-show="!widget_is_visible"
              >
                <b-icon icon="credit-card"></b-icon> Оплатити {{unpayedticketssum | toCurrency}}
              </b-button>
              <b-button variant="bruvis" @click="widget_is_visible=false,$bvModal.hide('unpayedtickets-modal')">
                Закрити
              </b-button>
          </template>
          </b-modal>

<!--BASKET END--------------------------------------------------------------------->

<!--PRINTLIST BEGIN--------------------------------------------------------------------->
          <!--            @shown  ="(readyToPay)?(payUnpayedTickets(),readyToPay=false):null"-->
          <b-modal 
            id="printlist-modal" 
            hide-header-close- centered scrollable size="md" 
            :no-close-on-backdrop="true"
          >
            <template #modal-title>
              <b-icon icon="printer" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Оплачені квитки</span>
              
            </template>

            <template v-for="pl of printlist">
              <template v-if="pl.groupname">

                <b-card no-body class="mb-2" :key="pl.rn">
                  <template #header>
                    <b-row>
                      <b-col>{{new Date(pl.ondate)|dateFormat('dddd D MMMM') }}</b-col>
                    </b-row>
                  </template>
                  <b-card-body>
                    <b-card-title>Група: {{pl.groupname}}</b-card-title>
                    <b-card-sub-title class="mb-2">{{pl.xservice}}</b-card-sub-title>
                    <b-button variant="bruvis" block @click="ticketDownload(pl.ticketRN,pl.code)">
                      <b-icon icon="printer-fill" class="mx-1" /> Роздрукувати
                    </b-button>
                  </b-card-body>
                </b-card>

              </template> 
              <template v-else-if="pl.xworker">

                <b-card no-body class="mb-2" :key="pl.rn">
                  <template #header>
                    <b-row> 
                      <b-col>{{new Date(pl.ondate)|dateFormat('dddd D MMMM') }}</b-col>
                      <b-col style="text-align:right"><b-icon icon="clock-history" class="mx-1" />{{pl.timeFr}} - {{pl.timeTo}}</b-col>
                    </b-row>
                  </template>
                  <b-card-body>
                    <b-card-title>{{pl.xworker}}</b-card-title>
                    <b-card-sub-title class="mb-2">{{pl.xservice}}</b-card-sub-title>
                    <b-button variant="bruvis" block @click="ticketDownload(pl.ticketRN,pl.code)">
                      <b-icon icon="printer-fill" class="mx-1" /> Роздрукувати
                    </b-button>
                  </b-card-body>
                </b-card>

              </template> 
              <template v-else>

                <b-card no-body class="mb-2" :key="pl.rn">
                  <template #header>
                    <b-row> 
                      <b-col>{{new Date(pl.ondate)|dateFormat('dddd D MMMM') }}</b-col>
                      <b-col style="text-align:right"><b-icon icon="clock-history" class="mx-1" />{{pl.timeFr}} - {{pl.timeTo}}</b-col>
                    </b-row>
                  </template>
                  <b-card-body>
                    <b-card-title>{{pl.objname}}</b-card-title>
                    <b-card-sub-title class="mb-2">{{pl.fullname}}</b-card-sub-title>
                    <b-card-text>
                      {{getSeatLabel(pl.namerow,pl.rownum)}} {{getSeatLabel(pl.namecol,pl.seat)}}
                    </b-card-text>
                    <b-button variant="bruvis" block @click="ticketDownload(pl.ticketRN,pl.code)">
                      <b-icon icon="printer-fill" class="mx-1" /> Роздрукувати
                    </b-button>
                  </b-card-body>
                </b-card>

              </template>
            </template>      
            
            <!--div class="w-100 printlist">
              <template v-for="pl of printlist">
                <div :key="pl.rn" role="button" class="text-center m-3 p-2" @click="ticketDownload(pl.ticketRN,pl.code)">
                  <b-iconstack font-scale="3">
                    <b-icon stacked icon="file-pdf" variant="danger"></b-icon>
                    <b-icon stacked icon="file" variant="dark"></b-icon>
                  </b-iconstack>
                  <div class="printlist-label">{{pl.ondate}}</div>
                </div>  
              </template>  
            </div-->  

            <template #modal-footer>  
              <b-button variant="bruvis" @click="widget_is_visible=false,$bvModal.hide('printlist-modal')">
                Закрити
              </b-button>
            </template>
          </b-modal>


<!-- Zakazy Payed Begin ------------------------------------------------------------>
            <b-modal 
              v-if="payed || (auth && auth.abons)"
              id="zakazy-payed-modal" hide-header-close- 
              centered scrollable size="lg" :no-close-on-backdrop="true" ok-only
              ok-variant="bruvis"
              ok-title="Закрити"
            >
              <template #modal-title>
                <b-icon icon="cart4" class="rounded bg-success p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Оплачені бронювання </span>
              </template>
              <ZakazyPayed 
                :zakazy_payed="payed"
                :visits= "auth"
                @ondelete = "init_zakazy_afterpay()"
                @needlogin = "needlogin = true"
              ></ZakazyPayed>

            </b-modal>
<!-- Zakazy Payed END ------------------------------------------------------------> 
<!--Archive--------------------------------------------------------------------->    
<b-modal 
  id="archive-modal" 
  size="lg"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
>
  <template #modal-title>
    <b-icon icon="box-seam" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Бронювання</span>
  </template>
  
  <ArchivZakazov
    :seatinfo = seatInfo
    @needlogin = "needlogin = true"
    @onPay = "$bvModal.show('unpayedtickets-modal')"
    @ondelete = "updateStates(),updateBasket()"
  />
  
</b-modal>   

<!--History--------------------------------------------------------------------->    
<b-modal 
  id="history-modal" 
  size="lg"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only-
  ok-variant="bruvis"
  ok-title="Закрити"
  @hidden = "borg_widget_is_visible=false;ready_pay_borg=false"
>
  <template #modal-title>
    <b-icon icon="box-seam" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Історія покупок</span>
  </template>
  
  <HistoryPay
    :seatinfo = seatInfo
    :borg_widget_is_visible = "borg_widget_is_visible"
    :ready_pay_borg = "ready_pay_borg"
    @showWidget = "borg_widget_is_visible=true"
    @hideWidget = "borg_widget_is_visible=false"
    @readyPaySet = "ready_pay_borg=true"
    @readyPayReset = "ready_pay_borg=false"
    @needlogin = "needlogin = true"
  />
  <template #modal-footer = "{ ok }">
        <div class="w-100">
          <div class="float-right">
            <template v-if="!ready_pay_borg">
              <b-button
                v-if="borg_widget_is_visible"
                variant="bruvis"
                class="ml-2"
                @click="borg_widget_is_visible=false"
              >
                &lt; Повернутись
              </b-button>
              <b-button
                variant="bruvis"
                class="ml-2"
                @click="ok()"
              >
                Закрити
              </b-button>
            </template>
            <template v-else>
              <b-button
                variant="bruvis"
                class="ml-2"
                @click="borg_widget_is_visible=false;ready_pay_borg=false"
              >
                Ok
              </b-button>
            </template>    
          </div>  
        </div>
      </template>
  
</b-modal>   


<!--USER Profile--------------------------------------------------------------------->    
<b-modal 
  id="userprofile-modal" 
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
>
  <template #modal-title>
    <b-icon icon="file-person" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Профіль користувача</span>
  </template>

  <!--
    :clubcards="clubcards"
    :enable_refill="salepoint && salepoint.enable_clubcard_refill"
    @onRefill = "init_afterrefill()"
    -->
  <UserProfile 
    :user="auth"
    :debugmode="debugmode"  
    @needlogin = "needlogin = true"
    @onchangename = onChangeName()
    @setVisitorInfo = setVisitorInfo
  />
</b-modal>

<!--Abonements--------------------------------------------------------------------->    
<b-modal 
  id="abonements-modal" 
  size="md"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
>
  <template #modal-title>
    <b-icon icon="credit-card2-front" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Абонементи в наявності</span>
  </template>
  <AboneMents
    @needlogin = "needlogin = true"
    @onupdate = "init()"
    @updateabons = "updateAbons()"
  /> 
</b-modal>   

<!--Salepoints--------------------------------------------------------------------->    
<b-modal 
  id="salepoints-modal" 
  size="md"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
>
  <template #modal-title>
    <b-icon icon="geo-alt-fill" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Точки продажу</span>
  </template>
  <SalePoints
    :places = places
    :salepoints = salepoints
    :point_rn = point_rn
  /> 
</b-modal>   


<!--Specialists--------------------------------------------------------------------->    
<!--b-modal 
  id="specialists-modal" 
  size="lg"
  hide-header-close- 
  centered 
  scrollable 
  :no-close-on-backdrop="true" 
  ok-only
  ok-variant="bruvis"
  ok-title="Закрити"
  v-if="auth && settings"
>
  <template #modal-title>
    <b-icon icon="credit-card2-front" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
    <span class="p-2 h5 mr-auto">Спеціалісти</span>
  </template>
  <SpeciaLists
    @needlogin = "needlogin = true"
    @onupdate = "this.init()"
  /> 
</b-modal-->   





 
  </div>
</template>

<script>

import LoginWindow from './components/login.vue'
import ZakazyPayed from './components/zakazy_payed.vue'
import UserProfile from './components/userprofile.vue'
import ArchivZakazov from './components/archive.vue'
import HistoryPay from './components/history.vue'
import AboneMents from './components/abonements.vue'
//import SpeciaLists from './components/specialists.vue'
import SpeciaListy from './components/specialisty.vue'
import GroupLessons from './components/grouplessons.vue'
import NewsData from './components/news.vue'
import SalePoints from './components/salepoints.vue'
//import { BIconBoxArrowInUpLeft } from 'bootstrap-vue'




export default {
  name: 'App',
  components: {
    LoginWindow,
    ZakazyPayed,
    UserProfile,
    ArchivZakazov,
    HistoryPay,
    AboneMents,
    //SpeciaLists,
    SpeciaListy,
    GroupLessons,
    NewsData,
    SalePoints
  },
  data: () => ({
    debugmode: false,
    razdel: 'news',
    logo :{"img": "/img/logo_jsol_mini.png","text":"Cow or King"},
    settings: {},
    ondate: null,
    ondates: null,
    onweekdays: null,
    cowdata: null,
    schemes: null,
    rootscheme: null,
    shifts: null,
    shiftspec: {},
    places: null,
    salepoints: null,
    hallobj: null,
    rooms: null,
    seats: null,
    nulSeats: [],
    roomNulseats: {},
    statesdata: null,
    states: null,
    roomsdata: null,
    datestates: {},
    roomstates: {},
    currentDateTabMark: null,
    currentDateTabMarkRooms: null,
    checkedSeats:[],
    seatInfo: null,
    roomInfo: null,
    prices: null,

    users: null,

    statesDateRoomShiftSeats: null,
    reservedDateRoomShift: null,
    freeDateRoomShift: {},
    
    auth: null,
    username: null,
    needlogin: false,
    currentAbon: null,
    useAbon: false,

    scrollLeft: null,
    navElement: null,
    ondateTabIndex: 0,
    ondateTabIndexBuffer: null,
    scrollMultiShift: 0,

    currentPlace: null,
    currentSalepoint: null,
    currentSalepointsList: [],
    hall_rp: null,
    point_rn: null,
    currentRoom: null,
    currentShift: null,
    disabledShifts: [],
    currentShifts:[],
    currentShiftsList:[],
    currentShiftsAssoc: null,
    selectedDates:[],
    vmodelDate: null,
    multiDateEnable: false,

    sum: 0,
    book_busy: false,
    globalbusy: false,
    navbusy: false,
    isBooking: false,
    booking_email: "victor-11@soft4all.com.ua",
    booking_name: "Victor",
    unpayedtickets: [],
    unpayedticketsTmp: null,
    printlist: null,
    //unpayedticketssum: null,
    selectedTopay: [],
    allSelected: false,
    amount: 0,

    unpayed: null,
    payed: null,
    archive: null,

    widget_is_visible: false,
    borg_widget_is_visible: false,
    ready_pay_borg: false,
    //showSidebar: false,

    getStatusInt: null,
    lpay: null,
    globalrns: null,
    ticketToDownload: null,
    readyToPay: false,

    countDown: null,
    counters:{},
    mincounter: null,

    showtooltip: false,
    txttooltip: '',
    posX: 0,
    posY: 0,

    zoomElement: null,
    zoomPosX:0,
    zoomPosY:0,
    zoomScale:1,
    zoomScaleTmpGlobal:1,
    onDrag: false,
    SVGclientWidth: null,
    SVGclientHeight: null,
    zoomControls: false,
    hovered: '',

    startX1: null,
    startY1: null,
    startX2: null,
    startY2: null,
    startDist: 0,
    needUpdateStatesGroup: false,
    needUpdateStatesSpecialist: false,
    gruppa2open: null,
    worker2open: null

  

  }),
  mounted(){
    //this.globalbusy = true
    if(!this.razdel) this.init()
    //this.globalbusy = false
    /*
    if(this.$refs["ondates-tabs"])
      this.navElement = this.$refs["ondates-tabs"].$el.querySelector("ul")
    if(this.$refs["shifts-tabs"])
      this.navElement2 = this.$refs["shifts-tabs"].$el.querySelector("ul")  
    */
  },

  created() {
    let liqpay = document.createElement('script');    
    liqpay.setAttribute('src',"https://static.liqpay.ua/libjs/checkout.js");
    document.head.appendChild(liqpay);

    this.$root.$on("change-salepoint", this.changeSalepoint )
    this.$root.$on("set-auth", this.setAuth )
    this.$root.$on("set-salepoints", this.setSalepoints )
  },
  destroy() {
    this.$root.$off("change-salepoint", this.changeSalepoint )
    this.$root.$off("set-auth", this.setAuth )
    this.$root.$off("set-salepoints", this.setSalepoints )
  },

  computed: {
    minOndate: function(){
      //const now = new Date()
      //const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      //const minDate = new Date(today)
      const minDate = new Date()
      //return minDate
      return (this.ondates != null)?new Date(this.ondates[0]):minDate
    },
    maxOndate: function(){
      const now = new Date()
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
      const maxDate = new Date(today)
      return (this.ondates != null)?new Date(this.ondates[this.ondates.length-1]):maxDate
    },
    positionUnlineStyle: function(){
      return 'left: '+this.posX+'px; top: '+this.posY+'px;'
    },
    unpayedticketssum: function(){
      var sum=0
      this.unpayedtickets.forEach((c) =>{sum+=c.paySum}) //*c.shiftscount
      return sum
    },
    
    shownRightSidebar: function(){
      return (document.getElementById('rightsidebar') && document.getElementById('rightsidebar').style.display!='none')
    },

    resultSum: function(){
      var result=0
//      if(this.settings && this.settings.multi_shift && this.currentShifts.length>1"){
      if(!this.multiDateEnable){
        this.checkedSeats.forEach((sts)=>{
          this.currentShifts.forEach((sft)=>{
            if(this.prices[sts]) result+=this.prices[sts][this.ondate][sft]
          })
        })
      }else{
        this.selectedDates.forEach((ondt)=>{
          this.checkedSeats.forEach((sts)=>{
            this.currentShifts.forEach((sft)=>{
              if(this.prices[sts]) result+=this.prices[sts][ondt][sft]
            })
          })
        })
      }  

  //    }
      return result
    }


    

  }, 
  
  watch:{
    currentDateTabMark: function (newmark, oldmark){
      if(!newmark && oldmark) console.log('currentDateTabMark',oldmark+' -> null')
      else this.setValidDateServiceAbon()
      //this.datestates = {}
    },
    states: function(newstates){

      this.statesDateRoomShiftSeats = {}
      this.reservedDateRoomShift = {}

      newstates.forEach((s) => {

        if(!this.statesDateRoomShiftSeats[s.date]) this.statesDateRoomShiftSeats[s.date]={}
        if(!this.statesDateRoomShiftSeats[s.date][s.rp]) this.statesDateRoomShiftSeats[s.date][s.rp]={}
        if(!this.statesDateRoomShiftSeats[s.date][s.rp][s.shift]) this.statesDateRoomShiftSeats[s.date][s.rp][s.shift]={}
        this.statesDateRoomShiftSeats[s.date][s.rp][s.shift][s.rn]=s.state
        
        if(this.nulSeats.includes(s.rn)) {
          if(!this.reservedDateRoomShift[s.date]) this.reservedDateRoomShift[s.date] = {}
          if(!this.reservedDateRoomShift[s.date][s.rp]) this.reservedDateRoomShift[s.date][s.rp] = {}
          this.reservedDateRoomShift[s.date][s.rp][s.shift] = (s.state>0)?2:0
        }  
      })

      var busynum
      var freenum
      Object.keys(this.statesDateRoomShiftSeats).forEach((d)=>{
        Object.keys(this.statesDateRoomShiftSeats[d]).forEach((r)=>{
          Object.keys(this.statesDateRoomShiftSeats[d][r]).forEach((sf)=>{
            if( 
               (
                !this.reservedDateRoomShift[d] ||
                !this.reservedDateRoomShift[d][r] ||
                !this.reservedDateRoomShift[d][r][sf]

               ) ||
               (              
                this.reservedDateRoomShift[d] &&
                this.reservedDateRoomShift[d][r] &&
                this.reservedDateRoomShift[d][r][sf] !=2 
               )
              ) {
                busynum=0
                freenum=0
                Object.keys(this.statesDateRoomShiftSeats[d][r][sf]).forEach((s)=>{
                  if(!this.nulSeats.includes(s)){
                    if(this.statesDateRoomShiftSeats[d][r][sf][s]==0){
                      freenum++
                    }else{
                      busynum++
                    }
                  }  
                })

                if(!this.reservedDateRoomShift[d]) this.reservedDateRoomShift[d]={}
                if(!this.reservedDateRoomShift[d][r]) this.reservedDateRoomShift[d][r]={}
                if(!this.reservedDateRoomShift[d][r][sf]) this.reservedDateRoomShift[d][r][sf]={}

                  if(busynum>0 && freenum>0)
                    this.reservedDateRoomShift[d][r][sf] = 1
                  else if(busynum==0 && freenum>0)
                    this.reservedDateRoomShift[d][r][sf] = 0
                  else if(busynum>0 && freenum==0)
                    this.reservedDateRoomShift[d][r][sf] = 2  
                  else
                    this.reservedDateRoomShift[d][r][sf] = -1  

                if(!this.freeDateRoomShift[d]) this.freeDateRoomShift[d]={}
                if(!this.freeDateRoomShift[d][r]) this.freeDateRoomShift[d][r]={}
                //if(!this.freeDateRoomShift[d][r][sf]) 
                this.freeDateRoomShift[d][r][sf]= freenum.toString()+'/'+(freenum+busynum).toString()   

              }else{
                if(!this.freeDateRoomShift[d]) this.freeDateRoomShift[d]={}
                if(!this.freeDateRoomShift[d][r]) this.freeDateRoomShift[d][r]={}
                this.freeDateRoomShift[d][r][sf]= '0'

              }


          })
        })
      })

      console.log('statesDateRoomShiftSeats',this.statesDateRoomShiftSeats)
      console.log('reservedDateRoomShift',this.reservedDateRoomShift)

      this.$nextTick().then(this.roomsRecolor())

    },
    ondate: function(n){
      this.currentShifts = []
      this.selectedDates = [n]
      //------------
      this.createShiftList()
      /*
      if(this.schemes[this.currentScheme] && 
        (this.schemes[this.currentScheme]).specs && 
        (this.schemes[this.currentScheme]).specs[this.onweekdays[n]] &&
        ((this.schemes[this.currentScheme]).specs[this.onweekdays[n]]).shift &&
        this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[n]]).shift]){
        var s = (this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[n]]).shift]).specs
        var sv = Object.values(s)
        sv.sort(function(a, b){
          return a.timeFr-b.timeFr
        })
        this.currentShiftsList = sv
        this.currentShiftsAssoc = s
      }  
      //-------------------
      this.detectCurrentShift()
      if(this.currentRoom && this.currentShift) this.seatsStatesRecalc()
      if(this.currentShift) this.roomsRecolor()
      this.clickLeft2(true)
      this.clickLeft22(true)
      */
      //this.$nextTick().then(this.roomsRecolor())
    },
    currentRoom: function (n,o){
      console.log('Room: '+o+'->'+n)
      if(o){
        this.currentDateTabMark = null
        this.checkedSeats = []
        this.selectedDates = [this.ondate]
      }

      if(this.rooms && this.currentRoom && this.rooms[this.currentRoom] && (this.rooms[this.currentRoom]).schemeRN) 
        this.currentScheme = (this.rooms[this.currentRoom]).schemeRN

      if(!this.currentScheme || !n) this.currentScheme = this.rootscheme  

      this.detectCurrentShift()

      /*
      var sfts = (this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]).specs
      var shiftTmp = null

      if((new Date()).getDate()==(new Date(this.ondate)).getDate() && (new Date()).getMonth()==(new Date(this.ondate)).getMonth()){
        console.log('Segodnya !!!',sfts)
        var t = new Date()
        var now = t.getHours() + t.getMinutes()/100
        this.disabledShifts = []
        
        Object.values(sfts).forEach((s)=>{
          if(s.timeFr < now){ 
            this.disabledShifts.push(s.rn)
          }else if(!shiftTmp){
            shiftTmp = s.rn  
          }
        })
        this.currentShift = shiftTmp
      }else{
        this.disabledShifts = []
        this.currentShift = Object.keys(sfts)[0]
      }
      */
      //this.currentShift = (Object.keys((this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]).specs))[0]
      //console.log('Keys',Object.keys((this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]).specs))
      //console.log('Values',Object.values((this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]).specs))
      // тут надо учесть сегодняшний день и закончившиеся смены

      this.checkRoomShift()

      if(this.currentRoom && this.currentShift) 
        this.$nextTick().then(this.seatsStatesRecalc)

      this.resetSVG()  

      if(this.roomNulseats[n] == this.currentDateTabMark) 
        this.$nextTick().then(this.switchSidebar('rightsidebar','on'))

      this.clickLeft2(true) //reset ///скроллируем до активной вкладки!!!!!
      this.clickLeft22(true)
    },

    currentShift: function(n){
      /*
      if(this.currentDateTabMark) 
        this.checkSeat()
      else  
        this.checkRoomShift()
      */
      this.checkedSeats = []
      this.currentDateTabMark = null
      this.selectedDates = [this.ondate]

      this.currentShifts=[n]

      /*
      var n = this.currentShifts.indexOf(rn)
      if(n===-1) this.currentShifts.push(rn)
      else 
        if(this.currentShifts.length>1) this.currentShifts = this.currentShifts.slice(0, n).concat(this.currentShifts.slice(n+1))
        //else if(this.currentShifts.length==1)
      */

      if(this.currentRoom && this.currentShift) this.seatsStatesRecalc()
      if(this.currentShift && this.ondate) this.roomsRecolor()
    },
    currentShifts: function(){
      this.checkedSeats = []
      this.selectedDates = [this.ondate]
      this.currentDateTabMark = null
      if(this.settings && this.settings.multi_shift){
        this.seatsStatesRecalc()
        this.roomsRecolor()
      }  
      this.checkRoomShift()      
    },
    checkedSeats: function(n){
      
      this.seatsCheckedRecalc()
      this.selectedDates = [this.ondate] // сбрасываем выбор нескольких дат

      if(n && n.length>0)  {
        var multi = (this.settings && this.settings.multi_shift)
        var free
        if(!multi)
          free=(((this.statesdata[n[0]])[this.ondate])[this.currentShift]===0 && (!this.roomsdata[this.currentRoom] || this.roomsdata[this.currentRoom][this.ondate][this.currentShift]!=2))
        else
          free=this.isStateFree(n[0])  

        if(document.getElementById('rightsidebar') && document.getElementById('rightsidebar').style.display=='none')
          if(free){
            console.log('Открываем!')
            this.$root.$emit('bv::toggle::collapse', 'rightsidebar')
          }  
      }else if(n && n.length==0){
        if(document.getElementById('rightsidebar') && document.getElementById('rightsidebar').style.display!='none'){
          console.log('Закрываем!')
          this.$root.$emit('bv::toggle::collapse', 'rightsidebar')
          this.book_busy = false
        }
      }
     
    },
/*    
    unpayed: function(u){

//console.log('Unpayed')

      var unpayedTmp=[],ch
      if(!u || u.length===0)return
      Object.values(u).forEach((uu) =>{
        uu.forEach((unp)=>{
//console.log(unp)          

          ch={
            "rn": unp.checkRN,
            "paySum": unp.paySum,
            "payUpTo": unp.payUpTo,
            "fullname": unp.fullname,
            "objname": unp.objname,
            "ticketRN": unp.ticketRN,
            "seatRN": unp.seatRN,
            "rownum": unp.rownum,
            "namerow": unp.namerow,
            "namecol": unp.namecol,
            "seat": unp.seat,
            "ondate": unp.timefr.substring(0,10),
            "shift": null,
            "timeFr": null,
            "timeTo": null,
            "smena" : unp.smena
          }
          console.log('Unpayed_Ticket',ch)          
          unpayedTmp.push(ch)
        })
      })
      this.unpayedtickets = unpayedTmp
    },
*/    
    unpayedtickets: function(newlist, oldlist){
      let diff,mindiff,minutes,seconds,purgeflag=[],tmp,counters={}
      console.log('Need to update!')
      console.log(oldlist)
      console.log(newlist)

      if(newlist){
        if(!this.razdel) this.updateStates()
        else if (this.razdel=='grouplessons') this.needUpdateStatesGroup = true
        else if (this.razdel=='specialisty')  this.needUpdateStatesSpecialist = true

        if(!this.countDown){
          this.countDown = setInterval(function(){
            if(!this.unpayedtickets || this.unpayedtickets.length===0){
              clearInterval(this.countDown)
              this.countDown = null
            }  
            mindiff=null
            this.unpayedtickets.forEach((t)=>{
              if(t.payUpTo){
                diff = ((new Date(t.payUpTo))-(new Date()) - 180000) // уменьшаем на 3 минуты
                if(diff > 0){
                  minutes = Math.floor(((diff % 86400000) % 3600000) / 60000);
                  seconds = Math.floor((((diff % 86400000) % 3600000) % 60000) /1000);
                  tmp = (minutes<10)?'0':''
                  tmp+= minutes.toString() + ':'
                  if(seconds<10)tmp+='0'
                  tmp+=seconds.toString()
                  //console.log(tmp)
                  counters[t.rn] = tmp
                  if((mindiff && diff < mindiff) || !mindiff) {
                    mindiff = diff
                    this.mincounter=tmp
                  }  
                }else{
                  purgeflag.push(t.ticketRN)
                }
              }  
            })
            this.counters = counters
            if(purgeflag && purgeflag.length>0){
              this.unpayedticketsTmp = []
              this.unpayedtickets.forEach((p) =>{
                if(!purgeflag.includes(p.ticketRN)) this.unpayedticketsTmp.push(p)
              })
              //this.deleteTickets(purgeflag.join(';'),true) //simulation of delete
              this.deleteTickets(purgeflag.join(';'))
              //надо пе6речитать стейты
              purgeflag = []

              //this.selectedTopay=[]
              //this.allSelected=false
              //this.recalcSumToPay()

              if(this.unpayedticketsTmp.length===0) {
                clearInterval(this.countDown)
                this.countDown = null
                this.mincounter=null
                //console.log('clear countdown')
              }
              //Закомментирую, т.к. єто произойдеть в функции deleteTickets
              //this.unpayedtickets = this.unpayedticketsTmp  
            }      
            //console.log('countdown')
          }.bind(this), 1000);  
        }else{
          console.log('Есть countDown!')
        }
        
      }   
    },
    useAbon: function(newval){
      if(newval && !this.currentDateTabMark && this.checkedSeats.length>1) this.checkedSeats=[]
    },
    auth: function(newval){
      if(!newval) {
        this.useAbon = null
        this.currentAbon = null
      }
    },
    razdel: function(n,o){
      // чистка при переходе между разделами
      this.currentDateTabMark = null
      this.checkedSeats = []
      console.log('Watch razdel')
      if(o && !n){
        console.log('Watch razdel init')
        this.init()
      }   
    },
    /*
    currentPlace: function(){
      this.currentSalepointsList = this.salepoints.filter(s => s.place_rn==this.currentPlace)
      if(this.currentSalepointsList && this.currentSalepointsList.length>0)
        this.currentSalepoint = this.currentSalepointsList[0]
    },
    */
    currentSalepoint: function(){
      this.createShiftList()
      console.log('Watch currentSalepoint')
      var razdel = this.razdel
      if(razdel && razdel!='news'){
        this.razdel = 'tmp';
        this.$nextTick(() => {
          this.razdel = razdel
        });
      }  
    }
    
  },
  methods:{
    logout(){
      var postData = {
        "action":"logout",
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            this.username = null
            this.auth = null
            this.init()
            this.razdel = 'news'
            //console.log('init')
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },
    afterLogin(){
      this.needlogin = false 
      if(this.razdel)
        this.razdel=null
      else 
        this.init()
    },
    init(rn){
      this.globalbusy = true
      this.navbusy = true
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        },
        'withCredentials': true
      }
      var postData
      //if(this.razdel==='news')
      //  postData = {"action":"initnews"}
      //else
      if(rn)  
        postData = {"action":"init","rn": rn}   
      else  
        postData = {"action":"init"}   

      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" ){
            if(response.data.shifts)  {
              this.shifts   = response.data.shifts
              Object.values(this.shifts).forEach((s)=>{
                Object.values(s.specs).forEach((sp)=>{
                  this.shiftspec[sp.rn] = sp
                })
              })
            }  
            if(response.data.ondate)  this.ondate  = response.data.ondate
            if(response.data.ondays)  this.ondates  = response.data.ondays
            if(response.data.onweekdays)  this.onweekdays  = response.data.onweekdays
            if(response.data.scheme)  this.currentScheme = response.data.scheme
            if(response.data.hallobj) {
              this.hallobj  = response.data.hallobj
              if(this.hallobj && this.hallobj.schemeRN) {
                this.rootscheme=this.hallobj.schemeRN
                if(!response.data.scheme)  this.currentScheme = this.rootscheme
              }  
            }
            if(response.data.salepoints) {
              this.salepoints = response.data.salepoints.salepoints
              this.places = response.data.salepoints.places
            } 
            if(response.data.place) this.currentPlace = response.data.place
            if(response.data.salepoint) this.currentSalepoint = response.data.salepoint
            if(response.data.hall_rp) this.hall_rp = response.data.hall_rp
            if(response.data.point_rn) this.point_rn = response.data.point_rn

            if(response.data.settings)  this.settings  = response.data.settings
            if(response.data.cowdata) this.cowdata = response.data.cowdata
            if(response.data.schemes) this.schemes  = response.data.schemes
            if(response.data.rooms)   this.rooms    = response.data.rooms
            if(response.data.roomsdata)   this.roomsdata    = response.data.roomsdata
            if(response.data.seats)   this.seats    = response.data.seats
            if(response.data.statesdata)   this.statesdata    = response.data.statesdata
            if(response.data.states)   this.states  = response.data.states
            if(response.data.seatinfo)   this.seatInfo    = response.data.seatinfo
            if(response.data.roominfo)   this.roomInfo    = response.data.roominfo
            if(response.data.svg)   this.svg    = response.data.svg
            if(response.data.unpayedtickets) this.unpayedtickets = response.data.unpayedtickets
            if(response.data.printlist) this.printlist = response.data.printlist
          
            this.auth  = response.data.auth
            if(this.settings && (this.settings.disable_anonym || this.settings.disable_liqpay )&& this.isLogined()) this.useAbon = true

            if(response.data.tickets && response.data.tickets.unpayed){
               this.unpayed  = response.data.tickets.unpayed
               //this.unpayedtickets = this.unpayed //!!!!!!!!!! проверить
            }   
            if(response.data.tickets && response.data.tickets.payed) this.payed    = response.data.tickets.payed
            if(response.data.tickets && response.data.tickets.archive) this.archive  = response.data.tickets.archive
            if(response.data.users)  this.users  = response.data.users
            if(response.data.prices && response.data.prices.pricedata)  this.prices  = response.data.prices.pricedata

            //-------------

            this.nulSeats = []
            this.roomNulseats = {}
            this.seats.forEach((sts) => {
              if(sts.rownum==0) {
                this.nulSeats.push(sts.hallSeatRN)
                this.roomNulseats[sts.hallSeatRP] = sts.hallSeatRN
              }
            })

            //-------------

            if(response.data.colors){
              var sheet = document.createElement('style')
              var colorIndex=0

              response.data.colors.forEach((clr)=>{
                //sheet.innerHTML += "svg#room .clr-" + colorIndex + ".st_free{fill: rgb(7, 7, 250);}"
                sheet.innerHTML += "svg#room .clr-" + colorIndex + ".st_free{fill: #"+clr+";}"
                console.log("Color-"+colorIndex+"="+clr)  
                colorIndex++
              })
              document.body.appendChild(sheet);

            }


            //-------------
            

            if(response.data.ondate_index!=null) this.ondateTabIndex = response.data.ondate_index
              if(this.ondateTabIndex===0 && this.$refs.scrollLeft && this.navElement){
                this.navElement.scrollLeft =0
                this.$refs.scrollLeft.style.left = "0px"
                this.$refs.scrollRight.style.right = "0px"
              }  
            if(response.data.ondate_index!=null) {
              this.ondateTabIndexBuffer = response.data.ondate_index
              this.ondateTabIndex = response.data.ondate_index
            }  

            if(response.data.currentroom) this.currentRoom = response.data.currentroom

            //this.$nextTick().then(this.$nextTick().then(this.testToChangeShift()))
            
            this.$nextTick().then(this.changeOndate(this.ondate))
            setTimeout(() => this.roomsRecolor(), 1000)

            this.globalbusy = false
            this.navbusy = false
            this.$toasted.success('Loaded successfull').goAway(2000)
            
          }else{
            this.globalbusy = false
            this.navbusy = false
            if(response.data.error=="auth" || response.data.error=="tokenout"){
              if(response.data.noreg) this.settings.disable_registration=true
              this.needlogin = true
            }
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
        //-----------------------------
/*
        if(this.$refs["ondates-tabs"])
          this.navElement = this.$refs["ondates-tabs"].$el.querySelector("ul")
        if(this.$refs["shifts-tabs"])
          this.navElement2 = this.$refs["shifts-tabs"].$el.querySelector("ul")  
*/          

    },
    //********************************************* */
    onChangeName(){
      this.$toasted.success('Заглушка, надо перечитать имя/фамилию').goAway(2000)
    },


    //************************************************

    errorMaster(er){
      if(er=="init"){
        if(this.auth){
          this.needlogin = true
          console.log('Re LOGIN')
        }else{
          this.init()
          console.log('Re INIT')
        }
      }if(er=="auth"){
        this.needlogin = true
      }else{
        console.log('Re INKNOWN',er)
      }
    },

    errorMessage(er){
      var msg
      switch (er){
        case 23:
          msg = 'Нажаль по цьому абонементу вже використано візити:('
          break
        case 8:
          msg = 'Не введено електрону адресу!:('
          break
        default:
          msg = 'Помилка код '+er
      }
      return msg  
    },

    //************************************************ 
    clickLeft() {
      //if(!this.navElement){
        if(this.$refs["ondates-tabs"])
          this.navElement = this.$refs["ondates-tabs"].$el.querySelector("ul")
        else return  
      //}    
      this.navElement.scrollLeft -= 50;
      this.$refs.scrollLeft.style.left = this.navElement.scrollLeft + "px";
      this.$refs.scrollRight.style.right = -this.navElement.scrollLeft + "px";
      this.$refs.scrollLeft.disabled = (this.$refs.scrollLeft.style.left === '0px') 
    },
    clickRight() {
      //if(!this.navElement){
        if(this.$refs["ondates-tabs"])
          this.navElement = this.$refs["ondates-tabs"].$el.querySelector("ul")
        else return  
      //}    

      this.navElement.scrollLeft += 50;
      this.$refs.scrollRight.style.right = -this.navElement.scrollLeft + "px";
      this.$refs.scrollLeft.style.left = this.navElement.scrollLeft + "px";
      this.$refs.scrollLeft.disabled = (this.$refs.scrollLeft.style.left === '0px')
    },
    wheel: function(ev){
      if(ev.deltaY > 0){
        this.clickRight()
      }else{
        this.clickLeft()
      }
    },
    //************************************************ 
    clickLeft2($reset = false) {

      //if(!this.navElement2){
        if(this.$refs["shifts-tabs"])
          this.navElement2 = this.$refs["shifts-tabs"].$el.querySelector("ul")
        else return  
      //}    
      if(!this.navElement2) return
      if($reset) {
        this.navElement2.scrollLeft = 0;
      }else{
        this.navElement2.scrollLeft -= 50;
      }
      if(!this.$refs.scrollLeft2) return
      this.$refs.scrollLeft2.style.left = this.navElement2.scrollLeft + "px";
      this.$refs.scrollRight2.style.right = -this.navElement2.scrollLeft + "px";
      this.$refs.scrollLeft2.disabled = (this.$refs.scrollLeft2.style.left === '0px') 
    },
    clickRight2() {

      //if(!this.navElement2){
        if(this.$refs["shifts-tabs"])
          this.navElement2 = this.$refs["shifts-tabs"].$el.querySelector("ul")
        else return    
      //}    

      this.navElement2.scrollLeft += 50;
      this.$refs.scrollRight2.style.right = -this.navElement2.scrollLeft + "px";
      this.$refs.scrollLeft2.style.left = this.navElement2.scrollLeft + "px";
      this.$refs.scrollLeft2.disabled = (this.$refs.scrollLeft2.style.left === '0px')
    },
    wheel2: function(ev){
      if(ev.deltaY > 0){
        this.clickRight2()
      }else{
        this.clickLeft2()
      }
    },
    //************************************************ 
    clickRight22() {
      var el = document.getElementById('multishift_select_scrolled')
      if(!el || el.clientWidth < el.parentElement.clientWidth || el.clientWidth+this.scrollMultiShift < el.parentElement.clientWidth) return
      if(el.clientWidth+this.scrollMultiShift-50 < el.parentElement.clientWidth)
        this.scrollMultiShift = el.parentElement.clientWidth - el.clientWidth
      else  
        this.scrollMultiShift-=50
      el.style.left = this.scrollMultiShift+'px'
    },
    clickLeft22(rst) {
      var el = document.getElementById('multishift_select_scrolled')
      if(!el || this.scrollMultiShift == 0) return
      if(rst===true || this.scrollMultiShift+50 > 0) {
        this.scrollMultiShift = 0;
      }else{
        this.scrollMultiShift+=50
      }
      el.style.left = this.scrollMultiShift+'px'
    },
    wheel22: function(ev){
      if(ev.deltaY > 0){
        this.clickRight22()
      }else{
        this.clickLeft22()
      }
    },
    //******************************************************
    zoomSVG: function(e){
      if(!this.zoomElement){
        this.zoomElement = document.getElementById('svgimage')
      }
      if(!this.zoomElement) return

      if(e.deltaY > 0){
        if(this.zoomScale >= 1.05)this.zoomScale-=0.05
      }else{
        this.zoomScale+=0.05
      }
      this.setSVGzoom(this.zoomScale)
    },
    zoomIN(){
      this.zoomScale+=0.1
      this.setSVGzoom(this.zoomScale)
    },
    zoomOUT(){
      if(this.zoomScale >= 1.1) this.zoomScale-=0.1
      this.setSVGzoom(this.zoomScale)
    },
    setSVGzoom(s){
      if(!this.zoomElement){
        this.zoomElement = document.getElementById('svgimage')
      }
      if(!this.zoomElement) return
      this.zoomElement.style.transform="scale("+s+")"
      //console.log('zoom=('+s+','+this.zoomElement.style.width+')')
    },

    setSVGposition(x,y){
      if(!this.zoomElement){
        this.zoomElement = document.getElementById('svgimage')
        if(!this.zoomElement) return
      }
     
      if(y!=null) this.zoomElement.style.top=y+"px"
      if(x!=null) this.zoomElement.style.left=x+"px"
      //console.log('position=('+x+','+y+')')
      //console.log(document.getElementById('svgimage').clientWidth*(this.zoomScale-1)/2 - x)
    },
    /*
    setSVGpositionY(y){
      if(!this.zoomElement){
        this.zoomElement = document.getElementById('svgimage')
      }
      if(!this.zoomElement) return
      this.zoomElement.style.top=y+"px"
    },

    setSVGpositionX(x){
      if(!this.zoomElement){
        this.zoomElement = document.getElementById('svgimage')
      }
      if(!this.zoomElement) return
      this.zoomElement.style.left=x+"px"
    },
    */
    resetSVG(){
      this.zoomElement = null
      this.zoomScale=1
      this.zoomPosX=0
      this.zoomPosY=0
      this.setSVGzoom(1)
      this.setSVGposition(0,0)
    },
    startDrag(e){
      this.onDrag = true
      this.startDragX = e.x
      this.startDragY = e.y
      e.target.style="cursor:move"
      // это для контроля выхода за границы
      this.SVGclientWidth = document.getElementById('svgimage').clientWidth
      this.SVGclientHeight = document.getElementById('svgimage').clientHeight
    },
    stopDrag(e){
      if(this.onDrag){
        this.onDrag = false
        e.target.style="cursor: default"
        this.zoomPosX+=e.x-this.startDragX 
        this.zoomPosY+=e.y-this.startDragY
      }  
    },
    //*********************** */
    startTouch(e){

      //console.log('startTouch1',this.zoomScale,this.zoomScaleTmpGlobal)

      var et = e.touches
      if(!et)return
      if(et.length>1){
        this.startX1 = et[0].clientX
        this.startY1 = et[0].clientY
        this.startX2 = et[1].clientX
        this.startY2 = et[1].clientY
        this.startDragX = (this.startX1+this.startX2)/2
        this.startDragY = (this.startY1+this.startY2)/2
        //this.startDist = Math.sqrt((this.startX1-this.startX2)^2+(this.startY1-this.startY2)^2)
        this.startDist = Math.abs(this.startX1-this.startX2)+Math.abs(this.startY1-this.startY2)
        this.onDrag = true
        // это для контроля выхода за границы
        this.SVGclientWidth = document.getElementById('svgimage').clientWidth
        this.SVGclientHeight = document.getElementById('svgimage').clientHeight
      }  

      //console.log('startTouch1',this.zoomScale,this.zoomScaleTmpGlobal)
    },
    moveTouch(e){
      var et = e.touches
      var dX=0
      var dY=0
      //var dDist=0
      var divDist=1
      var zoomScaleTmp
      if(!et)return
      if(this.onDrag){
        if(et.length>1){
          e.preventDefault()
          this.startX1 = et[0].clientX
          this.startY1 = et[0].clientY
          this.startX2 = et[1].clientX
          this.startY2 = et[1].clientY
          if(this.startDist){
            divDist = (Math.abs(this.startX1-this.startX2) + Math.abs(this.startY1-this.startY2))/this.startDist
            dX = (this.startX1+this.startX2)/2 - this.startDragX
            dY = (this.startY1+this.startY2)/2 - this.startDragY
          }  
          this.startDragX = (this.startX1+this.startX2)/2
          this.startDragY = (this.startY1+this.startY2)/2

          zoomScaleTmp = (divDist>1)?(divDist-1)/3+1:1-(1-divDist)/3

          if(this.zoomScale*zoomScaleTmp>5) {
            this.setSVGzoom(5)
            zoomScaleTmp = 5 / this.zoomScale
          }else if(this.zoomScale*zoomScaleTmp<1){ 
            this.setSVGzoom(1)
            zoomScaleTmp = 1 / this.zoomScale
          }else{
            this.setSVGzoom(this.zoomScale*zoomScaleTmp)
          }
          this.zoomScaleTmpGlobal = zoomScaleTmp

          var granzeX = this.SVGclientWidth*(this.zoomScale*zoomScaleTmp-1)/2
          var nextX = this.zoomPosX + dX

          if(nextX > granzeX) 
            this.zoomPosX = granzeX
          else if(nextX < -granzeX) 
            this.zoomPosX = -granzeX
          else   
            this.zoomPosX += dX

          var granzeY = this.SVGclientHeight*(this.zoomScale*zoomScaleTmp-1)/2
          var nextY = this.zoomPosY + dY

          if(nextY > granzeY) 
            this.zoomPosY = granzeY
          else if(nextY < -granzeY) 
            this.zoomPosY = -granzeY
          else   
            this.zoomPosY += dY
          
          this.setSVGposition(this.zoomPosX, this.zoomPosY)
          e.stopPropagation()
        }
      }        
    },
    endTouch(){
      if(this.onDrag){
        //console.log('touchEnd')
        //this.zoomScale = this.zoomScale*this.zoomScaleTmpGlobal
        this.zoomScale = this.zoomScale*this.zoomScaleTmpGlobal
        this.startX1 = null
        this.startY1 = null
        this.startX2 = null
        this.startY2 = null
        this.onDrag = false
      }  
    },
    //*********************** */
    svgMove(e){
      var delta = 30
      var nextX,nextY
      var SVGclientWidth = document.getElementById('svgimage').clientWidth
      var SVGclientHeight = document.getElementById('svgimage').clientHeight
      var granzeX = SVGclientWidth*(this.zoomScale-1)/2
      var granzeY = SVGclientHeight*(this.zoomScale-1)/2
      switch (e){
        case 'up':
          console.log('up')
          nextY = this.zoomPosY+delta
          if(nextY > granzeY) 
            this.zoomPosY = granzeY
          else
            this.zoomPosY+=delta
          break
        case 'down':
          console.log('down')
          nextY = this.zoomPosY-delta
          if(nextY < -granzeY) 
            this.zoomPosY = -granzeY
          else
            this.zoomPosY+=delta
          this.zoomPosY-=delta
          break
        case 'left':
          console.log('left')
          nextX = this.zoomPosX+delta
          if(nextX > granzeX) 
            this.zoomPosX = granzeX
          else  
            this.zoomPosX+=delta
          break
        case 'right':
          console.log('right')
          nextX = this.zoomPosX-delta
          if(nextX < -granzeX) 
            this.zoomPosX = -granzeX
          else  
            this.zoomPosX-=delta
          break
        default:
          console.log(e)
      }


      this.setSVGposition(this.zoomPosX,this.zoomPosY)


    },
   /* 
    emulHover(e){
      console.log(e.target.id)
      document.getElementById(e.target.id).classList.add("hovered")
    },

    removeHover(e){
      document.getElementById(e.target.id).classList.remove("hovered")
    },
    */
    //-------------------------------------------------------
    setRoom(rn){
      //temporery!!!!!!!!!!!!
      if(!this.currentShift) return false
      if(this.currentRoom == rn) return
      this.currentRoom = rn
      /*
      this.currentDateTabMark = null
      this.checkedSeats = []
      this.checkRoomShift()
      */
      if(!rn)this.datestates=null
    },
    /*
    setRoomSelect(){
      if(!this.currentShift) return false

      this.currentDateTabMark = null
      this.checkedSeats = []
      this.checkRoomShift()

    },
    */
    setShift(rn){
      //if(this.currentShift == rn) return
      this.currentShift = rn
    },
    clickShift(rn){
      var s = this.currentShiftsList.map(item =>  {return item.rn}) 
      var q = this.currentShifts
      var n1 = s.indexOf(q[0])
      var n2 = s.indexOf(q[q.length-1])
      var n = s.indexOf(rn)

      //this.settings.multi_shift

      if(n==-1 || n1==-1 || n2==-1) return

      if(n < n1-1 || n > n2+1) 
        q = [rn]
      else if (n > n1 && n <= n2)
        q = q.slice(0, n-n1)
      else if(n2+1 === n)
        q.push(rn)
      else if(n1-1 === n)
        q = [rn].concat(q)
      else if(n === n1 && n1<n2)
        q = q.slice(1)    
      this.currentShifts = q
      //this.setShift(q[0])
    },
    detectCurrentShift(){

      if(!this.schemes || !this.schemes[this.currentScheme]) return
      if(!this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]) return

      var sfts = (this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]).specs
      var shiftTmp = null

      if((new Date()).getDate()==(new Date(this.ondate)).getDate() && (new Date()).getMonth()==(new Date(this.ondate)).getMonth()){
        console.log('Segodnya !!!',sfts)
        var t = new Date()
        var now = t.getHours() + t.getMinutes()/100
        this.disabledShifts = []
        
        Object.values(sfts).forEach((s)=>{
          if(s.timeTo < now){ 
            this.disabledShifts.push(s.rn)
          }else if(!shiftTmp){
            shiftTmp = s.rn  
          }
        })
        this.currentShift = shiftTmp
      }else{
        this.disabledShifts = []
        this.currentShift = Object.keys(sfts)[0]
      }
      this.currentShifts = [this.currentShift]
    },

    createShiftList(){
      var n=this.ondate
      if(this.schemes &&
        this.schemes[this.currentScheme] && 
        (this.schemes[this.currentScheme]).specs && 
        (this.schemes[this.currentScheme]).specs[this.onweekdays[n]] &&
        ((this.schemes[this.currentScheme]).specs[this.onweekdays[n]]).shift &&
        this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[n]]).shift]){
        var s = (this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[n]]).shift]).specs
        var sv = Object.values(s)
        sv.sort(function(a, b){
          return a.timeFr-b.timeFr
        })
        this.currentShiftsList = sv
        this.currentShiftsAssoc = s
      }  
      //-------------------
      this.detectCurrentShift()
      if(this.currentRoom && this.currentShift) this.seatsStatesRecalc()
      if(this.currentShift) this.roomsRecolor()
      this.clickLeft2(true)
      this.clickLeft22(true)
    },

    changeOndate(dt){

      //if(this.ondate && dt && this.ondate == dt) return true

      this.checkedSeats = []
      this.currentDateTabMark = null
      this.ondate = dt

      this.testToChangeShift()

      if(this.currentShift){
        var sft = this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]
        
        //if(!(this.shifts[((this.schemes[(this.hallobj[0]).schemeRN]).specs[this.onweekdays[this.ondate]]).shift]).specs[this.currentShift]){
        if(!sft || !sft.specs[this.currentShift]){  
          this.currentShift = null
        }
      }

      if(this.currentDateTabMark) 
        this.checkSeat(this.currentDateTabMark)
      else  
        this.checkRoomShift()
      console.log('Set '+ dt)
    },
    changeAbon(){
      if(this.currentAbon && this.checkedSeats && this.checkedSeats.length>1) this.checkedSeats=[]
      this.useAbon = (this.currentAbon!=null)
    },
    /*
    changePlace(rn){
      var place_rn = (rn)?rn:this.currentPlace
      //this.globalbusy = true
      this.currentSalepointsList = this.salepoints.filter(s => s.place_rn==place_rn)
      if(this.currentSalepointsList && this.currentSalepointsList.length>0)
        this.init(this.currentSalepointsList[0]['hall_rp'])
    },
    */
    changeSalepoint(point_rn){
      //this.globalbusy = true
      /*
      var currentSalepointsList = this.salepoints.filter(s => s.hall_rn == this.currentSalepoint)
      console.log('currentSalepointsList',this.currentSalepoint,currentSalepointsList)
      if(currentSalepointsList && currentSalepointsList.length==1){

        this.init(hall_rp)
      }
      */

      var hall_rp = this.salepoints.filter(s => s.point_rn == point_rn )[0]['hall_rp']
      console.log('Change salepoint to '+point_rn+' (hall_rp='+hall_rp+')')


      if(hall_rp && this.currentSalepoint['point_rn']!=point_rn) {
        // можливо тут треба очистити деякі компоненти
        this.currentRoom = null

        this.$bvModal.hide('salepoints-modal')
        //this.init(hall_rp)  
        this.init(point_rn)  
      }  
    },

    setAuth(a){
      this.auth = a
    },
    setSalepoints(a){
      this.point_rn = a.point_rn
      this.salepoints = a.salepoints
      this.places = a.places
      this.currentSalepoint = a.salepoint
    },

    //---------------------------------------------------------------------------------
    seatsStatesRecalc(){

      var rm = this.currentRoom
      var d = this.ondate
      var sf = this.currentShift
      var el,state
      var roomIsReserved = false
      var colorClass=null
      var multi = (this.settings && this.settings.multi_shift)
      
      if(!this.rooms[rm])  return true

      if(this.reservedDateRoomShift && this.reservedDateRoomShift[d] && this.reservedDateRoomShift[d][rm]){
        if(!multi){
          if(this.reservedDateRoomShift[d][rm][sf]==2) roomIsReserved = true
        }else{  
          this.currentShifts.forEach((s)=>{
            if(this.reservedDateRoomShift[d][rm][s]==2)
              roomIsReserved = true
          })
        }  
      } 

      this.rooms[rm].seats.forEach((sts)=>{
        el = document.getElementById('st-'+sts.hallSeatRN)
        if(this.seatInfo[sts.hallSeatRN] && (this.seatInfo[sts.hallSeatRN])['colorIndex']!=null){
          colorClass = 'clr-'+(this.seatInfo[sts.hallSeatRN])['colorIndex']
        }else{
          colorClass = null
        }
        /*
        if(el){
            el.addEventListener('mouseover', function() {
              console.log(sts.hallSeatRN)
            })
        }    
        */  
        if(roomIsReserved) {
          if(el) {
            if(colorClass!=null) el.classList.remove(colorClass)
            el.classList.remove("st_free")
            el.classList.add("st_busy")
          }  
        }else{
          if(this.statesdata[sts.hallSeatRN] && (this.statesdata[sts.hallSeatRN])[d] && sts.rownum!=0){

            if(el){ 
              if(colorClass!=null) el.classList.add(colorClass)
              el.classList.remove("st_busy")
              el.classList.remove("st_busy_me")
              el.classList.remove("st_busy_notme")
              el.classList.add("st_free")
            }
            
            if(!multi){
              //----------------------------------------  
              state = ((this.statesdata[sts.hallSeatRN])[d])[sf]
              if(state>0){
                if(el) {
                  if(colorClass!=null) el.classList.remove(colorClass)
                  el.classList.remove("st_free")
                  el.classList.add("st_busy")

                  if(this.auth && this.auth.visitor && this.auth.visitorRN) 
                    if(this.users && this.users && this.users[d] && this.users[d][sts.hallSeatRN] && this.users[d][sts.hallSeatRN][sf])
                      if((this.users[d][sts.hallSeatRN][sf]).visitorRN === this.auth.visitorRN)
                        el.classList.add("st_busy_me")
                      else
                        el.classList.add("st_busy_notme")
                }  
              }
              //-------------------------
            }else{
              //ВЫБРАНО НЕСКОЛЬКО ШИФТОВ!
              this.currentShifts.forEach((s)=>{
                if((this.statesdata[sts.hallSeatRN])[d][s]>0){
                  if(el) {
                    if(colorClass!=null) el.classList.remove(colorClass)
                    el.classList.remove("st_free")
                    el.classList.add("st_busy")

                    //console.log('USERS ',this.users)
                    //console.log('USERS2 ',this.users[d])
                    //console.log('USERS3 ',this.users[d][sts.hallSeatRN])
                    //console.log('USERS4 ',this.users.d[sts.hallSeatRN][s])
                    

                    if(this.auth && this.auth.visitor && this.auth.visitor.visitorRN) 
                      if(this.users && this.users && this.users[d] && this.users[d][sts.hallSeatRN] && this.users[d][sts.hallSeatRN][s]){
                        console.log('USERS RECOLOR',this.users[d][sts.hallSeatRN][s])
                        if(this.users[d][sts.hallSeatRN][s]['visitorRN'] === this.auth.visitor.visitorRN)
                          el.classList.add("st_busy_me")
                        else
                          el.classList.add("st_busy_notme")  
                      }    


                  }  
                }
              })  
            }
          }
        }  
      })
    },

    tltp(s){
      console.log(s)
    },

    roomsRecolor(){
      var multi = (this.settings && this.settings.multi_shift)
      console.log('Recolor! CurrentRoom='+this.currentRoom )
      if(!this.rooms || this.currentRoom) return true
      //if(!this.rooms) return true

      var el,elbl,elbt
      var rdrs = this.reservedDateRoomShift
      var fr,bs,prt
      Object.keys(this.rooms).forEach((rm) => {
        el = document.getElementById('rm-'+rm)
        if(el) {
          console.log('rm='+rm)
          el.classList.remove("rm_free")
          el.classList.remove("rm_partial")
          el.classList.remove("rm_busy")
          el.classList.remove("rm_undefined")

          elbt = document.getElementById('bt-'+rm)
          if(elbt){
            elbt.classList.remove("rm_free2book")
            /*
            el_ico = document.getElementById('ico_bt_'+rm)
            if(el_ico){
              el_ico.style.top = elbt.getBoundingClientRect().top + document.body.scrollTop
              el_ico.style.left = elbt.getBoundingClientRect().left + document.body.scrollLeft
              el_ico.style.display='none'
            } 
            */
          }  

          if(!rdrs[this.ondate] || !rdrs[this.ondate][rm]){
              el.classList.add("rm_undefined")  
          }else{
            if(multi){
              fr = 0
              bs = 0
              prt = 0

              this.currentShifts.forEach((s)=>{
                switch (rdrs[this.ondate][rm][s]){
                  case 0:
                    fr++
                    break
                  case 1:
                    prt++
                    break
                  case 2:
                    bs++
                }
                if(bs>0)
                  el.classList.add("rm_busy")
                else if(prt>0)  
                  el.classList.add("rm_partial")  
                else if(fr>0)  
                  el.classList.add("rm_free")
                else
                  el.classList.add("rm_undefined")        
              })
            }else{
              if(rdrs[this.ondate][rm][this.currentShift]===0){
                el.classList.add("rm_free")
                if(elbt) elbt.classList.add("rm_free2book")
              }else if(rdrs[this.ondate][rm][this.currentShift]===2)
                el.classList.add("rm_busy")
              else if(rdrs[this.ondate][rm][this.currentShift]===1)
                el.classList.add("rm_partial")  
              else  
                el.classList.add("rm_undefined")  
            } 
          }

          /*
          if(this.reservedDateRoomShift[this.ondate] && this.reservedDateRoomShift[this.ondate][rm] && this.reservedDateRoomShift[this.ondate][rm][this.currentShift]===0){
            el.classList.add("rm_free")
            if(elbt) elbt.classList.add("rm_free2book")
          }else if(this.reservedDateRoomShift[this.ondate] && this.reservedDateRoomShift[this.ondate][rm] && this.reservedDateRoomShift[this.ondate][rm][this.currentShift]===2)
            el.classList.add("rm_busy")
          else if(this.reservedDateRoomShift[this.ondate] && this.reservedDateRoomShift[this.ondate][rm] && this.reservedDateRoomShift[this.ondate][rm][this.currentShift]===1)
            el.classList.add("rm_partial")  
          else  
            el.classList.add("rm_undefined")  
          */  
        } 
        
        elbl = document.getElementById('label-'+rm)
        /*
        console.log('Room')
        console.log(this.roomInfo[rm])
        console.log(this.roomInfo[rm].objname)
        console.log(this.roomInfo[rm]['objname'])
        */
        if(elbl){
         if(this.rooms[rm] && this.rooms[rm].objName) elbl.innerHTML = this.rooms[rm].objName
         elbl.classList.add("roomlabel")
        } 

      })

    },


    seatsCheckedRecalc(){
      var rm = this.currentRoom
      var el
      var sumtmp = 0
      if(!this.rooms || !rm || !this.rooms[rm])  return
      (this.rooms[rm]).seats.forEach((sts) => {
        el = document.getElementById('st-'+sts.hallSeatRN)

        if(this.checkedSeats.includes(sts.hallSeatRN)){
          if(el) el.classList.add("st_checked")
          if(this.seatInfo[sts.hallSeatRN]) {
            if(this.settings && this.settings.multi_shift && this.currentShifts.length>0)
              sumtmp+=(this.seatInfo[sts.hallSeatRN]).price*this.currentShifts.length
            else  
              sumtmp+=(this.seatInfo[sts.hallSeatRN]).price

            //sumtmp+=(this.seatInfo[sts.hallSeatRN]).price
          }  
        }else{
          if(el)el.classList.remove("st_checked")
        }
      })
      this.sum = sumtmp
    },  
    //----------------------------------------------------------------------------------
    getStateClass(sts){
      var res
      if(this.statesdata && this.statesdata[sts] && (this.statesdata[sts])[this.ondate]){
        res=0
        this.currentShifts.forEach((sfl)=>{
          if(((this.statesdata[sts])[this.ondate])[sfl] > res){
            res = ((this.statesdata[sts])[this.ondate])[sfl]
          }
        })
      }else{
        //res = null
      }
      
      //"getStateColor(((statesdata[sts.hallSeatRN])[ondate])[currentShift])"   

      switch(res) {
        case 0:
          return 'bg-success border-success text-light m-1'  
        case 1:
          return 'bg-warning border-warning text-dark m-1'  
        case 2:
          return 'bg-danger border-danger text-light m-1'  
        default:
          return 'bg-light border-dark text-dark m-1'  
      }
    },
    /*
    getStateTabColor(sft){

      if(!sft || !this.currentRoom) return 'white'

      if( this.roomsdata[this.currentRoom] && 
          this.roomsdata[this.currentRoom][this.ondate] && 
          this.roomsdata[this.currentRoom][this.ondate][sft]>0
        )  return 'danger'

      var freenum = 0
      var busynum = 0
      this.rooms[this.currentRoom].seats.forEach((d) => {
        if(d.rownum != 0){ 
          if(this.statesdata[d.hallSeatRN][this.ondate][sft] == 0){
            freenum++
          }else{
            busynum++
          }
        }
      })

      if(busynum>0 && freenum>0)
        return 'warning'
        //return 1
      else if(busynum==0 && freenum>0)
        return 'success'
        //return 0
      else
        return 'danger'
        //return 2
    },
    */

    getStateTab(sft){
      if(!this.currentRoom) return
      return ((this.reservedDateRoomShift[this.ondate]) && (this.reservedDateRoomShift[this.ondate])[this.currentRoom])?((this.reservedDateRoomShift[this.ondate])[this.currentRoom])[sft]:null
    },

    isStateFree(sts){
      var free = true
      if(this.settings && this.settings.multi_shift && this.currentShifts.length>0){
        if(this.roomsdata[this.currentRoom] && this.roomsdata[this.currentRoom][this.ondate])
          //console.log('Seat #',sts)
          this.currentShifts.forEach((sft)=>{
            //console.log('SFT #',sft,this.roomsdata[this.currentRoom][this.ondate][sft])
            if(this.roomsdata[this.currentRoom][this.ondate][sft]>0) free = false
          })
        this.currentShifts.forEach((sft)=>{
          //console.log('2SFT #',sft,((this.statesdata[sts])[this.ondate])[sft])
          if(((this.statesdata[sts])[this.ondate])[sft]>0) free = false
        })  
      }else{
        free = (((this.statesdata[sts])[this.ondate])[this.currentShift]===0 && (!this.roomsdata[this.currentRoom] || this.roomsdata[this.currentRoom][this.ondate][this.currentShift]!=2))
      }
      return free
    },
    isStateFreeExt(sts,date = this.ondate){
      var free = true
      if(this.settings && this.settings.multi_shift && this.currentShifts.length>0){
        if(this.roomsdata[this.currentRoom] && this.roomsdata[this.currentRoom][date])
          console.log('Seat #',sts)
          this.currentShifts.forEach((sft)=>{
            console.log('SFT #',sft,this.roomsdata[this.currentRoom][date][sft])
            if(this.roomsdata[this.currentRoom][date][sft]>0) free = false
          })
        this.currentShifts.forEach((sft)=>{
          console.log('2SFT #',sft,((this.statesdata[sts])[date])[sft])
          if(((this.statesdata[sts])[date])[sft]>0) free = false
        })  
      }else{
        free = (((this.statesdata[sts])[date])[this.currentShift]===0 && (!this.roomsdata[this.currentRoom] || this.roomsdata[this.currentRoom][date][this.currentShift]!=2))
      }
      return free
    },

    getRoomStateTabColor(rm){
      var freenum = 0
      var busynum = 0
      this.rooms[rm].seats.forEach((st) => {
        if(!this.statesdata[st.hallSeatRN] || !this.statesdata[st.hallSeatRN][this.ondate]) return "info"
        if(st && this.statesdata[st.hallSeatRN]){
          if(st.rownum==0 && this.roomsdata[rm]){
            Object.keys(this.roomsdata[rm][this.ondate]).forEach((sm) => {
              if(this.roomsdata[rm][this.ondate][sm] == 2){
                busynum++
              }else{
                //-----------------------
                Object.keys(this.statesdata[st.hallSeatRN][this.ondate]).forEach((sm) => {
                  if(this.statesdata[st.hallSeatRN][this.ondate][sm] == 0){
                    freenum++
                  }else{
                    busynum++
                  }
                })
                //--------------------------
              }
            })
       
          }else{
            //-----------------------
            Object.keys(this.statesdata[st.hallSeatRN][this.ondate]).forEach((sm) => {
              if(this.statesdata[st.hallSeatRN][this.ondate][sm] == 0){
                freenum++
              }else{
                busynum++
              }
            })
            //--------------------------
            
          }   
        }
      })
      if(busynum>0 && freenum>0)
        return 'warning'
      else if(busynum==0 && freenum>0)
        return 'success'
      else if(busynum==0 && freenum==0)
        return 'info'  
      else
        return 'danger'
    },
/*
    getroom(rn){
      Object.keys(this.rooms).forEach((r) => {
        (this.rooms[r]).seats.forEach((s) => {
          if(s.hallSeatRN == rn) return r
        })
      })
      return
    },
*/


    checkSeat(rn){
      /*
      if(this.currentDateTabMark == rn){
        this.currentDateTabMark = nullcheckRoomShift
        this.checkRoomShift()
        return

      }
      */

      if(this.settings && this.settings.disable_anonym && !this.isLogined()){
        this.needlogin = true
        return
      }

      var sf = this.currentShift
      //var sfl = this.currentShifts
      var rm = this.currentRoom
      var d = this.ondate
      var sdrs = this.statesDateRoomShiftSeats
      var rds = this.reservedDateRoomShift

      if(!sf) return false

      if(
          (this.currentDateTabMark || this.checkedSeats.length>1) && rn != this.currentDateTabMark &&
          (
            (!this.nulSeats.includes(this.currentDateTabMark) && sdrs[d][rm][sf] && sdrs[d][rm][sf][rn]>0) || 
            (rds && rds[d] && rds[d][rm] && rds[d][rm][sf]==2)
          )
        ){
          //нельзя выбрать занятое место, если до этого было выбрано свободное место
        console.log('низя')
        return false
      } 

      if(
          (this.currentDateTabMark || this.checkedSeats.length==1) && sf && rn != this.currentDateTabMark &&
          (
            (sdrs && sdrs[d] && sdrs[d][rm] && sdrs[d][rm][sf] && sdrs[d][rm][sf][this.currentDateTabMark]>0) || 
            (rds && rds[d] && rds[d][rm] && rds[d][rm][sf]==2) ||
            this.nulSeats.includes(this.currentDateTabMark) ||
            (this.currentAbon && this.useAbon)
          )
        ){
        //если кликнуть на свободное место, то если до этого было выбрано занятое (или нулевое), 
        //то с предыдущего места
        //снимается выделение  
        console.log('низя, но есть нюанс')
        //this.checkedSeats=[rn]
        this.checkedSeats=[]
        this.currentDateTabMark = rn
        //return true
      } 
   
      if(this.checkedSeats){
        var pos = this.checkedSeats.indexOf(rn)
        if(pos > -1){
          this.checkedSeats.splice(pos,1)
        }else{
          this.checkedSeats.push(rn)
        }
      }else{
        this.checkedSeats = [rn]
      }

      if(this.checkedSeats.length != 1){ 
        this.currentDateTabMark = null
        this.checkRoomShift()
        return true
      }  
          
      rn = this.checkedSeats[0]

      this.currentDateTabMark = rn
      var datestatestmp = {}

      Object.keys(sdrs).forEach((d)=>{
        this.currentShifts.forEach((sfl)=>{
          if(sdrs[d][rm][sfl] && datestatestmp[d] != 2){
            if(sdrs[d][rm][sfl][rn]>0) datestatestmp[d] = 2
            else datestatestmp[d] = 0
          } 
          if(rds[d][rm][sfl] ===2) datestatestmp[d] = 2
        })

        /*
        if(sdrs[d][rm][sf]){
         if(sdrs[d][rm][sf][rn]>0)  datestatestmp[d] = 2
         else datestatestmp[d] = 0
        } 
        if(rds[d][rm][sf] ===2) datestatestmp[d] = 2
        */
      })
      this.datestates = datestatestmp
      return true
    },
    /*
    uncheckSeat(){
      this.currentDateTabMark = null
      this.checkRoomShift()
      return true
    },  
    */

    checkRoomShift(){

      var datestatestmp = {}
      var rm = this.currentRoom
      var sf = this.currentShift
      
      if(rm){
        //выбрана комната - значит надо подсветить свободные даты
        Object.keys(this.reservedDateRoomShift).forEach((d) => {

          this.currentShifts.forEach((sfl)=>{
            if(datestatestmp[d] != -1 && datestatestmp[d] != 2){
              if(this.reservedDateRoomShift[d][rm] && this.reservedDateRoomShift[d][rm][sfl]!=null){
                if((datestatestmp[d]!=null && this.reservedDateRoomShift[d][rm][sfl]>datestatestmp[d])||(!datestatestmp[d]))
                  datestatestmp[d] = this.reservedDateRoomShift[d][rm][sfl]  
              }else{
                datestatestmp[d] = -1
              }
            }    

          })

          /*
          if(this.reservedDateRoomShift[d][rm][sf]!=null){
            datestatestmp[d] = this.reservedDateRoomShift[d][rm][sf]  
          }else{
            datestatestmp[d] = -1
          }
          */  

        })
        this.datestates = datestatestmp
      }  


      //возможно блок ниже можно убрать......

      // комната не выбрана - подсвечиваем занятости на кнопках выбора комнат в выбранную смену
      if(!this.reservedDateRoomShift || !this.reservedDateRoomShift[this.ondate]) return
      Object.keys(this.reservedDateRoomShift[this.ondate]).forEach((r) => {
        if(!this.reservedDateRoomShift[this.ondate][r]) return
        this.roomstates[r] = this.reservedDateRoomShift[this.ondate][r][sf]
      })


    },

    clickMap(e){
      if(['path','polygon','rect'].includes(e.target.tagName) && e.target.id && e.target.id.substring(0,3)=='st-'){
        //console.log(e.target.id.substring(3))
        //console.log(e.target.dataset)
        //event.target.classList.toggle('st_checked')
        if(this.checkSeat(e.target.id.substring(3))) e.target.classList.toggle('st_checked')
      }  
    },
    overMap(e){
      if(!this.onDrag){
        // eslint-disable-next-line
        if(1){
          //console.log('OverMap')
          //return
        }
        var seatnum
        var ondt = this.ondate

        if(!this.showtooltip && ['path','polygon','rect'].includes(e.target.tagName) && e.target.id && e.target.id.substring(0,3)=='st-'){
          this.showtooltip = true
          e.target.style="cursor:pointer"

          seatnum = e.target.id.substring(3)
          var i = this.seatInfo[seatnum]

          console.log('Target:',i)

          if(i){
            this.txttooltip = ''

            //---- name of users

            if(this.auth && this.auth.visitor && this.auth.visitor.visitorRN){

              if((this.settings && this.settings.multi_shift)){
                //ВЫБРАНО НЕСКОЛЬКО ШИФТОВ!
                
                this.currentShifts.forEach((s)=>{
                  //console.log('USERS STATE',(this.statesdata[seatnum])[ondt][s])
                  if((this.statesdata[seatnum])[ondt][s]>0){
                    if(this.users && this.users[ondt] && this.users[ondt][seatnum] && this.users[ondt][seatnum][s])
                      if(this.txttooltip)this.txttooltip+='<br>'
                      if(this.users[ondt][seatnum][s]['visitorRN'])
                        if(this.users[ondt][seatnum][s]['visitorRN'] === this.auth.visitor.visitorRN)
                          this.txttooltip+='<b>'+this.users[ondt][seatnum][s]['visitorMnemo']+'</b>'
                        else
                          this.txttooltip+=this.users[ondt][seatnum][s]['visitorMnemo']
                          
                  }
                })  
                
              }else{
                var sf = this.currentShift 
                if(((this.statesdata[seatnum])[ondt])[sf]>0){
                  if(this.users && this.users && this.users[ondt] && this.users[ondt][seatnum] && this.users[ondt][seatnum][sf])
                    if(this.txttooltip)this.txttooltip+='<br>'
                    if((this.users[ondt][seatnum][sf]).visitorRN === this.auth.visitorRN)
                      this.txttooltip+='<b>'+(this.users[ondt][seatnum][sf]).visitorMnemo+'</b>'
                    else  
                      this.txttooltip+=(this.users[ondt][seatnum][sf]).visitorMnemo
                    
                }
              }            
            }
            //------------------ prices
            if((this.settings && this.settings.multi_shift)){
              var priceResult=0
              this.currentShifts.forEach((s)=>{
                if(this.prices[seatnum]) priceResult+=this.prices[seatnum][ondt][s]
              })   
            }else{
              if(this.prices[seatnum]) priceResult=this.prices[seatnum][ondt][this.currentShift ]
            }
            //----------------------   
            if(this.txttooltip)this.txttooltip+='<hr>'
            this.txttooltip+= this.getSeatLabel(i.namerow,i.rownum)+'<br>'+this.getSeatLabel(i.namecol,i.seat)
            //if(!this.useAbon) this.txttooltip+='<br>'+i.price.toString()+'&nbsp;&#8372;'
            if(!this.useAbon) this.txttooltip+='<br>'+priceResult.toString()+'&nbsp;&#8372;'

            this.posX = e.x+10
            this.posY = e.y+25+window.pageYOffset //e.offsetY+e.srcElement.top
          // console.log(e)
          }

        }  
      }else{
        var nx,ny  
        var granzeX = this.SVGclientWidth*(this.zoomScale-1)/2
        var nextX = this.zoomPosX+e.x-this.startDragX

        if(nextX > granzeX) 
          nx = granzeX
        else if(nextX < -granzeX) 
          nx = -granzeX
        else   
          nx = nextX

        var granzeY = this.SVGclientHeight*(this.zoomScale-1)/2
        var nextY = this.zoomPosY+e.y-this.startDragY

        if(nextY > granzeY) 
          ny = granzeY
        else if(nextY < -granzeY) 
          ny = -granzeY
        else   
          ny = nextY

        //nx = this.zoomPosX+e.x-this.startDragX
        //ny = this.zoomPosY+e.y-this.startDragY

        //var w = document.getElementById('svgimage').clientWidth
        //var h = document.getElementById('svgimage').clientHeight

        this.setSVGposition(nx, ny)
      }
    },
    outMap(e){
      if(!this.onDrag){
        if(['path','polygon','rect'].includes(e.target.tagName) && e.target.id && e.target.id.substring(0,3)=='st-'){
          this.showtooltip = false
          e.target.style="cursor:default"
          this.txttooltip = ''
          this.posX = 0
          this.posY = 0
        }
      }
    },
    clickMapRoot(e){
      var rm
      //console.log(e.target)
      if(['path','polygon','rect'].includes(e.target.tagName) && e.target.id && e.target.id.substring(0,3)=='rm-'){
        //console.log(e.target.id.substring(3))
        this.setRoom(e.target.id.substring(3))
      }
      if(e.target.id && e.target.id.substring(0,3)=='bt-'){
        rm = e.target.id.substring(3)
        if(this.roomNulseats[rm]){
          this.setRoom(rm)
          this.currentDateTabMark=this.roomNulseats[rm] // (this.roomInfo[rm]).rn
          this.checkedSeats=[this.currentDateTabMark]
          //this.$nextTick().then( this.$nextTick().then( this.$nextTick().then(this.switchSidebar('rightsidebar','on'))))
        }  
      }    
    },

    overMapRoot(e){
      if(['path','polygon','rect'].includes(e.target.tagName) && e.target.id && e.target.id.substring(0,3)=='rm-'){
        var rm = e.target.id.substring(3)
        e.target.style.cursor = "pointer"
        //e.target.classList.add('rm_hover')
        if(this.freeDateRoomShift[this.ondate] && this.freeDateRoomShift[this.ondate][rm]){
          var freestr = this.freeDateRoomShift[this.ondate][rm][this.currentShift]
          console.log(rm, freestr)
        }  
      }  
    },

    mouseLeaveRoot(e){
      if(['path','polygon','rect'].includes(e.target.tagName) && e.target.id && e.target.id.substring(0,3)=='rm-'){
        e.target.classList.remove('rm_hover')
      }  
    },

    testToChangeShift(){
      if( !this.shifts || !this.schemes || !this.hallobj || !this.onweekdays || !this.ondate || !this.onweekdays[this.ondate] ||
          !this.schemes[this.currentScheme] ||
          !this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]) return
      var sfs = (this.shifts[((this.schemes[this.currentScheme]).specs[this.onweekdays[this.ondate]]).shift]).specs
      for(var key in sfs){
        //console.log(key)
        break
      }
      if(!sfs[this.currentShift] && key){ 
        console.log('Bingo - '+key)
        this.setShift(key)
      }
    },

    //------------------------------------------------
    bookRoom(st){

      this.checkedSeats = [st]
      this.isBooking = true
      return true
    },

    //******************************* */

    recalcSumToPay(){
      var sum = 0;
      this.unpayedtickets.forEach((itm) => {
        //if(this.selectedTopay.includes(itm.rn)) 
        if(this.settings && this.settings.multi_shift && this.currentShifts.length>0)
          sum += itm.paySum*this.currentShifts.length
        else  
          sum += itm.paySum
      })
      this.amount = Math.round(sum * 100) / 100
    },
    /*
    selectAll(){
      this.selectedTopay = []
      this.allSelected = !this.allSelected
      if (this.allSelected) {
        this.unpayedtickets.forEach((ci)=>{
          if(new Date() < new Date(ci.payUpTo)){
            //if(document.querySelector('#checkbox'+ci.disabled === false))
            console.log('Ok-'+ ci.rn)
            this.selectedTopay.push(ci.rn)
          }else{
            console.log('Not Ok-'+ ci.rn+ ' ('+ci.payUpTo+')')
          } 
        })
      }
      this.recalcSumToPay()
    },
    */
   /*
    selectAllOff(){
      this.allSelected = false
      this.recalcSumToPay()
    },*/
    /*
    paySelectedTickets(){
      console.log('Pay')
      console.log(this.selectedTopay)

      this.book_busy = true
      var postData = {
        "action"  :"payselected",
        "list"    : this.selectedTopay
      }

      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            if(response.data.success != false){
              
              if(response.data.liqpay.data && response.data.liqpay.signature){
                //------------------------------------------------
                if(response.data.response && response.data.response[0]){


                  //this.unpayedticketsTmp = this.unpayedtickets

                  //response.data.response[0].checks.forEach((c) =>{
                  //  c.ondate  = this.ondate
                  //  c.shift   = this.currentShift
                  //  this.unpayedticketsTmp.push(c)
                  //})

                  //this.unpayedtickets = this.unpayedticketsTmp                

                }

                this.globalrns = response.data.rns

                this.goLiqPay(response.data.liqpay.data, response.data.liqpay.signature,"#liqpay_checkout2")
                
              }
              
            }else{
              this.book_busy = false
              if(response.data.code)this.$toasted.error('Помилка № '+response.data.code).goAway(6000)
              if(response.data.code === 23){
                this.$toasted.error('Нажаль по цьому абонементу вже використано візити:(').goAway(6000)
                this.book_busy = true
                this.updateStates(false)
                this.book_busy = false  
                
              }else{  
                this.$toasted.error('Нажаль забронювати це вже неможливо:(').goAway(6000)
                if(response.data.message)
                  this.$toasted.error(response.data.message).goAway(4000)
                else if(response.data.error)
                  this.$toasted.error(response.data.error).goAway(4000)
                else  
                  this.$toasted.error('Error').goAway(2000)  


                this.book_busy = true
                this.updateStates()
                this.book_busy = false 
                this.isBooking=false 
              }  
            }  
          })
          .catch(error => {
              console.error('There was an error!', error)
              this.book_busy = false
              this.$toasted.error('Error').goAway(2000)
          });  

    },*/
    //********************************* */
    payUnpayedTickets(){
      console.log('Pay Unpayed')

      var tickets2pay = []
      this.unpayedtickets.forEach((t)=>{
        tickets2pay.push(t.rn)
      })

      this.book_busy = true
      var postData = {
        "action"  :"payselected",
        "list"    : tickets2pay
      }

      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            if(response.data.success != false && response.data.success != 'false'){
              
              if(response.data.liqpay.data && response.data.liqpay.signature){
                
                this.globalrns = response.data.rns
                this.goLiqPay(response.data.liqpay.data, response.data.liqpay.signature,"#liqpay_checkout2")
              }
              
            }else{
              this.errorMaster(response.data.error)
            }  
          })
          .catch(error => {
              console.error('There was an error!', error)
              this.book_busy = false
              this.$toasted.error('Error').goAway(2000)
          });  

    },
    //******************************** */
    deleteTickets(rns,simulate = false){
      var el
      var postData = {
        "action"  :(simulate)?"cleartickets":"deletetickets",
        "rn"    : rns
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }

      rns.split(';').forEach((t)=>{
        console.log('Delete '+ t)
        el = document.getElementById('ticket_'+ t)
        if(el) el.classList.add('bg-secondary')
      })
      

      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            if(response.data.success != false && response.data.success != 'false'){
              if(response.data.result && Object.keys(response.data.result).length>0){
                Object.keys(response.data.result).forEach((t)=>{
                  console.log('result of del'+t)
                  console.log(response.data.result[t])
                  if(response.data.result[t].success===true)
                    this.$toasted.success('Бронювання №'+t+' було аннульовано').goAway(3000)
                })
              }
              if(response.data.unpayedtickets && this.unpayedtickets != response.data.unpayedtickets) 
                this.unpayedtickets = response.data.unpayedtickets
            }else{
              this.errorMaster(response.data.error)
            }     
          })
          .catch(error => {
              console.error('There was an error!', error)
              this.book_busy = false
              this.$toasted.error('Error').goAway(2000)
          });  

    },

    deleteVisit(rn) {
          
          this.$axios.post('/', {"action":"annulvisit","rn":rn } )
            .then((response) => {
              if(response.data.success != false && response.data.success != 'false'){
                if(response.data.message && response.data.message.code===777){
                  this.$toasted.error('Цей візит анулювати неможливо').goAway(3000)
                }else{
                  this.$toasted.success('Візит було анульовано').goAway(3000)
                  if(response.data.unpayedtickets && this.unpayedtickets != response.data.unpayedtickets) 
                    this.unpayedtickets = response.data.unpayedtickets
                  //this.updateStates() - не понадобится, т.к. это для платных групп
                  //this.updateBasket()
                }  
              }else{
                if(response.data.error=="init"){
                  this.needlogin = true
                }else{
                  this.$toasted.error(response.data.message).goAway(3000) 
                }
              }  
            })
            .catch(error => {
              //this.ordersbusy = false
              console.error('There was an error!', error)
            });
          
        },

    //**********************************/
    switchSidebar(el,action){
      switch (action){
        case 'on':
          if(document.getElementById(el) && document.getElementById(el).style.display=='none')
            this.$root.$emit('bv::toggle::collapse', el)
            console.log('-----on')
          break
        case 'off':    
          if(document.getElementById(el) && document.getElementById(el).style.display!='none')
            this.$root.$emit('bv::toggle::collapse', el)
            console.log('-----off')
      }
    },
    
    //******************************* */

    getTimeFromShiftList(){
      var timeFr="",timeTo=""
      //if(this.settings && this.settings.multi_shift){
        if(this.currentShiftsAssoc && this.currentShifts[0] && this.currentShiftsAssoc[this.currentShifts[0]] && this.currentShiftsAssoc[this.currentShifts[this.currentShifts.length-1]]){
          timeFr = this.floatToTime((this.currentShiftsAssoc[this.currentShifts[0]]).timeFr)
          timeTo = this.floatToTime((this.currentShiftsAssoc[this.currentShifts[this.currentShifts.length-1]]).timeTo)
          return timeFr+'-'+timeTo
        }else return null  
      //}else return null  
    },




    goBook(st = null){
      this.book_busy = true
      var timeFr="",timeTo="",shiftlist=""

      if(this.settings && this.settings.multi_shift)
        if(this.currentShiftsAssoc && this.currentShifts[0] && this.currentShiftsAssoc[this.currentShifts[0]] && this.currentShiftsAssoc[this.currentShifts[this.currentShifts.length-1]]){
          timeFr = this.floatToTime((this.currentShiftsAssoc[this.currentShifts[0]]).timeFr)
          timeTo = this.floatToTime((this.currentShiftsAssoc[this.currentShifts[this.currentShifts.length-1]]).timeTo)
          shiftlist=this.currentShifts
        }


      var postData = {
        "action"  :"book",
        "date"    : this.ondate,
        "dates"   : this.selectedDates,
        "hallobj" : this.currentRoom,
        "shift"   : this.currentShift,
        "seats"   : (st)?[st]:this.checkedSeats,
        "email"   : (!this.isLogined())?this.booking_email:null,
        "name"    : (!this.isLogined())?this.booking_name:null,
        "abon"    : (this.currentAbon && this.useAbon)?this.currentAbon:null,
        "timeFr"  : timeFr,
        "timeTo"  : timeTo,
        "shiftlist": shiftlist
      }

      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            if(response.data.success != false  && response.data.success != 'false'){
              
              //console.log(response.data)
              //this.init()
              //console.log('init')

              if(this.currentAbon && this.useAbon) {
                //абонемент
                this.book_busy = false

                if(response.data.errormessage){  
                  var errmsg=response.data.errormessage
                  this.$toasted.error('Увагf! Не вдалося забронювати відвідування на наступні дні : ').goAway(6000)
                  Object.keys(errmsg).forEach((e)=>{
                    this.$toasted.error(e + ': ' + this.errorMessage(errmsg[e]['code'])).goAway(6000)
                  })
                }else{
                  this.$toasted.success('Бронювання було успішним!').goAway(2000)
                }  
  
                this.checkedSeats = []
                this.currentDateTabMark = null
                //this.isBooking = false
                this.updateStates()
                //this.getAbonsVisits()
              }else if(response.data.liqpay.data && response.data.liqpay.signature){
                //------------------------------------------------
                if(response.data && response.data.success){

                  if(response.data.errormessage){
                    this.$toasted.error('Увагf! Не вдалося забронювати відвідування на наступні дні : '+Object.keys(errmsg).join(',')).goAway(6000)
                  }

                  this.unpayedticketsTmp = this.unpayedtickets

                  //if(!this.unpayedticketsTmp['checks']) this.unpayedticketsTmp['checks']=[]

                  //if(this.unpayedticketssum) {
                    //this.unpayedticketssum+= response.data.response[0]['totalPaySum']
                  //}else {
                  //  this.unpayedticketssum = response.data.booking[0]['totalPaySum']
                  //}
                  //response.data.response[0].checks.forEach((c) =>{
                  response.data.checks.forEach((c) =>{  
                    //c.ondate  = this.ondate
                    c.shift   = this.currentShift //обратить внимсан7ие!!!
                    this.unpayedticketsTmp.push(c)
                  })

                  this.unpayedtickets = this.unpayedticketsTmp
                  this.book_busy = false    
                  this.checkedSeats = []
                                   
                  if(this.readyToPay && this.unpayedtickets.length>0){
                    //this.readyToPay = false
                    this.$bvModal.show('unpayedtickets-modal')
                  }  

                  /*
                  this.unpayedticketsTmp['checks'] = []
                  this.unpayedticketsTmp['checks'].push(response.data.booking[0])
                  
                  console.log('this.unpayedticket')
                  console.log(this.unpayedtickets)        
                  console.log('1-'+response.data.booking[0]['totalPaySum'])
                  console.log('2-'+(response.data.booking[0]).totalPaySum)
                  */
                }

                //----------------------this.globalrns = response.data.rns

                //liqpaybegin

                //-----------------------this.goLiqPay(response.data.liqpay.data, response.data.liqpay.signature,"#liqpay_checkout")
                /*
                if(this.lpay){
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": response.data.liqpay.data,
                    "signature": response.data.liqpay.signature,
                    "embedTo": "#liqpay_checkout",
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  })
                }else{
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": response.data.liqpay.data,
                    "signature": response.data.liqpay.signature,
                    "embedTo": "#liqpay_checkout",
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  }).on("liqpay.callback", function(data){  
                      console.log('Callback '+this.globalrns)
                      console.log(data.status)

                      if(data.status=='failure' && data.code=='expired'){
                        this.$toasted.error('Час на оплату скінчився! Бронювання буде скасовано.').goAway(4000)
                        this.book_busy = true
                        this.checkedSeats = []
                        this.currentDateTabMark = null
                        this.updateStates()
                        this.book_busy = false
                      }else{
                        setTimeout(() => this.requestToPayStatus(this.globalrns), 2000)
                      }
                    }.bind(this)
                  ).on("liqpay.ready", function(data){
                    console.log(data.status)
                    this.book_busy = false
                    this.widget_is_visible = true
                    }.bind(this)
                  ).on("liqpay.close", function(data){
                    // close
                    console.log('Close')
                    console.log(data)
                  });
                }
                */
                //------------------------------------------------
              }
              
            }else{
              this.book_busy = false

              this.errorMaster(response.data.error)
                if(this.needlogin) return

              if(response.data.code)this.$toasted.error('Помилка № '+response.data.code).goAway(6000)

              if(response.data.code === 23){
                this.$toasted.error('Нажаль по цьому абонементу вже використано візити:(').goAway(6000)
                /*
                this.book_busy = true
                this.updateStates(false)
                this.book_busy = false  
                */
                
              }else if(response.data.code === 8){
                this.$toasted.error('Не введено електрону адресу!:(').goAway(6000)

              }else{  
                this.$toasted.error('Нажаль забронювати це вже неможливо:(').goAway(6000)
                if(response.data.message)
                  this.$toasted.error(response.data.message).goAway(4000)
                else if(response.data.error)
                  this.$toasted.error(response.data.error).goAway(4000)
                else  
                  this.$toasted.error('Error').goAway(2000)  


                this.book_busy = true
                this.updateStates()
                this.book_busy = false 
                //this.isBooking=false 
              }  
            }  
          })
          .catch(error => {
              console.error('There was an error!', error)
              this.book_busy = false
              this.$toasted.error('Error').goAway(2000)
          });  
    },

    goLiqPay(dt, sign, el){
                
                if(this.lpay){
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": dt,
                    "signature": sign,
                    "embedTo": el,
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  })
                }else{
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": dt,
                    "signature": sign,
                    "embedTo": el,
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  }).on("liqpay.callback", function(data){  
                      console.log('Callback '+this.globalrns)
                      console.log(data.status)

                      if(data.status=='failure' && data.code=='expired'){
                        this.$toasted.error('Час на оплату скінчився! Бронювання буде скасовано.').goAway(4000)
                        this.book_busy = true
                        this.checkedSeats = []
                        this.currentDateTabMark = null
                        this.updateStates()
                        this.book_busy = false
                      }else{
                        setTimeout(() => this.requestToPayStatus(this.globalrns), 2000)
                      }
                    }.bind(this)
                  ).on("liqpay.ready", function(data){
                    console.log(data.status)
                    this.book_busy = false
                    this.widget_is_visible = true
                    }.bind(this)
                  ).on("liqpay.close", function(data){
                    // close
                    console.log('Close')
                    console.log(data)
                  });
                }
    },



    requestToPayStatus(rns){
      //console.log('lpay')
      //console.log(this.lpay)
      //eslint-disable-next-line
      //this.lpay = LiqPayCheckout.close()
      //console.log(this.lpay)
      this.$axios.post('/', {"action":"getpaystatus","rn":rns} )
        .then((response) => {
          if(response.data.success != false && response.data.success != 'false'){
            let result = response.data
            switch(result.status){
              case 'wait':
                console.log('wait')
                this.$toasted.info('Очікуємо на підтвердження платежу').goAway(2000)
                setTimeout(() => this.requestToPayStatus(rns), 2000)
                break
              case 'success':
                console.log('success')
                this.$toasted.success(result.message).goAway(3000)
                //---оплата прошла успешно
                this.book_busy = false
                this.checkedSeats = []
                this.ticketToDownload = (result.file)?result.file:null
                this.printlist = result.printlist
                //this.isBooking = false
                this.updateStates()
                this.removeFromBasket(rns)
                //if(this.auth && this.auth.visitor) this.getTickets()
                break
              default:
                console.log(result)  
            } 
          }else{
            this.errorMaster(response.data.error)
          }   
        })
        .catch(error => {
          this.globalbusy = false
          console.error('There was an error!', error)
        });
    },
    removeFromBasket(rns){
      var rns2del = rns.split(';')
      var arrTmp = []
      this.unpayedtickets.forEach((t) => {
          if(!rns2del.includes(t.rn)) arrTmp.push(t)
      })
      this.unpayedtickets = arrTmp
    },
/*
    ticketDownload(fl){
      var axiosConfig = {
        
        //headers: {           'Content-Type': 'application/json',         },
        
        'withCredentials': true,
        'responseType': 'blob'
      }
      this.$axios.post('/', {"action":"download","rn":fl}, axiosConfig )
        .then((res) => {
          if(res.data.success != false && res.data.success != 'false'){
            var FILE = window.URL.createObjectURL(new Blob([res.data]));
            var docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', 'file.pdf');
            document.body.appendChild(docUrl);
            docUrl.click(); 

            //document.body.removeChild(docUrl);
            //window.URL.revokeObjectURL(FILE);
          }else{
            this.errorMaster(res.data.error)
          }   
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },
*/
     ticketDownload(fl,code){
      var axiosConfig = {
        /*
        headers: {
          'Content-Type': 'application/json',
        },
        */
        'withCredentials': true,
        'responseType': 'blob'
      }
      this.$axios.post('/', {"action":"download","rn":fl,"code":code}, axiosConfig )
        .then((res) => {
          console.log(res.data.success,res.data.error)
          if(res.data.success != false && res.data.success != 'false'){
            /*
            var FILE = window.URL.createObjectURL(new Blob([res.data]));
            var docUrl = document.createElement('a');
            docUrl.href = FILE;
            docUrl.setAttribute('download', 'ticket_'+fl+'.pdf');
            document.body.appendChild(docUrl);
            docUrl.click(); 
            ////document.body.removeChild(docUrl);
            ////window.URL.revokeObjectURL(FILE);
            */
            var FILE = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }));
            window.open(FILE)
          }else{
            if(res.data.error=="init"){
              this.$emit('needlogin')
            }
          } 
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },


    updateStatesRoom() {
      var postData = {
        "action"  :"updatestatesroom",
        "rn"    : this.currentRoom
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      var statestmp
      var statesdatatmp = this.statesdata
      
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            if(response.data.success != false && response.data.success != "false"){
              if(response.data.statesdata)  statestmp    = response.data.statesdata
              //if(response.data.states)      this.states    = response.data.states

              Object.keys(statestmp).forEach((st) => {
                if(statesdatatmp[st] != statestmp[st]){
                  //state was changed
                }
                statesdatatmp[st] = statestmp[st]
              })
              this.statesdata = statesdatatmp
              this.$nextTick().then(this.seatsStatesRecalc)
              //this.$nextTick().then(this.checkRoomShift)
              console.log('Updated')
            }else{
              this.errorMaster(response.data.error)
            }   
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },

    updateStates($clearselect = true) {
      var postData = {
        "action"  :"updatestates"
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            if(response.data.success != false && response.data.success != "false"){
              if(response.data.statesdata) this.statesdata  = response.data.statesdata
              if(response.data.roomsdata)  this.roomsdata   = response.data.roomsdata
              if(response.data.states)     this.states      = response.data.states
              if(response.data.abons)      this.auth.abons  = response.data.abons
              if(response.data.tickets && response.data.tickets.unpayed){
                this.unpayed  = response.data.tickets.unpayed
                //this.unpayedtickets = this.unpayed
              }   
              if(response.data.tickets && response.data.tickets.payed) this.payed    = response.data.tickets.payed
              if(response.data.tickets && response.data.tickets.archive) this.archive  = response.data.tickets.archive

              if($clearselect){
                this.currentDateTabMark = null
                this.checkedSeats = []
              }

              if(response.data.users)  this.users  = response.data.users

              this.$nextTick().then(this.seatsStatesRecalc)
              this.$nextTick().then(this.checkRoomShift)
              console.log('Updated')
            }else{
              this.errorMaster(response.data.error)
            }   
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },

     updateAbons() {
      var postData = {
        "action"  :"updateabons"
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
      this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            if(response.data.success != false && response.data.success != "false"){
              if(response.data.abons) this.auth.abons  = response.data.abons
              console.log('Abons Updated')
            }else{
              this.errorMaster(response.data.error)
            }   
          })
          .catch(error => {
              console.error('There was an error!', error)
          });  
    },


    updateBasket(){
      var postData = {
        "action"  :"updatebasket"
      }
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json',
        },
        'withCredentials': true
      }
        this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            if(response.data.success != false && response.data.success != "false"){
              if(response.data.tickets && response.data.tickets.unpayed){
                this.unpayed  = response.data.tickets.unpayed
                //this.unpayedtickets = this.unpayed
              }   
              if(response.data.tickets && response.data.tickets.payed) this.payed    = response.data.tickets.payed
              if(response.data.tickets && response.data.tickets.archive) this.archive  = response.data.tickets.archive
              if(response.data.unpayedtickets) this.unpayedtickets = response.data.unpayedtickets
              console.log('Updated')
            }else{
              this.errorMaster(response.data.error)
            }   
          })
          .catch(error => {
              console.error('There was an error!', error)
          }); 

    },

    openBasketToPay(){
      this.$bvModal.show('unpayedtickets-modal')
    },

    //----------------------------
    getAbonsVisits(){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        },
        'withCredentials': true
      }
      var postData = {"action":"getabonsvisits"}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" && response.data.success != false){
            this.auth.abons  = response.data.abons
            console.log('abons')
            console.log(this.auth.abons)
            this.$toasted.success('Updated successfull').goAway(2000)
          }else{
            this.globalbusy = false
            this.errorMaster(response.data.error)
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },

    //----------------------------
    getTickets(){
      //var unp,dtFr,dtTo
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        },
        'withCredentials': true
      }
      var postData = {"action":"gettickets"}
      this.$axios.post('/', postData,  axiosConfig)
        .then((response) => {
          if(response.data.success != "false" && response.data.success != false){
            if(response.data.payed) this.payed    = response.data.payed
            if(response.data.archive) this.archive  = response.data.archive
            /*
            if(response.data.unpayed) {
              this.unpayedtickets = []   // потом будем добавлять, а не очищать
              this.unpayed  = response.data.unpayed
              this.unpayed.forEach((ch)=>{
                dtFr = new Date(ch.timeFr)
                dtTo = new Date(ch.timeTo)
                unp = {
                  "rn"      : ch.checkRN,
                  "paySum"  :ch.checkRN,
                  "payUpTo" : ch.payUpTo,
                  "fullname": ch.fullname,
                  "objname" : ch.objname,
                  "ticketRN": ch.ticketRN,
                  "seatRN"  : ch.seatRN,
                  "rownum"  : ch.rownum,
                  "namerow" : ch.namerow,
                  "namecol" : ch.namecol,
                  "seat"    : ch.seat,
                  "ondate"  : dtFr.getFullYear.toString+'-'+dtFr.getMonth.toString+'-'+dtFr.getDate.toString,
                  "shift"   : null,
                  "timeFr"  : dtFr.getHours.toString+':'+dtFr.getMinutes.toString,
                  "timeTo"  : dtTo.getHours.toString+':'+dtTo.getMinutes.toString,
                  "shiftscount": 1
                }
                this.unpayedtickets.push(unp)
              })
            }
            */
            this.$toasted.success('Updated successfull').goAway(2000)
          }else{
            this.globalbusy = false
            this.errorMaster(response.data.error)
          }
        })
        .catch(error => {
          console.error('There was an error!', error)
        });
    },

    //----------------------------
    floatToTime(t){
      //var tmp = t-Math.trunc(t)
      var tmp = (t % 1)
      var out = (Math.trunc(t)).toString() + ':'
      if(tmp > 0){
        var priznak = Math.round(tmp*100)
        out += (priznak<10)?'0':''
        out += priznak.toString()
      }else{
        out += '00'
      }
      return out
    },

    /*
    TimeToFloat(d){
      var t=new Date(d)
      return t.getHours() + t.getMinutes()+100
    },
    */
    validDateAbon(abn,dt){
      if(!abn) return true
      if(abn.datefr && new Date(dt) < new Date(abn.datefr)) return false
      if(abn.dateto && new Date(dt) > new Date(abn.dateto)) return false
      if(!this.currentAbon) this.currentAbon = abn.rn //первый валидный
      return true
    },
    validDateServiceAbon(abn,dt,servRN){
      if(!abn) return true
      if(abn.datefr && new Date(dt) < new Date(abn.datefr)) return false
      if(abn.dateto && new Date(dt) > new Date(abn.dateto)) return false
      if(abn.serviceRN != servRN) return false
      if(!this.currentAbon) this.currentAbon = abn.rn //первый валидный
      return true
    },
    setValidDateServiceAbon(){
      if(!this.auth || !this.auth.abons ) return true
      if(!this.ondate) return true
      if(!this.checkedSeats[0] || !this.seatInfo[this.checkedSeats[0]] || !(this.seatInfo[this.checkedSeats[0]]).nomenu) return true
      let dt = this.ondate
      let serviceRN = (this.seatInfo[this.checkedSeats[0]]).nomenu
      var flag,abn

      if(this.currentAbon && this.auth.abons[this.currentAbon]){
        abn = this.auth.abons[this.currentAbon]
        flag = true
        if(abn.datefr && new Date(dt) < new Date(abn.datefr)) flag = false
        if(flag && abn.dateto && new Date(dt) > new Date(abn.dateto)) flag = false
        if(flag && abn.serviceRN != serviceRN) flag = false
        if(!flag) this.currentAbon = null
      }

      Object.values(this.auth.abons).forEach(abn =>{
        if(!this.currentAbon){
          flag = true
          if(abn.datefr && new Date(dt) < new Date(abn.datefr)) flag = false
          if(flag && abn.dateto && new Date(dt) > new Date(abn.dateto)) flag = false
          if(flag && abn.serviceRN != serviceRN) flag = false
          if(flag) this.currentAbon = abn.rn //первый валидный
        }  
      })
      return true
    },
    getSeatLabel(nam,num){
      if(!nam)return ''
      if(nam.indexOf('#')>-1)
        return nam.replace('#',num)
      else
        return nam
    },

    setNextDay(){
      if(this.ondateTabIndex < this.ondates.length-1){
        this.changeOndate(this.ondates[this.ondateTabIndex+1])
        this.ondateTabIndex++
        console.log("nextDay")
      }
    },
    setPrevDay(){
      if(this.ondateTabIndex > 0){
        //this.init(null,this.ondates[this.ondateTabIndex-1])
        this.changeOndate(this.ondates[this.ondateTabIndex-1])
        this.ondateTabIndex--
        //this.ondate = this.ondates[this.ondateTabIndex-1]
        console.log("prevDay")
      }
    },
    dateDisabled(ymd, date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        // disable days that fall on the 13th of the month
        //console.log(date)
        // const weekday = date.getDay()
        var dt = '' + date.getFullYear()
        dt += (date.getMonth()<9)?'-0':'-'
        dt += date.getMonth()+1
        dt += (date.getDate()<10)?'-0':'-' 
        dt += date.getDate()
        //console.log(dt)
        //console.log((this.holidays[dt]!=null)?'true':'false')
        //const day = date.getDate()
        // Return `true` if the date should be disabled
        //return weekday === 0 || weekday === 6 || day === 13
        //return weekday === 0
        //return this.holidays[dt] != null
        return !this.ondates.includes(dt)
    },
    dateDisabledExt(ymd) {

      var enabled = true

    //dateDisabledExt(ymd, date) {
        // Disable weekends (Sunday = `0`, Saturday = `6`) and
        // disable days that fall on the 13th of the month
        //console.log(ymd,date,sts)
        // const weekday = date.getDay()
       
        /*
        var dt = '' + date.getFullYear()
        dt += (date.getMonth()<9)?'-0':'-'
        dt += date.getMonth()+1
        dt += (date.getDate()<10)?'-0':'-' 
        dt += date.getDate()
        if(!this.ondates.includes(dt)) return true
        */
        var now = new Date()
        var d = '',timto,timenow
        d += now.getFullYear().toString()
        d += (now.getMonth()<9)?'-0':'-'
        d += (now.getMonth()+1).toString()+'-'
        d += (now.getDate()<10)?'0':'' 
        d += now.getDate().toString()

        if(ymd === d){
          timto = Math.max.apply(Math, this.currentShifts.map(item =>  {return (this.shiftspec[item]).timeTo}) )
          timenow = now.getHours()+now.getMinutes()/100
          console.log("MAX-NOW",timto,timenow)
          if(timenow>timto) return true
        }

        if(!this.ondates.includes(ymd)){
          enabled = false
        }else{
          enabled = true
          this.currentShifts.forEach((sft)=>{
            if(enabled){
              //console.log(ymd,this.currentRoom,sft,this.checkedSeats[0])
              /*
              if(
                this.statesDateRoomShiftSeats[ymd] &&
                this.statesDateRoomShiftSeats[ymd][this.currentRoom] &&
                this.statesDateRoomShiftSeats[ymd][this.currentRoom][sft] &&
                this.statesDateRoomShiftSeats[ymd][this.currentRoom][sft][this.checkedSeats[0]]>0
              ) {
                console.log(ymd,this.currentRoom,sft,this.checkedSeats[0])
                enabled = false
              }  */

              if(
                this.reservedDateRoomShift &&
                this.reservedDateRoomShift[ymd] &&
                this.reservedDateRoomShift[ymd][this.currentRoom]
              ){
                //enabled = enabled &&  this.reservedDateRoomShift[ymd][this.currentRoom][sft]===0
                //console.log('testDisabled',ymd,sft,this.reservedDateRoomShift[ymd][this.currentRoom][sft])
                switch (this.reservedDateRoomShift[ymd][this.currentRoom][sft]){
                  case 0:
                    // enabled = enabled && true
                    // so - do nothing
                    break
                  case 1:
                    // прверяем места

                    if(this.nulSeats.includes(this.checkedSeats[0])){
                      //нулевое место, т.е. всю комнату, не занять
                      enabled = false
                    }else{
                      if(
                        this.statesDateRoomShiftSeats[ymd] &&
                        this.statesDateRoomShiftSeats[ymd][this.currentRoom] &&
                        this.statesDateRoomShiftSeats[ymd][this.currentRoom][sft]
                      ) {
                        if(this.statesDateRoomShiftSeats[ymd][this.currentRoom][sft][this.checkedSeats[0]]>0){
                          console.log(ymd,this.currentRoom,sft,this.checkedSeats[0])
                          enabled = false
                        }  
                      }else{
                        enabled = false
                      } 
                    }                        
                    break
                  case 2:
                    //enabled = enabled && false
                    enabled = false
                    break
                  default:
                    enabled = false
                }
              }else{
                enabled = false
              }
              
              /*
              if(
                this.statesDateRoomShiftSeats[ymd] &&
                this.statesDateRoomShiftSeats[ymd][this.currentRoom] &&
                this.statesDateRoomShiftSeats[ymd][this.currentRoom][sft]
              ) {
                enabled = enabled && this.statesDateRoomShiftSeats[ymd][this.currentRoom][sft][this.checkedSeats[0]]===0
              }else{
                enabled = false
              }  
              */
            } 
          })
        }
        return !enabled
        
        //console.log(dt)
        //console.log((this.holidays[dt]!=null)?'true':'false')
        //const day = date.getDate()
        // Return `true` if the date should be disabled
        //return weekday === 0 || weekday === 6 || day === 13
        //return weekday === 0
        //return this.holidays[dt] != null
      
    },

    dateIsSelected(ymd){
      if(this.selectedDates.includes(ymd))
        return 'date-is-selected'
      else
        return ''  
    },

    activeVariant(ymd){
      if(this.selectedDates.includes(ymd))
        return 'warning'
      else
        return 'white'
    },

    clickMultiDate(ymd){
      if(ymd===this.ondate) return true
      if(!this.selectedDates.includes(ymd))
        this.selectedDates.push(ymd)
      else{
        var i = this.selectedDates.indexOf(ymd);
        if (i !== -1) {
          this.selectedDates.splice(i, 1);
        }
      }
    },
    isLogined(){
      return (this.auth && this.auth.visitor && this.auth.visitor.visitorRN)
    },
      
    svgInsertShtrihovka(svg){
      var strihovka='<style type="text/css">line{stroke:#000;}</style><defs><pattern id="diagonalHatch" width="10" height="10" patternTransform="rotate(45)" patternUnits="userSpaceOnUse"><line x1="0" y1="0" x2="0" y2="10" /></pattern></defs>'
      return svg.replace('</svg>',strihovka+'</svg>')
    },

    jumpTo(rn,typ){
      switch (typ){
        case 'group':
          this.gruppa2open = rn
          this.razdel = 'grouplessons'
          break
        case 'worker':
          this.worker2open = rn
          this.razdel = 'specialisty'  
          break
      }    
    },

    setVisitorInfo(s){
      this.auth.visitor = s
      console.log('setVisitorInfo',s)
    }

    /*
    jumpToGroup(rn,xrn){
      console.log(xrn)
      this.gruppa2open = rn
      this.razdel = 'grouplessons'
    },

    jumpToWorker(rn){
      this.worker2open = rn
      this.razdel = 'specialisty'
    }
    */
  //***************************************************** */  
  }  

}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(-10px);
  opacity: 0;
}
.b-calendar [aria-disabled="true"] {
  cursor: not-allowed;
}
.date-is-selected,
.date-is-selected .btn-light{
  background-color:#263b97;
  border-radius: 1.2em;
}
.date-is-selected span{
  color: #fff !important;
}
.b-calendar span:focus,
.b-calendar .btn.focus,
.b-calendar .form-control:focus {
  box-shadow: none;
  border: none;
}
.b-calendar-grid-help{
  display: none;
}

.b-calendar .btn-outline-light:hover {
  background-color: transparent;
}

</style>