<template>

  <!--b-card no-body-->
    <b-tabs card>
      <b-tab title="Основні" active>
        <!--b-card-text-->
        <b-overlay
          :show="busy"
          rounded
          opacity="0.6"
          spinner
          spinner-variant="bruvis"
        >
          <div id="photo" v-if="userinfo">
            <!--img 
              v-if="userinfo.photo && !selectedFile" 
              v-bind:src="'data:image/jpeg;base64,'+userinfo.photo" 
              class="rounded-circle w-100"
            /-->
            <!------------------------->
            <input type="file" accept="image/jpeg" ref="fileInput"
              @change="onFileSelected"  style="display: none">

            <b-card v-if="selectedFile" class="text-center">
              <img :src="previewImage" class="uploading-image w-50 text-center" />
              <!--small>{{selectedFile.name}} ({{selectedFile.size}} байт)</small-->

              <b-button-group class="my-2">
                <b-button @click="onFileReset()" variant="danger">
                  <b-icon icon="x"></b-icon> Скасувати
                </b-button>
                <b-button @click="onUpload" variant="success">
                  <b-icon icon="cloud-upload"></b-icon> Зберегти
                </b-button>
              </b-button-group>

              <b-progress class="w-100" max="100" height="1.5rem" v-if="currentProgress!=null">
                <b-progress-bar :value="currentProgress*100" :label="`${(currentProgress*100).toFixed(2)}%`"></b-progress-bar>
              </b-progress>

            </b-card>

            <!--div class="w-100 text-center">
              <img :src="previewImage" class="uploading-image w-50 text-center" />
            </div-->  

            <!--b-alert 
              v-if="selectedFile"
              variant = "info" show dismissible fade
              @dismissed="onFileReset()"
              class="text-left"
            >
              {{selectedFile.name}} ({{selectedFile.size}} байт)
            </b-alert-->  

            <!--input type="file" accept="image/jpeg" ref="fileInput"
              @change="onFileSelected"  style="display: none"-->
            <!--button @click="onUpload" v-if="selectedFile">Upload</button-->

            <!--b-progress class="w-100" max="100" height="1.5rem" v-if="currentProgress!=null">
              <b-progress-bar :value="currentProgress*100" :label="`${(currentProgress*100).toFixed(2)}%`"></b-progress-bar>
            </b-progress-->
            <!------------------------->
          </div>  

          <div id="profile-name" class="alert alert-info mb-3 h5" v-show="!changenamemode" v-if="userinfo">

            <div v-if="!selectedFile"  v-b-hover="hoverHandler">
              <b-overlay :show="overlayed" opacity="0.5" variant="dark"> 
                <template #overlay>
                  <div class="text-center" @click="$refs.fileInput.click()" role="button">
                    <b-icon icon="camera" font-scale="2" variant="light"></b-icon>
                    <h6 class="text-light" v-if="userinfo.photo">Змінити фото</h6>
                    <h6 class="text-light" v-else>Додати фото</h6>
                  </div>
                </template>
                <template v-if="userinfo.photo">
                  <img 
                    v-if="userinfo.photo && !selectedFile" 
                    v-bind:src="'data:image/jpeg;base64,'+userinfo.photo" 
                    width="100"
                  />
                </template>
                <template v-else-if="!userinfo.photo">
                  <div class="empty-photo"></div>    
                </template>   
              </b-overlay>  

            </div>
             
            <div id="namefield" v-if="userinfo">  
                <h5>{{userinfo.mnemo}}</h5>
                <h6 v-if="userinfo.email"><b-icon icon="envelope" class="mr-2"/>{{userinfo.email}}</h6>
                <h6 v-if="userinfo.phoneNumber"><b-icon icon="telephone" class="mr-2"/>{{userinfo.phoneNumber}}</h6>
            </div>
            <div id="edit-name-ico"><b-icon icon="pencil-fill" role="button" @click="changenamemode=true" title="Редагувати"></b-icon></div>
          </div>
          <b-card header-tag="header" class="mb-2" footer-tag="footer" v-show="changenamemode"
            header-bg-variant="bruvis" header-text-variant="white" border-variant="dark"
          >
            <template #header>
              <h6 class="mb-0">Редагування профілю</h6>
            </template>
            <b-card-text v-if="userinfo">
                <ChangeName 
                  :surname="userinfo.surName" 
                  :firstname="userinfo.firstName" 
                  :secondname="userinfo.secondName" 
                  :email="userinfo.email"
                  :phone="userinfo.phoneNumber"  
                  @oncancel="changenamemode=false" 
                  @onsuccess="changenamemode=false,onChangeName()"
                  @onregetdata="onChangeName()"
                />
            </b-card-text>
          </b-card>

          <b-button block @click="changepassmode=true" v-show="!changepassmode">Змінити пароль</b-button>
          <b-card 
            header-tag="header"  class="mb-2" footer-tag="footer" v-show="changepassmode"
            header-bg-variant="bruvis" header-text-variant="white" border-variant="dark"
          >
            <template #header>
              <h6 class="mb-0">Зміна пароля</h6>
            </template>
            <b-card-text>
                <ChangePass 
                  @onsuccess="changepassmode=false"   
                  @oncancel="changepassmode=false" 
                />
            </b-card-text>
          </b-card>

        </b-overlay>
        <!--/b-card-text-->
      </b-tab>
      <b-tab title="Абонементи" :disabled="Object.keys(abons).length===0" v-if="abons">
        <!--b-card-text-->
          <b-alert variant="info" v-for="(a,rn) in abons" :key="rn" show>
            <div class="abon-item">
              <div>
                <div><b>{{a.xnomenu}}</b></div>
                <div v-if="a.datefr || a.dateto">
                  Дійсний<template v-if="a.datefr"> з {{new Date(a.datefr) | dateFormat('DD-MM-YYYY')}}</template>
                  <template v-if="a.dateto"> до {{new Date(a.dateto) | dateFormat('DD-MM-YYYY')}}</template>
                </div>
                <pre v-if="debugmode">
                {{a}} 
                </pre>
              </div> 
              <div class="abon-item-qr">
                <b-icon icon="upc-scan" class="mr-3" @click="showAbonBarcode(a.rn)" role="button"/>
              </div>
            </div>
            <b-collapse :id="'abon_barcode_'+a.rn"> <!--visible="abonbarcode===a.rn"-->
              <div class="barcode p-2 text-center" v-html="a.barcode"></div>  
            </b-collapse>
          </b-alert> 
        <!--/b-card-text-->
      </b-tab>
      <b-tab title="Клубні карти" :disabled="!clubcards || clubcards.length===0">
        <!--b-card-text-->

        <b-aspect :aspect="1.85" v-if="clubcards != null && clubcards.length > 0" class="mb-3">  
              <div class="active dark club-card">
                <div class="shadow"></div>
                <div class="right" v-if="!barcode">
                  <div class="card-info">
                    <div class="card-info-1 mb-2">
                      <div class="card-name"><b-icon icon="upc-scan" class="mr-3" @click="barcode=(clubcards[0]).barcode" role="button"></b-icon>CLUB CARD</div>
                      <div class="card-num">№ {{(clubcards[0]).cardnum}}</div>
                      <template v-if="(clubcards[0]).balance > 0">
                        <div class="card-credit" v-if="(clubcards[0]).cardlimit && (clubcards[0]).cardlimit > 0">
                          credit {{(clubcards[0]).cardlimit  | toCurrency}}
                        </div>
                      </template>
                      <template v-else>
                        <div class="card-credit text-victor" v-if="(clubcards[0]).cardlimit && (clubcards[0]).cardlimit > 0">
                          <b-icon icon="piggy-bank-fill" flip-h class="mr-1"></b-icon> {{(clubcards[0]).balance  | toCurrency}}
                        </div>  
                      </template>
                    </div>
                    <div class="card-info-2" v-if="(clubcards[0]).tarif">
                      <div class="card-tarif">{{(clubcards[0]).tarifMnemo}}</div>
                    </div>
                    <div class="card-info-3" v-if="(clubcards[0]).balance && (clubcards[0]).balance > 0">
                      <b-icon icon="piggy-bank-fill" flip-h class="mr-2"></b-icon>
                      <div class="card-ballance">{{(clubcards[0]).balance  | toCurrency}}</div>
                    </div>
                    <div class="card-info-3" v-else>
                      credit {{(clubcards[0]).cardlimit+(clubcards[0]).balance  | toCurrency}}
                    </div>
                  </div>
                  <div class="card-refill" v-if="settings && settings.enable_clubcard_refill">  
                    <b-button class="button-card-refill bg-transparent" role="button" v-b-modal.refill-modal>
                      <b-icon icon="plus-circle" font-scale="1.2" class="mr-2"></b-icon> Поповнити
                    </b-button>
                  </div>  
                  <div class="card-holdername">
                    {{user.visitor.mnemo}}<!--{{user.visitor.mnemo | capitalize }}-->
                  </div>  
                </div>
                <div v-else class="w-100">
                  <div class="close_barcode"><b-icon icon="x" @click="barcode=null" font-scale="1.5" role="button" class="m-2"></b-icon></div>
                  <div class="barcode p-2 text-center" v-html="barcode"></div>
                </div>  
              </div>
            </b-aspect>

        <!--/b-card-text-->
      </b-tab>
    </b-tabs>
  <!--/b-card-->


    <!--div>
        
            <div class="alert alert-info mb-3 h5">  
             <template v-if="user.visitor.surname && user.visitor.firstname">
                {{user.visitor.surname}} {{user.visitor.firstname}} {{user.visitor.secondname}}
              </template>
              <template v-else>
                <template v-if="user.visitor.fillname">{{user.visitor.surname}}</template>
                <template v-else>{{user.visitor.username}}</template>
              </template>
            </div-->

            <!--b-aspect :aspect="1.85" v-if="clubcards != null && clubcards.length > 0" class="mb-3">  
              <div class="active dark club-card">
                <div class="shadow"></div>
                <div class="right">
                  <div class="card-info">
                    <div class="card-info-1 mb-2">
                      <div class="card-name">CLUB CARD</div>
                      <div class="card-num">№ {{(clubcards[0]).cardnum}}</div>
                      <template v-if="(clubcards[0]).balance > 0">
                        <div class="card-credit" v-if="(clubcards[0]).cardlimit && (clubcards[0]).cardlimit > 0">
                          credit {{(clubcards[0]).cardlimit  | toCurrency}}
                        </div>
                      </template>
                      <template v-else>
                        <div class="card-credit text-victor" v-if="(clubcards[0]).cardlimit && (clubcards[0]).cardlimit > 0">
                          <b-icon icon="piggy-bank-fill" flip-h class="mr-1"></b-icon> {{(clubcards[0]).balance  | toCurrency}}
                        </div>  
                      </template>
                    </div>
                    <div class="card-info-2" v-if="(clubcards[0]).tarif">
                      <div class="card-tarif">{{(clubcards[0]).tarifMnemo}}</div>
                    </div>
                    <div class="card-info-3" v-if="(clubcards[0]).balance && (clubcards[0]).balance > 0">
                      <b-icon icon="piggy-bank-fill" flip-h class="mr-2"></b-icon>
                      <div class="card-ballance">{{(clubcards[0]).balance  | toCurrency}}</div>
                    </div>
                    <div class="card-info-3" v-else>
                      credit {{(clubcards[0]).cardlimit+(clubcards[0]).balance  | toCurrency}}
                    </div>
                  </div>
                  <div class="card-refill" v-if="enable_refill">  
                    <b-button class="button-card-refill bg-transparent" role="button" v-b-modal.refill-modal>
                      <b-icon icon="plus-circle" font-scale="1.2" class="mr-2"></b-icon> Поповнити
                    </b-button>
                  </div>  
                  <div class="card-holdername">
                    {{user.mnemo | capitalize }}
                  </div>  
                </div>
              </div>
            </b-aspect-->


              <!--b-card header-tag="header" footer-tag="footer"  v-if="clubcards != null && clubcards.length > 0" class="mb-3">
                <template #header>
                  <h6 class="mb-0">Клубна картка №{{(clubcards[0]).cardnum}}</h6>
                </template>
                <b-card-text>
                  <template>
                    <p class="h6"></p>
                    <p class="h6" v-if="(clubcards[0]).validTo">Дійсна до {{new Date((clubcards[0]).validTo) | dateFormat('DD.MM.YYYY')}}</p>
                    <p class="h6 "> 
                        Тариф: <template v-if="(clubcards[0]).tarif">{{(clubcards[0]).tarifMnemo}}</template>
                              <template v-else>---</template>
                    </p>
                    <p class="h6 "> 
                      Баланс: <template v-if="(clubcards[0]).balance">{{(clubcards[0]).balance  | toCurrency}}</template>
                              <template v-else>0</template>
                        
                    </p>
                    <p class="h6" v-if="(clubcards[0]).cardlimit && (clubcards[0]).cardlimit > 0">
                      Кредитний ліміт: {{(clubcards[0]).cardlimit  | toCurrency}}
                    </p> 
                    </template>  
                  </b-card-text>  
                  <template #footer>
                    <b-button variant="bruvis" class="ml-0" v-b-modal.refill-modal>Поповнити баланс</b-button>
                  </template>
                </b-card-->




        <!--b-card header-tag="header" footer-tag="footer">
          <template #header>
            <h6 class="mb-0">Зміна пароля</h6>
          </template>
          <b-card-text>
              <ChangePass/>
          </b-card-text>
        </b-card-->
  

        <!-------------------------------------------->
        <!--
        <b-modal 
          id="refill-modal" 
          hide-header-close- 
          centered 
          scrollable 
          size="md" 
          :no-close-on-backdrop="true"
        >
          <template #modal-title>
            <b-icon icon="credit-card" class="rounded bg-secondary p-1" variant="light" font-scale="1.3"></b-icon> <span class="p-2 h5 mr-auto">Поповнення балансу</span>
          </template>
          <RefillCard @onRefill = "init_afterrefill()" @needlogin = "needlogin()"/>
          <template #modal-footer>
            <b-button variant="bruvis" @click="$bvModal.hide('refill-modal')">
              Закрити
            </b-button>
          </template>
        </b-modal>  
        -->
        <!-------------------------------------------->

        <!--div v-if="clubcards != null && clubcards.length > 0">
            <b-card no-body>
                <pre>    
                    {{clubcards}}
                </pre>
            </b-card>  
        </div-->

    <!--/div-->
</template>

<script>
import ChangePass from './changepass.vue'
import ChangeName from './changename.vue'
//import RefillCard from './refill.vue'
export default {
  name: 'UserProfile',
  components: {
    ChangePass,
    ChangeName,
    //RefillCard
  },
  props: {
    user: {
      type: Object,
      required: true
    },
    debugmode: {
      type: Object,
      required: true
    },
    /*
    clubcards:{
      type: Object,
      required: true
    },
    enable_refill:{
      type: Object,
      required: true
    }
    */
  }, 
  data: () => ({
    changepassmode: false,
    changenamemode: false,
    clubcards: null,
    barcode: null,
    abonbarcode: null,
    userinfo: null,
    abons: null,

    previewImage:null,
    selectedFile: null,
    currentProgress: null,
    overlayed: false
    
  }),
  mounted(){
    this.clubcards = this.user.clubbase
    this.getUserInfo()

  },    
  methods: {
    /*
    init_afterrefill(){
      this.$emit('onRefill') 
    },
    */
    needlogin(){
      this.$emit('needlogin')
    },
    onChangeName(){
      this.getUserInfo('update')
    },
    getUserInfo(mode){
      this.busy = true;
      this.$axios.post('/', {"action":"getuserinfo" } )
        .then((response) => {
          if(response.data.success != false && response.data.success != 'false'){
            this.userinfo = response.data.userinfo
            this.abons = response.data.abons
            if(mode==='update') this.$emit("setVisitorInfo",this.userinfo)
            this.busy = false;
          }else{
            this.busy = false;
            if(response.data.error=="auth" || response.data.error=="init"){
                this.$emit('needlogin')
            }else{
              this.$toasted.error(response.data.message).goAway(3000) 
            }
          }  
        })
      .catch(error => {
        this.busy = false
        console.error('There was an error!', error)
      });
    },
    //----------------------------------------------------

    onFileSelected(event){
      console.log(event)
      this.selectedFile = event.target.files[0]
      this.currentProgress=null
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = e =>{
        this.previewImage = e.target.result;
        //console.log(this.previewImage);
      }
    },
    onFileReset(){
      this.selectedFile = null
      this.currentProgress=null
      this.previewImage = null
      this.overlayed = false
    },
    onUpload(){
      const fd = new FormData()
      //fd.append('image',this.selectedFile,this.selectedFile.name)
      fd.append('image',this.selectedFile)
      fd.append('filename',this.selectedFile.name)
      fd.append('action','addphoto')
      this.$axios.post('/', fd,{
        onUploadProgress: uploadEvent =>{
          this.currentProgress = uploadEvent.loaded / uploadEvent.total
        },
        headers: {
         "Content-Type": "multipart/form-data",
        }
      })
        .then(res =>{
          console.log(res)
          if(res.status===200){
            this.onFileReset()
            this.getUserInfo('update')
            this.overlayed = false
          }
        })

    },
    hoverHandler(isHovered) {
      this.overlayed = isHovered
    },
    showAbonBarcode(rn){
      if(this.abonbarcode) this.$root.$emit('bv::toggle::collapse', 'abon_barcode_'+this.abonbarcode)
      if(this.abonbarcode!=rn){
        this.$root.$emit('bv::toggle::collapse', 'abon_barcode_'+rn)
        this.abonbarcode=rn
      }else  
        this.abonbarcode=null
    }

  }

}
</script>

<style scoped>

.club-card.dark {
  background: linear-gradient(to bottom,#003462,#004D90,#0077E2);
  box-shadow: 0px 0px 10px 2px rgb(0 0 0 / 35%);
}

.club-card.active {
  box-shadow: 0px 0px 10px 3px #ffffff !important;
}

.club-card {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 8px;
  border-radius: 10px;
  color: #ffffff;
  -webkit-transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1);
  transition: all 0.3s cubic-bezier(0.645,0.045,0.355,1);
}

/**/

.club-card.active .shadow {
    background-color: transparent;
}

.club-card .shadow {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    pointer-events: none;
    border-radius: 10px;
    background-color: rgba(0,0,0,0.25);
    -webkit-transition: background-color 0.3s cubic-bezier(0.645,0.045,0.355,1);
    transition: background-color 0.3s cubic-bezier(0.645,0.045,0.355,1);
}

.club-card .right {
  width: 100%;  
}

.club-card .right .card-info {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    /*height: 130px;*/
    padding: 20px 15px 20px 15px;
    line-height: 1;
}

.card-info-1, .card-info-2, .card-info-3{
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    padding-right: 16px;
    line-height: 1;
    font-family: Segoe UI;
    font-weight: 600;
}

.card-info-1 .card-name {
    font-size: 23px;
    font-weight: 700;
    line-height: 1;
    cursor: default;
    color: #ffffff;
}

.card-info-1 .card-num {
    position: absolute;
    top: 0px;
    right: 4px;
}

.card-info-1 .card-credit {
    position: absolute;
    top: 20px;
    right: 4px;
}

.card-info-1 .card-tarif {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    cursor: default;
    color: #ffffff;
}

.card-info-3{
    justify-content: center;
    font-size: 30px;
    font-weight: 700;
    margin-top:20px;
}

.card-refill{
  position:absolute;
  right: 8px;
  bottom: 15px;
}

.button-card-refill {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 15px;
    font-weight: 600;
    border-color: transparent;
}

.card-holdername{
  position: absolute;
  bottom: 23px;
  left: 27px;
}

.club-card p {
  margin: 0;
  line-height: 1;
}

.empty-photo{
  border:2px dashed white;
  width:100px;
  height:100px;
}

#profile-name{
  display: flex;
  justify-content: space-between;
}

#namefield{
  padding: 0 0.5em;
}

#edit-name-ico{
  flex-grow: 1;
  text-align: right;

}

.abon-item{
    display: flex;
    justify-content: space-between;
  }

  .abon-item-qr{
    text-align: right;
  }


@media (max-width: 575px) {  
  .card-info-3 {
    font-size: 20px;
    font-weight: 600;
    margin-top: 14px;
  }

  #profile-name{
    flex-direction: column;
  }

  #namefield{
    padding: 0 0.5em;
  }
  #namefield h6{
    padding-left:0;
  }
  #namefield h5{
    padding-bottom:1em;
  }
  
  
}


</style>
