<template>
  <div>
    <validation-observer ref="observer" v-slot="{ handleSubmit }">
      <b-form @submit.stop.prevent="handleSubmit(onSubmit)" autocomplete="off">
        <validation-provider
          name="Ім'я"
          :rules="{required: true, min: 1, max: 16}"
          v-slot="validationContext"
        ><!--          mode="lazy"-->
          <b-form-group id="example-input-group-1" label="Ім'я" label-for="firstname">
            <b-form-input
                id="firstname"
                name="firstname"
                ref="firstname"
                v-model="ofirstname"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="firstname-live-feedback"
                autocomplete="current-password"
                spellcheck="false" 
                autocorrect="off" 
                autocapitalize="off" 
            ></b-form-input>
            <b-form-invalid-feedback id="firstname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          name="По-батькові"
          :rules="{required: true, min: 1, max: 16}"
          v-slot="validationContext"
        ><!--          mode="lazy"-->
          <b-form-group id="example-input-group-2" label="По-батькові" label-for="secondname">
            <b-form-input
                id="secondname"
                name="secondname"
                ref="secondname"
                v-model="osecondname"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="secondname-live-feedback"
                autocomplete="current-password"
                spellcheck="false" 
                autocorrect="off" 
                autocapitalize="off" 
            ></b-form-input>
            <b-form-invalid-feedback id="secondname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>        

        <validation-provider
          name="Призвіще"
          :rules="{required: true, min: 1, max: 16}"
          v-slot="validationContext"
        ><!--          mode="lazy"-->
          <b-form-group id="example-input-group-3" label="Призвіще" label-for="surname">
            <b-form-input
                id="surname"
                name="surname"
                ref="surname"
                v-model="osurname"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="surname-live-feedback"
                autocomplete="current-password"
                spellcheck="false" 
                autocorrect="off" 
                autocapitalize="off" 
            ></b-form-input>
            <b-form-invalid-feedback id="surname-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          name="Email"
          :rules="{ required: false , regex: /^[a-zA-Z0-9][a-zA-Z0-9-_\.]+@([a-zA-Z]|[a-zA-Z0-9]?[a-zA-Z0-9-]+[a-zA-Z0-9])\.[a-zA-Z0-9]{2,10}(?:\.[a-zA-Z]{2,10})?$/, email: true}"
          mode="lazy"
          v-slot="validationContext"
        >
          <b-form-group id="example-input-group-3" label="Email" label-for="email">
            <b-form-input
                id="email"
                name="email"
                ref="email"
                v-model="oemail"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="email-live-feedback"
                autocomplete="current-password"
                spellcheck="false" 
                autocorrect="off" 
                autocapitalize="off" 
            ></b-form-input>
            <b-form-invalid-feedback id="email-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider
          name="Phone"
          :rules="{ required: false, regex: /^[0-9]{12}$/, phone: true}"
          mode="lazy"
          v-slot="validationContext"
        >
          <b-form-group id="phone-input-group-3" label="Номер телефону" label-for="phone">
            <b-form-input
                id="phone"
                name="phone"
                ref="phone"
                v-model="ophone"
                type="text"
                :state="getValidationState(validationContext)"
                aria-describedby="phone-live-feedback"
                autocomplete="current-password"
                spellcheck="false" 
                autocorrect="off" 
                autocapitalize="off" 
            ></b-form-input>
            <b-form-invalid-feedback id="phone-live-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>



        <b-overlay
            :show="busy"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="bruvis"
            class="d-inline-block"
            @hidden="onHidden"
            >
            <b-button
                ref="button"
                type="submit"
                :disabled="busy || (firstname==ofirstname && surname==osurname && secondname==osecondname && email==oemail && phone==ophone)"
                variant="bruvis"
            >
                Записати
            </b-button>
        </b-overlay>
        <b-button class="ml-2" @click="resetForm()" :disabled="firstname==ofirstname && surname==osurname  && secondname==osecondname && email==oemail && phone==ophone">Повернути</b-button>
        <b-button class="ml-2" @click="resetForm(), $emit('oncancel')">Відміна</b-button>
      </b-form>
    </validation-observer>
  </div>
</template>

<style>

</style>

<script>
export default {
  name: 'ChangeName', 
  props: {
    firstname: {
      type: Object,
      required: true
    },
    secondname: {
      type: Object,
      required: true
    },
    surname: {
      type: Object,
      required: true
    },
    email: {
      type: Object,
      required: false
    },
    phone: {
      type: Object,
      required: false
    }
  }, 
  data() {
    return {
      busy: false,   
      ofirstname: null,
      osurname: null,
      osecondname: null,
      oemail: null,
      ophone: null,
    };
  },
  mounted(){
    this.storeState()

  },  
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    resetForm() {
      this.storeState()
      /*
      this.ofirstname=this.firstname
      this.osurname=this.surname
      this.osecondname=this.secondname
      this.oemail=this.email
      this.ophone=this.phone
      */
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    onSubmit() {
      this.changeName(
          (this.firstname===this.ofirstname)?null:this.ofirstname,
          (this.osurname===this.surname)?null:this.osurname,
          (this.osecondname===this.secondname)?null:this.osecondname,
          (this.oemail===this.email)?null:((!this.email)?'+_'+this.oemail:this.oemail),
          (this.ophone===this.phone)?null:((!this.phone)?'+_'+this.ophone:this.ophone)
      )
    },
    onHidden() {
        this.$refs.button.focus()
    },
    storeState(){
      this.ofirstname=this.firstname,
      this.osurname=this.surname
      this.osecondname=this.secondname
      this.oemail=this.email
      this.ophone=this.phone
    },
    /*
    restoreStateBack(){
      this.firstname=this.ofirstname,
      this.surname=this.osurname
      this.secondname=this.osecondname
      this.email=this.oemail
      this.phone=this.ophone
    },
    */
    changeName(firstname,surname,secondname,email,phone){
      var axiosConfig = {
        headers: {
          'Content-Type': 'application/json'
        },
        'withCredentials': true
      }
      this.busy = true 
      this.$axios.post('/', {"action":"changename","firstname":firstname,"surname":surname,"secondname":secondname,"email":email,"phone":phone}, axiosConfig )
                  .then((response) => {
                    if(response.data.success != false && response.data.success != "false"){
                      this.$toasted.success('Інформацію змінено').goAway(6000)
                      this.$emit('onsuccess')
                      this.storeState()
                      this.busy = false 
                      this.resetForm()
                    }else{
                      if(response.data.error=="auth" || response.data.error=="tokenout"){
                        //this.needlogin = true
                        this.$toasted.error('Час сессії вичерпано').goAway(4000)
                      }else if(response.data.message != null){
                        this.$toasted.error(response.data.message).goAway(4000)
                        this.$emit('onregetdata')
                        this.storeState()
                        this.busy = false 
                        this.resetForm()
                      }else{
                        this.$toasted.error('Помилка:(').goAway(4000)
                        this.$emit('onregetdata')
                        this.storeState()
                        this.busy = false 
                        this.resetForm()
                      } 
                      this.busy = false  
                    }
                  })
                  .catch(error => {
                    this.busy = false  
                    console.error('There was an error!', error)
                    this.$toasted.error('Помилка: '+error).goAway(4000)
                  });
    }
  }
};
</script>