<template>
    <div class="archive">
        <b-row v-if="!borg_widget_is_visible">
            <b-col md="12">
                <b-overlay
                    :show="busy"
                    rounded
                    opacity="0.6"
                    spinner
                    spinner-variant="bruvis"
                >
                  <b-tabs card v-if="!widget_is_visible">
                    <b-tab title="Абонементи" active>
                      <b-table hover 
                        v-if="history"
                        :items="history.filter(item => item.rt === 0)" 
                        :fields="fields_abon"
                        sort-icon-left-
                        @row-clicked="onAbonSelected"
                        selectable-
                        select-mode-="single"
                        :tbody-tr-class="rowAbonClass"
                        role="button"
                        :sort-by.sync="sortAbonBy"
                        :sort-desc.sync="sortAbonDesc"
                      >
                        <!--template #cell(abonPaySum)="data">
                          <template v-if="data && data.item && data.item.abonPaySum && data.item.price != data.item.abonPaySum"><div class="text-danger">{{ data.item.price - data.item.abonPaySum | toCurrency}}</div></template>
                          <template v-else-if="data && data.item && !data.item.abonPaySum"><div class="text-danger">{{ data.item.price | toCurrency}}</div></template>
                        </template-->
                        <template #cell(price)="data">
                          <template v-if="data && data.item && data.item.price">{{data.item.price | toCurrency}}</template>
                        </template>
                        <template #cell(ico_borg)="data">
                          <b-icon 
                            v-if="data.item.price && data.item.price != data.item.abonPaySum" 
                            icon="exclamation-triangle-fill" 
                            variant="danger"
                            title="Заборгованність"
                          >
                          </b-icon>
                        </template>
                        <!--template #cell(ico_update)="data">
                          <b-icon 
                            v-if="data.item.timeto && new Date(data.item.timeto) < new Date()" 
                            icon="arrow-repeat" 
                            title="Відновити абонемент"
                            role="button"
                            >
                          </b-icon>
                          <b-icon 
                            v-else-if="data.item.timeto && new Date(data.item.timeto) > new Date()" 
                            icon="arrow-clockwise" 
                            title="Продовжити абонемент"
                            role="button"
                            >
                          </b-icon>
                        </template-->

                        <template #row-details="a">
                          <div :class="abonClass(a.item)+' m-0 p-3 abon-details'"> <!-- v-if="a.item.rn==selectedAbon.rn"--><!--class="text-center m-0 mb-2"-->
                            <template v-if="a.item && a.item.abonPaySum &&  a.item.price && a.item.price != a.item.abonPaySum"><div class="mb-2">Увага! Заборгованність складає <span class="text-danger">{{ a.item.price - a.item.abonPaySum | toCurrency}}</span></div></template>
                            <template v-else-if="a.item && a.item.price !=0 && !a.item.abonPaySum"><div class="mb-2">Увага! Заборгованність складає <span class="text-danger">{{ a.item.price | toCurrency}}</span></div></template>

                            <b-button 
                              v-if="a.item.price && a.item.price != a.item.abonPaySum" 
                              @click = goBuyBorg(a.item.rn)
                              :disabled="!a.item.checkRN"
                            ><!--@click = goBuyBorg(a.item.sampleRN,a.item.price,a.item.abonPaySum)-->
                            <!--this.$toasted.error('Рахунок не знайдено').goAway(3000)-->
                              <b-icon icon="credit-card" class="mr-2"></b-icon>Сплатити заборгованність
                            </b-button>

                            <template v-else-if="a.item.timeto && new Date(a.item.timeto) < new Date()">
                              <div class="mb-2">Сплив термін дії абонемента</div>
                              <b-button v-if="a.item.avail" @click = goBuyRenew(a.item.sampleRN)>
                                <b-icon icon="arrow-repeat" class="mr-2"></b-icon>Відновити абонемент
                              </b-button>
                            </template>

                            <!--b-button 
                              v-else-if="a.item.avail && a.item.timeto && new Date(a.item.timeto) > new Date()" 
                              @click = goBuyEnlarge(a.item.sampleRN,a.item.timeto)
                            >
                              <b-icon icon="arrow-clockwise" class="mr-2"></b-icon>Продовжити абонемент
                            </b-button-->

                            <template v-else-if="a.item.avail && a.item.timeto && new Date(a.item.timeto) > new Date()">
                              <div class="mb-2">&nbsp;</div>
                              <b-button v-if="a.item.avail" @click = goBuyRenew(a.item.sampleRN,a.item.timeto)>
                                <b-icon icon="arrow-clockwise" class="mr-2"></b-icon>Продовжити абонемент
                              </b-button>
                            </template>

                            <template v-else-if="!a.item.timefr">
                              <div class="mb-2">Абонемент активується при використанні</div>
                              <b-button v-if="a.item.avail" @click = goBuyRenew(a.item.sampleRN)>
                                <b-icon icon="arrow-repeat" class="mr-2"></b-icon>Купити ще абонемент
                              </b-button>
                            </template>

                          </div>
                        </template>
                        <!--template #cell(ico_diia)="data">
                          <b-icon 
                            icon="gear-fill" 
                            title="Налаштування"
                            role="button"
                            >
                          </b-icon>
                        </template-->
                         <!--template #cell(timefr)="data">
                            {{ data.value | dateFormat('D MMM YYYY H:mm')}}
                          </template>  
                          <template #cell(timeto)="data">
                            {{ data.value | dateFormat('D MMM YYYY H:mm')}}
                          </template-->  
                      </b-table>
                    </b-tab>
                    <b-tab title="Квитки">
                      <b-table hover sticky-header
                        v-if="history"
                        :items="history.filter(item => item.rt === 1)" 
                        :fields="fields_ticket"
                        :tbody-tr-class="rowTicketClass"
                        @row-clicked="onTicketSelected"
                        sort-icon-left-
                        :sort-by.sync="sortBy"
                        :sort-desc.sync="sortDesc"
                        role="button"
                      >
                        <template #cell(timefrto)="data">
                          <template v-if="data && data.item && data.item.timefr && data.item.timeto">{{ new Date(data.item.timefr) | dateFormat('H:mm')}}-{{ new Date(data.item.timeto) | dateFormat('H:mm')}}</template>
                        </template>
                        <template #cell(price)="data">
                          <template v-if="data && data.item && data.item.price">{{ data.item.price | toCurrency}}</template>
                        </template>
                        <template #cell(ico_borg)="data">
                          <b-icon 
                            v-if="!data.item.paytime" 
                            icon="exclamation-triangle-fill" 
                            variant="danger"
                            title="Заборгованність"
                          >
                          </b-icon>
                        </template>
                        <template #row-details="a">
                          <div :class="rowTicketClass(a.item,'row')+' m-0 p-3 abon-details'">
                            <template v-if="a.item.xspecialistMnemos">
                              <div>Спеціаліст: {{a.item.xspecialistMnemos}}</div>
                            </template>
                            <template v-else>
                              <pre>{{a.item}}</pre>
                            </template>    

                            <!--b-button 
                              v-if="a.item.price != a.item.abonPaySum" 
                              @click = goBuyBorg(a.item.sampleRN,a.item.price,a.item.abonPaySum)
                            >
                              <b-icon icon="credit-card" class="mr-2"></b-icon>Сплатити заборгованність
                            </b-button-->


                          </div>
                        </template>  
                      </b-table>
                    </b-tab>
                  </b-tabs>      

                  <div v-show="widget_is_visible">
                    <div id="liqpay_checkout"></div>
                  </div>  

                </b-overlay>    
            </b-col>
        </b-row>
        <!------------------------------------------>

        <div v-show="borg_widget_is_visible">
          <div id="liqpay_checkout_borg"></div>    
        </div>

        <!------------------------------------------>

        <b-modal 
          id="abonements-modal2" 
          size="md"
          hide-header-close- 
          centered 
          scrollable 
          :no-close-on-backdrop="true" 
          ok-only
          ok-variant="bruvis"
          ok-title="Закрити"
        >
          <template #modal-title>
            <b-icon icon="credit-card2-front" class="rounded bg-bruvis p-1" variant="light" font-scale="1.3"></b-icon> 
            <span class="p-2 h5 mr-auto">Замовлення абонемента</span>
          </template>
          <AboneMents
            :targetAbon = "targetAbon"
            :dateFRtargetAbon = "dateFRtargetAbon"
            @needlogin = "this.$emit('needlogin')"
          ></AboneMents> 
        </b-modal>   



    </div>

    

</template>

<script>

import AboneMents from './abonements.vue'

export default {
  name: 'HistoryPay',
  components: {
    AboneMents,
  },
  props: {
    seatinfo:{
        type: Object,
        required: true
    },
    borg_widget_is_visible:{
      type: Object,
      required: true
    },
    ready_pay_borg:{
      type: Object,
      required: true
    },
  },
  data: () => ({
    zakazy_payed : {}, 
    checkedAbon:null,
    days_payed : [],
    dt: null,
    dt_label: null,
    dt_index:null,
    dt_id:null,
    zakazy_day: null,
    busy: true,
    history: null,
    sortBy: 'date',
    sortDesc: true,
    sortAbonBy: 'date',
    sortAbonDesc: true,
    targetAbon: null,
    dateFRtargetAbon: null,
    payReady: false,

    lpay: null,
    widget_is_visible: false,
    globalrns: null,


    fields_ticket: [
          {
            key: 'date',
            sortable: true,
            label: 'Дата',
            class: 'text-nowrap',
            formatter: (d) => {
              if(!d) return
              return d.substring(8)+'-'+d.substring(5,7)+'-'+d.substring(0,4)
            }
          },
          {
            key: 'timefrto',
            label: 'Час дії',
            class: 'text-center'
          },  
          /*
          {
            key: 'timefr',
            sortable: false,
            label: 'Дійсний з',
            formatter: (value) => {
              var t = new Date(value)
              if(!value) return
              return t.getHours() + ':' + ("0" + t.getMinutes()).slice(-2)
            }
          },  
          {
            key: 'timeto',
            sortable: false,
            label: 'Дійсний по',
            formatter: (value) => {
              if(!value) return
              var t = new Date(value)
              return t.getHours() + ':' + ("0" + t.getMinutes()).slice(-2)
            }
          },
          */
          {
            key: 'xnomenu',
            label: 'Послуга',
            sortable: true
          },
          {
            key: 'price',
            label: 'Вартість',
            sortable: true,
            class: 'text-right'
          },
          {
            key: 'ico_borg',
            label: '',
            class: 'text-left'
          },
    ],
    fields_abon: [
          {
            key: 'timefr',
            sortable: true,
            label: 'Дійсний з',
            class: 'text-nowrap',
            formatter: (value) => {
              if(!value) return
              var t = new Date(value)
              //return t.getFullYear()+'-'+("0" + (t.getMonth()+1)).slice(-2)+'-'+t.getDate()
              return t.getDate()+'-'+("0" + (t.getMonth()+1)).slice(-2)+'-'+t.getFullYear()
            }
          },
          {
            key: 'timeto',
            sortable: true,
            label: 'Дійсний до',
            class: 'text-nowrap',
            formatter: (value) => {
              if(!value) return
              var t = new Date(value)
              //return t.getFullYear()+'-'+("0" + (t.getMonth()+1)).slice(-2)+'-'+t.getDate()
              return t.getDate()+'-'+("0" + (t.getMonth()+1)).slice(-2)+'-'+t.getFullYear()
            }
          },
          /*
          {
            key: 'ico_update',
            label: '',
            class: 'text-left'
          },
          */
          {
            key: 'xnomenu',
            label: 'Послуга',
            sortable: true
          },
          {
            key: 'price',
            label: 'Вартість',
            sortable: true,
            class: 'text-right'
          },
          /*
          {
            key: 'abonPaySum',
            label: 'Заборгованість',
            sortable: false,
            class: 'text-right'
          },
          */
          {
            key: 'ico_borg',
            label: '',
            class: 'text-left'
          },
          /*
          {
            key: 'ico_diia',
            label: '',
            class: 'text-left'
          }*/


    ]        
/*
    key: 'date'
paytime
price
rn
rt
serviceRN
    timefr
    timeto
xnomenu
*/

  }),  
  methods: {
        getHistory(){
            this.busy = true;
            this.$axios.post('/', {"action":"gethistory"} )
                .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){
                    this.history = response.data.zakazy_payed
                    //this.zakazy_payed = response.data.zakazy_payed
                    //this.days_payed = response.data.days_payed
                    //this.gotoDateIndex(this.days_payed.length-1)
                    this.busy = false;

                    //const calendar = this.$refs.calendar
                    //calendar.attributes =  this.getAttributes()

                    //console.log(this.getAttributes())
                    //console.log(calendar)

                    //this.$refs.calendar.attributes.available-dates = this.archive.days_payed
                    //this.$toasted.success(response.data.message).goAway(3000)
                    //this.ordersbusy = false
                }else{

                    this.busy = false;
                    if(response.data.error=="auth" || response.data.error=="init"){
                        //this.needlogin = true
                        this.$emit('needlogin')
                    }else{
                        this.$toasted.error(response.data.message).goAway(3000) 
                    }
                }  
                })
                .catch(error => {
                    this.busy = false
                    console.error('There was an error!', error)
                });
        },
        rowTicketClass(item, type){
          if (!item || type !== 'row') return
          if (!item.paytime) return 'table-danger'
        },
        onRowClicked(item) {
          console.log(item)
        },
        onAbonSelected(a){
          console.log(a)
          this.$set(a, '_showDetails', !a._showDetails)
/*
          if ('_showDetails' in a) {
            item._showDetails = !item._showDetails;
          }

          a.toggleDetails
          this.checkedAbon = a
          //a._showDetails = true
          console.log(a)

          if(a.detailsShowing){
            this.$set(a, '_showDetails', false)
          }else{
            this.currentItems.forEach(item => {
            this.$set(item, '_showDetails', false)
          })

          this.$nextTick(() => {
            this.$set(row, '_showDetails', true)
          })
          
        }
      }
*/          
        },
        rowAbonClass(item, type){
          if (!item || type !== 'row') return
          if (item.timefr && new Date(item.timefr) > new Date()) return 'table-warning'
          if (item.timefr && new Date(item.timefr) <= new Date())
            if(!item.timeto) return 'table-success'
            else if(new Date(item.timeto) >= new Date()) return 'table-success'
            else return 'table-danger'
        },
        abonClass(item){
          if (!item) return
          if (item.timefr && new Date(item.timefr) > new Date()) return 'alert-warning'
          if (item.timefr && new Date(item.timefr) <= new Date())
            if(!item.timeto) return 'alert-success'
            else if(new Date(item.timeto) >= new Date()) return 'alert-success'
            else return 'alert-danger'
        },
        onTicketSelected(a){
          this.$set(a, '_showDetails', !a._showDetails)
        },  

        dayClicked(day){
            if(day.isDisabled) return false
            this.dt = day
            this.zakazy_day = this.zakazy_payed[day.id]
            this.dt_index = this.days_payed.indexOf(day.id)
            this.dt_label = day.ariaLabel
            this.dt_id = day.id
            //console.log(day)
        },
        gotoDateIndex(idx){
            this.dt_id = this.days_payed[idx]
            this.zakazy_day = this.zakazy_payed[this.dt_id]
            this.dt_index = idx
            //this.dt_label = this.days_payed[idx].dateFormat('dddd D MMMM')

        },
        getAttributes(){
            return this.days_payed.map( d => ({
                    dates: new Date(d),
                    highlight:{
                        color: 'blue',
                        fillMode: 'light'
                    },
                    color: 'red'
                }))
                
            
        },
        getSeatLabel(nam,num){
          if(!nam)return ''
          if(nam.indexOf('#')>-1)
            return nam.replace('#',num)
          else
            return nam
        },
        ticketDownload(fl,code){
          var axiosConfig = {
            /*
            headers: {
              'Content-Type': 'application/json',
            },
            */
            'withCredentials': true,
            'responseType': 'blob'
          }
          this.$axios.post('/', {"action":"download","rn":fl,"code":code}, axiosConfig )
            .then((res) => {
              console.log(res.data.success,res.data.error)
              if(res.data.success != false && res.data.success != 'false'){
                var FILE = window.URL.createObjectURL(new Blob([res.data]));
                var docUrl = document.createElement('a');
                docUrl.href = FILE;
                docUrl.setAttribute('download', 'ticket_'+fl+'.pdf');
                document.body.appendChild(docUrl);
                docUrl.click(); 
                ////document.body.removeChild(docUrl);
                ////window.URL.revokeObjectURL(FILE);
                console.log(res)
              }else{
                if(res.data.error=="init"){
                  this.$emit('needlogin')
                }
              } 
            })
            .catch(error => {
              console.error('There was an error!', error)
            });
        },
        //*******************BUY******************* */
        goBuyBorg(rn){

          this.busy = true
          this.$axios.post('/', {"action":"payabonborg","rn": rn} )
              .then((response) => {
                if(response.data.success != false && response.data.success != 'false'){

                  if(response.data.data && response.data.signature && response.data.checkRN){
                    this.busy = false
                    this.globalrns = response.data.checkRN
                    this.payReady = false
                    this.goLiqPayBorg(response.data.data, response.data.signature,"#liqpay_checkout_borg")
                  }  
    
      
                }else{

                  this.busy = false
                  if(response.data.error=="auth" || response.data.error=="init"){
                    //this.needlogin = true
                    this.$emit('needlogin')
                  }else{
                    this.$toasted.error(response.data.message).goAway(3000) 
                  }
                }  
              })
              .catch(error => {
                this.busy = false
                  console.error('There was an error!', error)
              });
          
        },
        //********************************************************** */

        goLiqPayBorg(dt, sign, el){

                if(this.lpay){
                  document.getElementById('liqpay_checkout_borg').innerHTML=''
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": dt,
                    "signature": sign,
                    "embedTo": el,
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  })
                }else{
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": dt,
                    "signature": sign,
                    "embedTo": el,
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  }).on("liqpay.callback", function(data){  
                      console.log('Callback '+this.globalrns)
                      console.log(data.status)

                      if(data.status=='failure' && data.code=='expired'){
                        this.$toasted.error('Час на оплату скінчився! Бронювання буде скасовано.').goAway(4000)
                        this.busy = true
                        //this.checkedSeats = []
                        //this.currentDateTabMark = null
                        //this.updateStates()
                        this.busy = false
                      }else{
                        setTimeout(() => this.requestToPayStatusBorg(this.globalrns), 2000)
                      }
                    }.bind(this)
                  ).on("liqpay.ready", function(data){
                    console.log(data.status)
                    this.busy = false
                    this.$emit('showWidget')
                    }.bind(this)
                  ).on("liqpay.close", function(data){
                    // close
                    console.log('Close')
                    console.log(data)
                  });
                }
        },

        requestToPayStatusBorg(rns){

          this.$axios.post('/', {"action":"getpaystatusabon","rn":rns} )
            .then((response) => {
              if(response.data.success != false && response.data.success != 'false'){
                let result = response.data
                switch(result.status){
                  case 'wait':
                    console.log('wait')
                    this.$toasted.info('Очікуємо на підтвердження платежу').goAway(2000)
                    setTimeout(() => this.requestToPayStatus(rns), 2000)
                    break
                  case 'success':
                    console.log('success')
                    this.$toasted.success(result.message).goAway(3000)
                    //---оплата прошла успешно
                    this.book_busy = false
                    this.$emit('readyPaySet')
                    this.getHistory()
                    //this.$emit('onupdate')
                    //this.resetSelectedAbon()
                    //if(this.auth && this.auth.visitor) this.getTickets()
                    break
                  default:
                    console.log(result)  
                } 
              }else{
                this.errorMaster(response.data.error)
              }   
            })
            .catch(error => {
              this.globalbusy = false
              console.error('There was an error!', error)
            });
        },

        //********************************************************** */
        goBuyRenew(rn,date=null){
          this.targetAbon = rn
          this.dateFRtargetAbon = date
          this.$bvModal.show('abonements-modal2') 
          /*
          let today = new Date()
          let outDay = new Date()
          outDay.setDate(today.getDate() + 1) // zavtra
          var zavtra = '' + outDay.getFullYear()
          zavtra += (outDay.getMonth()<9)?'-0':'-'
          zavtra += outDay.getMonth()+1
          zavtra += (outDay.getDate()<10)?'-0':'-' 
          zavtra += outDay.getDate()

          this.goBuy(rn,zavtra,null)
          */
        },
        goBuyNew(rn){
          this.goBuy(rn,null,null)
        },
        goBuyEnlarge(rn, dt){
          let today = new Date(dt)
          let outDay = new Date(dt)
          outDay.setDate(today.getDate() + 1) // zavtra
          var zavtra = '' + outDay.getFullYear()
          zavtra += (outDay.getMonth()<9)?'-0':'-'
          zavtra += outDay.getMonth()+1
          zavtra += (outDay.getDate()<10)?'-0':'-' 
          zavtra += outDay.getDate()
          this.goBuy(rn,zavtra,null)
        },
        goBuy(rn,date,sum){
          this.busy = true

          var postData = {
            "action"  :"buyabonement",
            "date"    : date,
            "rn"      : rn,
            "sum"     : sum
          }

          console.log('PostData',postData)

          var axiosConfig = {
            headers: {
              'Content-Type': 'application/json',
            },
            'withCredentials': true
          }

          this.$axios.post('/', JSON.stringify(postData),  axiosConfig)
          .then((response) => {
            console.log(response.data)
            if(response.data.success != false && response.data.success != 'false'){
              
              if(response.data.liqpay.data && response.data.liqpay.signature){
                
                this.globalrns = response.data.rns
                this.goLiqPay(response.data.liqpay.data, response.data.liqpay.signature,"#liqpay_checkout")
              }
              
            }else{
              this.errorMaster(response.data.error)
            }  
          })
          .catch(error => {
              console.error('There was an error!', error)
              this.book_busy = false
              this.$toasted.error('Error').goAway(2000)
          });  

    },

    goLiqPay(dt, sign, el){
                
                if(this.lpay){
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": dt,
                    "signature": sign,
                    "embedTo": el,
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  })
                }else{
                  //eslint-disable-next-line
                  this.lpay = LiqPayCheckout.init({
                    "data": dt,
                    "signature": sign,
                    "embedTo": el,
                    "language": "uk",
                    "mode": "embed" // embed || popup
                  }).on("liqpay.callback", function(data){  
                      console.log('Callback '+this.globalrns)
                      console.log(data.status)

                      if(data.status=='failure' && data.code=='expired'){
                        this.$toasted.error('Час на оплату скінчився! Бронювання буде скасовано.').goAway(4000)
                        this.busy = true
                        //this.checkedSeats = []
                        //this.currentDateTabMark = null
                        //this.updateStates()
                        this.busy = false
                      }else{
                        setTimeout(() => this.requestToPayStatus(this.globalrns), 2000)
                      }
                    }.bind(this)
                  ).on("liqpay.ready", function(data){
                    console.log(data.status)
                    this.busy = false
                    this.widget_is_visible = true
                    }.bind(this)
                  ).on("liqpay.close", function(data){
                    // close
                    console.log('Close')
                    console.log(data)
                  });
                }
    },

    requestToPayStatus(rns){

      this.$axios.post('/', {"action":"getpaystatusabon","rn":rns} )
        .then((response) => {
          if(response.data.success != false && response.data.success != 'false'){
            let result = response.data
            switch(result.status){
              case 'wait':
                console.log('wait')
                this.$toasted.info('Очікуємо на підтвердження платежу').goAway(2000)
                setTimeout(() => this.requestToPayStatus(rns), 2000)
                break
              case 'success':
                console.log('success')
                this.$toasted.success(result.message).goAway(3000)
                //---оплата прошла успешно
                this.book_busy = false
                 this.$emit('onupdate')
                 this.resetSelectedAbon()
                //if(this.auth && this.auth.visitor) this.getTickets()
                break
              default:
                console.log(result)  
            } 
          }else{
            this.errorMaster(response.data.error)
          }   
        })
        .catch(error => {
          this.globalbusy = false
          console.error('There was an error!', error)
        });
    },

    
  },
  
  mounted() {
      this.getHistory()
  },

    computed: {
        availDates(){
            return this.days_payed.map(d => new Date(d))
        },
        attrs(){
            //return this.days_payed.map(d => ({dates: new Date(d), highlight: true}))
            return this.days_payed.map(d => ({
                dates: new Date(d), 
                highlight: {
                    color: (new Date(d) < new Date())?'gray':'blue',
                    fillMode: 'solid'
                }
            }))
        },
        /*
        minPage(){
            return {
                min-page [
                    
                        year: (new Date(this.zakazy_days[0])).getFullYear(), 
                        month: (new Date(this.zakazy_days[0])).getMonth()
                        
                ]
            }    
        }
        */
        
    }
}
</script>

<style scoped>

.abon-details{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.check{
  padding: 5px;
  background:
    linear-gradient(-45deg, #0000 6px, #fff 7px),
    linear-gradient( 45deg, #0000 6px, #fff 7px),
    linear-gradient(-45deg, #fff calc(100% - 7px), #0000 calc(100% - 8px)),
    linear-gradient( 45deg, #fff calc(100% - 7px), #0000 calc(100% - 8px));
  background-size: 10px 50%;
  background-position: 0 100%, 0 100%, 0 0, 0 0;
  background-repeat: repeat-x;
  font-family: courier;
}

.perforated_check{
  position: relative;
  margin: 3px;
  width: 400px;
  min-height: 200px;
  background: #e2e3de;
  padding: 4px 20px;
  box-shadow: 10px 10px 20px rgb(0 0 0 / 20%);
  font-family: courier;
}

.perforated_check_abon{
  background-color: #fdf3ce;
}

.perforated_check svg{
  position: absolute;
  top: -3px;
  left: 2px;
  width: 100%;
}

.perforated_check table td{
  border-bottom: 1px dashed #c5c5c5;
  vertical-align: top;
}

.perforated_check table {
  margin-bottom:20px;
}

svg.ico-download{
  position: absolute;
  top: 23px;
  left: 21px;
  width: 30px;
}  


svg.ico-del-zakaz{
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  position: absolute;
  top: 23px;
  left: 21px;
  width: 30px;
}  

.perforated_check:hover svg.ico-del-zakaz{
  visibility: visible;
  opacity: 1;
}

.shtamp-canceled{
  color: #b9333f;
  background-color: transparent;
  border: 3px solid #b9333f;
  border-radius: 0.25rem;
  position: absolute;
  padding: 0.75rem 1.25rem;
  transform: rotate(45deg);
  opacity: 0.7;
}

.comment-canceled{
  color: #b9333f;
  padding-top: 0.5em;
}  


@media (max-width: 575px) {
  .perforated_check{
    width: 100%;
/*    min-height: 200px;*/
  }
  .card-body {
    padding:0;
  }

}

@media (hover: none) {
  svg.ico-del-zakaz{
    visibility: visible;
    opacity: 1;
  }    

}    

/*

.perforated_check{
  position: relative;
  margin: 3px;
  width: 400px;
  min-height: 200px;
  background: #e2e3de;
  padding: 4px 20px;
  box-shadow: 10px 10px 20px rgb(0 0 0 / 20%);
  font-family: courier;
}

.perforated_check svg{
  position: absolute;
  top: -3px;
  left: 2px;
  width: 100%;
}

.perforated_check table td{
  border-bottom: 1px dashed #c5c5c5;
  vertical-align: top;
}

.perforated_check table {
  margin-bottom:20px;
}

.perforated_check:hover svg.ico-del-zakaz{
  visibility: visible;
  opacity: 1;
}

.shtamp-canceled{
  color: #b9333f;
  background-color: transparent;
  border: 3px solid #b9333f;
  border-radius: 0.25rem;
  position: absolute;
  padding: 0.75rem 1.25rem;
  transform: rotate(45deg);
  opacity: 0.7;
}

.comment-canceled{
  color: #b9333f;
  padding-top: 0.5em;
} 


@media (max-width: 575px) {
  .perforated_check{
    width: 100%;
  }
  .card-body {
    padding:0;
  }

}

@media (hover: none) {


}    
*/


</style>
